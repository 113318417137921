import apiURL from '../hooks/useAPI';

const { fetchAPI } = apiURL;

const notificaciones = [];

const urlAPI = `${fetchAPI}alerta/`;
// const urlAPI = `http://192.168.0.103:4000/api/alerta/`;

const requestData = async(url, token, carrera, perfil) => {
    if (url) {
        try {
            const entryPointLogin = url;
            const optionFetch = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'secret': '5ec4702eff406849a82818cb',
                    'carrera': carrera,
                    'perfil': perfil
                }
            }
            return await fetch(entryPointLogin, optionFetch)
                .then(result => result.json())
                .then((data) => {
                    if (+data.success === 1) {
                        return data.result;
                    } else {
                        return data.result = {};
                    }
                })
        } catch (error) {
            console.log(error);
            return error;
        }
    } else {
        let res;
        return res.json({
            'message': 'EndPoint no definido'
        });
    }
}

const requestDataAll = async(url, token) => {
    if (url) {
        try {
            const entryPointLogin = url;
            const optionFetch = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'secret': '5ec4702eff406849a82818cb'
                }
            }
            return await fetch(entryPointLogin, optionFetch)
                .then(result => result.json())
                .then((data) => {
                    if (+data.success === 1) {
                        return data.result;
                    } else {
                        return data.result = {};
                    }
                })
        } catch (error) {
            console.log(error);
            return error;
        }
    } else {
        let res;
        return res.json({
            'message': 'EndPoint no definido'
        });
    }
}

const requestDataPost = async(url, notificacion, urlImagen, perfil, usuario, token, carrera, idColaborador) => {
    if (url) {
        try {
            const entryPointLogin = url;
            let data = {
                "notificacion": notificacion,
                "imagen": urlImagen,
                "perfil": perfil,
                "user_publico": usuario,
                "carrera": carrera,
                "idColaborador": idColaborador
            };
            return await fetch(entryPointLogin, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then(result => result.json())
                .then((data) => {
                    return data;
                })
        } catch (error) {
            console.log(error);
            return error;
        }
    } else {
        let res;
        return res.json({
            'message': 'EndPoint no definido'
        });
    }
}

notificaciones.getNotificaciones = (token, carrera, perfil) => {
    const url = `${urlAPI}`;
    return requestData(url, token, carrera, perfil);
}

notificaciones.getNotificacionesAll = (token) => {
    const url = `${urlAPI}/all/`;
    return requestDataAll(url, token);
}

notificaciones.setNotificacion = (notificacion, urlImagen, perfil, usuario, token, carrera, idColaborador) => {
    return requestDataPost(urlAPI, notificacion, urlImagen, perfil, usuario, token, carrera, idColaborador);
}

export default notificaciones;