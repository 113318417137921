import React from "react";

const CardPlanesPago = ({ cuota, monto, vencimiento }) => {
    let salida = new Date(vencimiento)
  return (
    <tr>
      <td>{cuota}</td>
      <td>${monto}</td>
      <td>{salida.toLocaleDateString()}</td>
    </tr>
  );
};

export default CardPlanesPago;
