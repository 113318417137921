import React from "react";

const CarTalonario = ({datos}) => {
  const talonario = datos || [];
  
  return (
    <div>
    <div className="card">
      <div className="card-body">
      <table className="table">                  
           <tbody>
            <tr><td colspan="2"><h4 className="text-success"><b>{talonario.mes_1}</b></h4></td><td></td></tr>
             <tr><td>Tipo: </td><td>{talonario.nombre_tipo_cobro}</td></tr>
             <tr><td>Pagar: </td><td><b>${talonario.valor_cobro}</b></td></tr>
             <tr><td>SIN RECARGO HASTA: </td><td>{talonario.fecha_mes_1}</td></tr>
             <tr><td>NPE: </td><td>{talonario.npe_1}</td></tr>
             <tr className="text-center"><td colspan="2" className="texr-info"><b>POR PAGO EXTEMPORÁNEO INCLUYE MULTA </b></td><td></td></tr>
             <tr><td>Total por pago extemporáneo: </td><td>{talonario.valor_multa}</td></tr>
             <tr className="text-info"><td>NPE: </td><td><b>{talonario.npe_multa_1}</b></td></tr>
             </tbody>
      </table> 
      </div>
      </div>

<div className="card">
<div className="card-body">
      <table className="table">                  
           <tbody>
            <tr><td colspan="2"><h4 className="text-success"><b>{talonario.mes_2}</b></h4></td><td></td></tr>
             <tr><td>Tipo: </td><td>{talonario.nombre_tipo_cobro}</td></tr>
             <tr><td>Pagar: </td><td><b>${talonario.valor_cobro}</b></td></tr>
             <tr><td>SIN RECARGO HASTA: </td><td>{talonario.fecha_mes_2}</td></tr>
             <tr><td>NPE: </td><td>{talonario.npe_2}</td></tr>
             <tr className="text-center"><td colspan="2" className="texr-info"><b>POR PAGO EXTEMPORÁNEO INCLUYE MULTA </b></td><td></td></tr>
             <tr><td>Total por pago extemporáneo: </td><td>{talonario.valor_multa}</td></tr>
             <tr className="text-info"><td>NPE: </td><td><b>{talonario.npe_multa_2}</b></td></tr>
             </tbody>
      </table> 
      </div>
      </div>

      <div className="card">
      <div className="card-body">
      <table className="table">                  
           <tbody>
            <tr><td colspan="2"><h4 className="text-success"><b>{talonario.mes_3}</b></h4></td><td></td></tr>
             <tr><td>Tipo: </td><td>{talonario.nombre_tipo_cobro}</td></tr>
             <tr><td>Pagar: </td><td><b>${talonario.valor_cobro}</b></td></tr>
             <tr><td>SIN RECARGO HASTA: </td><td>{talonario.fecha_mes_3}</td></tr>
             <tr><td>NPE: </td><td>{talonario.npe_3}</td></tr>
             <tr className="text-center"><td colspan="2" className="texr-info"><b>POR PAGO EXTEMPORÁNEO INCLUYE MULTA </b></td><td></td></tr>
             <tr><td>Total por pago extemporáneo: </td><td>{talonario.valor_multa}</td></tr>
             <tr className="text-info"><td>NPE: </td><td><b>{talonario.npe_multa_3}</b></td></tr>
             </tbody>
      </table> 
      </div>
    </div>
    </div>
  )
};

export default CarTalonario;
