import React from "react";

const CardFechasVencimiento = ({ mes, vencimiento, id }) => {
  let now = new Date();
  let salida = new Date(vencimiento);
  console.log(+now, +salida);
  let vencido = 'text-danger';
  let like = 'fa fa-hand-paper-o'
  if (+now <= +salida) {
    vencido = 'text-success';
    like = 'fa fa-thumbs-up';
  }
  let fecha = salida.toLocaleString().split(',');
  if (fecha.length === 1) {
    fecha = salida.toLocaleString().split(' ');
  }
  
  return (
    <tr id = {id}>
      <td style={{width: '100px'}}>
        <b>{mes}</b>{" "}
      </td>
      <td className={vencido}>
       (<i class={like} aria-hidden="true"></i>) <b>{fecha[0]}  </b> 
      </td>
    </tr>
  );
};

export default CardFechasVencimiento;
