import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory}  from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import CarToefl from "../element/CarToefl";
import Menu from './Menu';
// Contexto
import dataContext from "../context/User";
// Helper 
import GetFetch from '../helper/GetFetch';
// import ShortData from '../helper/ShortData';
import loadMenu from '../js/main';
import Footer from './Footer';

const Toefl = () => {

  const history = useHistory();

  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem('dataUser');
 
  const [toefl, setToefl] = useState({});
  const [aprobado, setAprobado] = useState();
  const [fechaExamen, setFechaExamen] = useState();
  const [tipoToefl, setTipoToefl] = useState();
  const [solvencia, setSolvencia] = useState();

  useEffect(() => {

    if (datos) {
      let cred = JSON.parse(datos);
       dataUser.token = cred.token;
       dataUser.nombre = cred.nombre;
       dataUser.carnet = cred.carnet;
       dataUser.perfil = cred.perfil;
       dataUser.carrera = cred.carrera;

      let toeflD = GetFetch.toefl(cred.carnet, cred.token);      
      toeflD.then((dataT) => {
        setToefl(dataT);
        if(localStorage.getItem('solvencia')){
          setSolvencia('INSOLVENTE')
          setTimeout(() => {
            localStorage.removeItem('solvencia')
          }, 5000);
        }
        });

      loadMenu();
    }else{
      history.push("/"); 
    }
  },[datos, history, dataUser]);

  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto
            foto={dataUser.carnet}
            alt="Foto alumno"
            tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            perfil={dataUser.perfil}
            carrera={dataUser.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4 homeLogin"> Toefl</h2>
        <p className="display-4">{aprobado}</p> 
        <p className=""><b>{tipoToefl}</b><br></br>{fechaExamen}</p> 
        {
          toefl.length > 0 
            ?
            toefl.map((element) => {      
              if (!aprobado) {
                setAprobado('APROBADO')
                let fecha = new Date(element.toefl_fecha);
                let formatFecha = fecha.toLocaleString().split(',');
                setFechaExamen(formatFecha[0])
                setTipoToefl(element.tipotoefl)
              }     
              return (
                <>       
                <CarToefl
                  datos={element} 
                  key = {element.estructura}
                  />
                </>
              )
            })
            :
            solvencia ? <><p className="text-danger display-4">{solvencia}</p></> :
            < >             
            <div className="text-center">
            <i className="fa fa-meh-o display-1 text-info" aria-hidden="true"></i> <br></br>
            <p className='display-4 text-info text-center'> Toefl no realizado</p>
            </div>
            </>
        }
      </div>
    </div>
  );
};

export default Toefl;
