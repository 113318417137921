import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import dataSuper from '../hooks/Conf';

const EventoDetalle = () => {

    const history = useHistory();
    const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');

    const [detalleEvento, setDetalleEventos] = useState({});

    let menuEventos = '/eventos'

    if (datos) {
        let cred = JSON.parse(datos);
        dataUser.token = cred.token;
        dataUser.nombre = cred.nombre;
        dataUser.carnet = cred.carnet;

        if (dataSuper.indexOf(cred.carnet.trim()) !== -1) {
            menuEventos = '/admineventoall';
        }

    } else {
        history.push("/");
    }

    var query = new URLSearchParams(window.location.search);
    console.log(query.get('id_noticia'));

    useEffect(() => {
        GetFetch.getEventosID(query.get('id_noticia'), dataUser.token)
            .then((eventosData) => {
                setDetalleEventos(eventosData);
            });
        loadMenu();
    }, [dataUser, query]);

    const noticiaBody = () => {
        return { __html: detalleEvento[0].noticia };
    }

    return (

        <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
                <div className="custom-menu">
                    <button
                        type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary"
                    >
                        <i className="fa fa-bars"></i>
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4">
                    <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

                    <HeaderMenu
                        carnet={dataUser.carnet}
                        nombre={dataUser.nombre}
                        posicion="text-center"
                    />

                    <Menu />

                    <div className="footer">
                        <p>
                            Copyright &copy;
                  {/* <script>
                                document.write(new Date().getFullYear());
                            </script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a> */}
                        </p>
                    </div>
                </div>
            </nav>

            <div id="content" className="p-4 p-md-5 pt-5">
                <h2 className="mb-4"> Detalle del evento</h2>
                {
                    detalleEvento.length > 0
                        ?
                        detalleEvento.map((elemt) => {
                            const { titulo, ruta_archivo, fecha_caducidad, unidad } = elemt;
                            // let f_inicio = new Date(fecha_inicio);
                            let f_caducidad = new Date(fecha_caducidad);
                            return (
                                <div class="card mb-2">
                                    <div class="card-header">
                                        {unidad}
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title">{titulo}</h5>
                                        <p class="card-text" dangerouslySetInnerHTML={noticiaBody()}></p>
                                        <img src={ruta_archivo} style={{ width: '80%' }} alt="ficha resumen" /><br></br>
                                        <Link to={menuEventos} className="btn btn-primary mt-3"> Volver a eventos</Link>
                                    </div>
                                    <div class="card-footer text-muted">
                                        Vencimiento: {f_caducidad.toLocaleString().substr(0, 10)}
                                    </div>
                                </div>
                            )
                        })
                        :
                        null
                }
            </div>
        </div>
    );
}

export default EventoDetalle;