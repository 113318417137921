import React from 'react';

const FormLogin = ({onChange, onSubmit, carnet, password}) => (
        <form 
        onSubmit={onSubmit} 
        >
                        <div className="form-group">
                            <label htmlFor="">Usuario</label>
                            <input 
                            type="text" 
                            className="form-control" 
                            id="carnet" 
                            name="carnet" 
                            aria-describedby="" 
                            onChange={onChange} 
                            value={carnet}/>                            
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Contraseña</label>
                            <input 
                            type="password" 
                            className="form-control" 
                            id="password" 
                            name="password" 
                            onChange={onChange} 
                            value={password}/>
                        </div>
                        {/* <hr></hr> */}
                        {/* <div class="col-6 form-check">
                            <input class="form-check-input" type="radio" name="loginSource"  value="0" defaultChecked onChange={onChange} />
                            <label class="form-check-label" for="exampleRadios1">
                                Login con Registro
                            </label>
                        </div>
                        <div class="col-6 form-check">
                            <input class="form-check-input" type="radio" name="loginSource"  value="1" onChange={onChange} />
                            <label class="form-check-label" for="exampleRadios2">
                                Login con CCESEN
                            </label>
                        </div> */}
                        <hr></hr>
                        <button type="submit" 
                        className="btn btn-block btn-primary" 
                        > Enviar</button>
                        <small id="emailHelp" className="form-text text-muted">* No compartir sus credenciales de acceso con nadie</small>
        </form>
)

export default FormLogin