import React, { useEffect, useState } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
// import CarCovid19 from "../element/CarCovid19";
// Contexto
// import dataContext from "../context/User";

// Helper
// import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from "../componentes/Footer";

import "../css/styleAdmin.css";

const HelpDesk = () => {
  const history = useHistory();

  let datos = localStorage.getItem("dataUser");
  datos = JSON.parse(datos);
  console.log(datos);

  if (!datos) {
    history.push("/");
  }
  

  const [user, setUser] = useState(datos.carnet);
  const [correo, setCorreo] = useState(datos.carnet);

  // const dataUser = useContext(dataContext);

  

  // if (dataSuper.indexOf(datos.carnet.trim()) === -1) {
  //   if (dataDireccionEstudiantil.indexOf(datos.carnet.trim()) === -1) {
  //     history.push("/");
  //   }
  // }  

  // setUser(datos.carnet)
  // setCorreo(datos.correo)

  useEffect(() => {
    loadMenu();
  }, []);

  // const btnHelpDesk = (e)=>{
  //   e.preventDefault();
  //   console.log('Redirigir a helpdesk');
  // }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={datos.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={datos.carnet}
            nombre={datos.nombre}
            perfil={datos.perfil}
            carrera={datos.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <div className="container">
          <h1 className="mt-4 text-center"> HelpDesk</h1>
          {/* <p className="text-center">Consulta de saldos pendientes</p> */}
         
              <div className="text-center">
              <span className="fa fa-desktop mr-3 display-1 mt-5 text-primary"></span>
              </div>
            {/* <p className="mt-4" >Sistema de mesa de ayuda proporcionado a docentes y usuarios adminsitrativos, para el envío de ticket, reportando problemas 
              de hardware o software de los equipos de aulas, hacer petición de servicios o reportar cualquier incidencia en las que el equipo 
              de IT, pueda tomar accción y brindar soluciones oportunas en un tiempo determinado.
            </p> */}

            <form action="https://www.esen.edu.sv/ext/helpdesk/index.php" method="post" target="_blank" className="mt-4">
              <input type="hidden" name="idusr" value={user}/>
              <input type="hidden" name="usu_pass" value={correo}/>
              <input type="submit" class="btn btn-primary btn-block" value="Ingresar"/>
            </form>
        </div>
      </div>
    </div>
  );
};

export default HelpDesk;
