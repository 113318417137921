import apiURL from '../hooks/useAPI';

const { fetchAPI } = apiURL;

const GetFetchPush = {};

const urlAPI = `${fetchAPI}notificacion/`;
// const urlAPI = `http://localhost:4000/api/notificacion/`;

const requestDataCanalTelegram = async(url) => {
    if (url) {
        try {
            const entryPointLogin = url;
            const optionFetch = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            return await fetch(entryPointLogin, optionFetch)
                .then(result => result.json())
                .then((data) => {
                    if (+data.success === 1) {
                        return data.result;
                    } else {
                        return data.result = {};
                    }
                })
        } catch (error) {
            console.log(error);
            return error;
        }
    } else {
        let res;
        return res.json({
            'message': 'EndPoint no definido'
        });
    }
}

const requestData = async(url, message) => {
    try {
        const entryPointLogin = url;
        return await fetch(entryPointLogin, {
                method: 'POST',
                body: message
            })
            .then(result => result.json())
            .then((data) => {
                return data;
            })
    } catch (error) {
        console.log(error);
        return error;
    }

}

const requestDataMessage = async(url, message) => {
    try {
        const entryPointLogin = url;
        return await fetch(entryPointLogin, {
                method: 'POST',
                body: message
            })
            .then(result => result.json())
            .then((data) => {
                return data;
            })
    } catch (error) {
        console.log(error);
        return error;
    }

}

const requestDataUp = async(url, photo) => {
    // console.log(photo);
    try {
        const entryPointLogin = url;
        return await fetch(entryPointLogin, {
                method: 'POST',
                body: photo
            })
            .then(result => result.json())
            .then((data) => {
                return data;
            })
    } catch (error) {
        console.log(error);
        return error;
    }

}

GetFetchPush.sendMessage = (message) => {
    return requestData(urlAPI + message);
}

GetFetchPush.sendPhoto = (photo) => {
    return requestDataUp(urlAPI + 'photo/', photo);
}

GetFetchPush.sendMessageEconomia = (message) => {
    return requestData(urlAPI + 'economia/', message);
}

GetFetchPush.sendPhotoEconomia = (photo) => {
    return requestDataUp(urlAPI + 'economiaphoto/', photo);
}

GetFetchPush.sendMessageDerecho = (message) => {
    return requestData(urlAPI + 'derecho/', message);
}

GetFetchPush.sendPhotoDerecho = (photo) => {
    return requestDataUp(urlAPI + 'derechophoto/', photo);
}

GetFetchPush.sendMessageIng = (message) => {
    return requestData(urlAPI + 'ing/', message);
}

GetFetchPush.sendMessageIngFoft = (message) => {
    return requestData(urlAPI + 'ingsoft/', message);
}
GetFetchPush.sendMessageGeneraciones = (message) => {
    return requestData(urlAPI + 'generaciones/', message);
}

GetFetchPush.sendPhotoIng = (photo) => {
    return requestDataUp(urlAPI + 'ingphoto/', photo);
}

GetFetchPush.sendMessageAdmin = (message) => {
    return requestData(urlAPI + 'admin/', message);
}

GetFetchPush.sendPhotoAdmin = (photo) => {
    return requestDataUp(urlAPI + 'adminphoto/', photo);
}

GetFetchPush.sendMessageProfesores = (message) => {
    return requestData(urlAPI + 'profesores/', message);
}

GetFetchPush.sendPhotoProfesores = (photo) => {
    return requestDataUp(urlAPI + 'profesoresphoto/', photo);
}

GetFetchPush.sendMessageAll = (message) => {
    return requestData(urlAPI + 'all/', message);
}

GetFetchPush.sendPhotoAll = (photo) => {
    return requestDataUp(urlAPI + 'allphoto/', photo);
}

GetFetchPush.sendMessageAllAdminEsen = (message) => {
    return requestData(urlAPI + 'alladminesen/' + message);
}

GetFetchPush.sendPhotoAllAdminEsen = (photo) => {
    return requestDataUp(urlAPI + 'alladminesenphoto/', photo);
}

GetFetchPush.sendAllAdminMessage = (message) => {
    return requestDataMessage(urlAPI + 'alladminesen/', message);
}

GetFetchPush.getCanalTelegram = (canal) => {
    const url = `${urlAPI}/canaltelegram/anio/${canal}`;
    return requestDataCanalTelegram(url);
}

GetFetchPush.getCanalTelegramUltimoAnio = (descrip) => {
    const url = `${urlAPI}/ultimoanio/${descrip}`;
    return requestDataCanalTelegram(url);
}

export default GetFetchPush;