import React from "react";

const CarNotificaciones = ({ cuerpoMensaje, urlImagen, fecha, toUser, key, unidad }) => {
    fecha = new Date(fecha)
    let onlyFecha = fecha.toLocaleString().split(',');
    if (onlyFecha.length === 1) {
      onlyFecha = fecha.toLocaleString().split(' ');
    }
    let publico_meta = null;
    let mensaje = cuerpoMensaje.replace(/\n\n/g, '<br><br>');
    toUser.trim() === 'Docente' ? publico_meta = 'Catedráticos' : publico_meta = toUser; 

    const alertaBody = ()=>{
        return {__html: mensaje};
    }

  return (
    <div className="card mt-2" key={key}>
      <div className="card-body text-justify">
        <p dangerouslySetInnerHTML={alertaBody()}></p>      
     </div>
      {
          urlImagen
          ?
          <img src={urlImagen} class="rounded mx-auto d-block" alt="NotificacionesImagen" style={{width: '20%'}}></img>
          :
          null
      }
      
      <small className='ml-4'>Publicado el: {onlyFecha[0]}  <br></br>
         De: <b>{unidad} </b><br></br>
         Para: {publico_meta}
      </small>
    </div>
  );
};

export default CarNotificaciones;
