import React, { useEffect, useState } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";

// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
import dataFinanzas from '../hooks/useFinzas';
import DtesView from "../element/DtesView";
// import GetFetch from "../helper/GetFetch";
import GetFetchDTE from "../helper/GetFetchDTE";
import loadMenu from "../js/main";
import Footer from "../componentes/Footer";

import "../css/styleAdmin.css";

const DteFinanzas = () => {
  const history = useHistory();

  const [dtes, setDtes] = useState([]);
  const [carnet, setCarnet] = useState({});

  let datos = localStorage.getItem("dataUser");

  if (!datos) {
    history.push("/");
  }

  datos = JSON.parse(datos);

  if (dataSuper.indexOf(datos.carnet.trim()) === -1) {
    if (dataDireccionEstudiantil.indexOf(datos.carnet.trim()) === -1 && dataFinanzas.indexOf(datos.carnet.trim()) === -1) {
      history.push("/");
    }
  }  

  let carnetSet = (e) => {
    setCarnet(e.target.value);
  };

  let onSutmit = (e) => {
    e.preventDefault();
    localStorage.setItem("currentCarnet", carnet);

    const dormData = {"carnet":carnet, "secret": "5ec4702eff406849a82818cb"}
    GetFetchDTE.alumnoHistoria(dormData).then(dataDTE=>{
      if (dataDTE.hasOwnProperty('success')  && dataDTE.success === 1) {
        setDtes(dataDTE.result);        
      }
    })
  };

  useEffect(() => {
    let currentCarnet = localStorage.getItem("currentCarnet");
    if (currentCarnet) {
      setCarnet(currentCarnet);
    } else {
      setCarnet("");
    }
    loadMenu();
    document.getElementById("textCarnet").focus();

    const dormData = {"secret": "5ec4702eff406849a82818cb"}
    GetFetchDTE.dteHistoriaFinanzas(dormData).then(dataDTE=>{      
        setDtes(dataDTE.result);              
    })
  }, []);

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={datos.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={datos.carnet}
            nombre={datos.nombre}
            perfil={datos.perfil}
            carrera={datos.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <div className="container">
          <h1 className="mt-4 text-center"> Facturas - Cuotas y otros pagos</h1>
          <p className="text-center">Consulta de pagos reaizados en caja 1 y caja 2</p>
          <form id="formUP" onSubmit={onSutmit}>
            <div class="form-group">
              <input
                id="textCarnet"
                type="text"
                placeholder="Carnet"
                className="form-control"
                onChange={carnetSet}
                autofocus
                value={carnet}
              ></input>
            </div>
            <div className="form-group">
              <input
                type="submit"
                className="btn btn-primary btn-block"
                value="Enviar"
              />
            </div>
          </form>
        </div>

        {
          dtes.length > 0 ? <DtesView data={dtes} /> : null
        }
      </div>
    </div>
  );
};

export default DteFinanzas;
