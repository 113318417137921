import React from "react";
import { Link } from 'react-router-dom';

const CarEventos = ({ elemento, tardie = 0, autor = 'app' }) => {
    const { id_noticia, titulo, noticia, unidad, creado_por } = elemento;
    let url_detalle = '/detalleventos?id_noticia=' + id_noticia;
    let editarEvento = '/adminevento?id_noticia=' + id_noticia;
    let editar = 0;

    const noticiaBody = ()=>{
        return {__html: noticia};
    }

    if (creado_por.trim() === autor.trim()) {
        editar = 1;
    }

  
    return (
        <div class="card mb-2">
            <div class="card-header">
                
                    </div>
            <div class="card-body">
                <h5 class="card-title">{titulo}</h5>
                <p class="card-text" dangerouslySetInnerHTML={noticiaBody()}></p>
                <Link to={url_detalle}  className="btn btn-primary"> Ver</Link>
                {
                    tardie && editar ? <Link to={editarEvento}  className="btn btn-info ml-2"> Editar</Link> : null
                }
            </div>
            <div class="card-footer text-muted">
                {/* Vencimiento: {f_caducidad.toLocaleString().substr(0,10)} */}
               Públicado por: <b> {unidad} </b>
                    </div>
        </div>
    )
}

export default CarEventos;