import React from "react";

const CarSolvencia = ({componente, solvencia}) => { 
    componente === 'CtasCtes' ? componente = 'Cuotas mensuales' : componente = componente;
    let alertSolvencia = '';
    solvencia !== 'Solvente' ? alertSolvencia = 'text-danger' : alertSolvencia = '';
    return (
                <tr><td><b>{componente}</b> </td><td className={alertSolvencia}><b>{solvencia}</b></td></tr>
    )   
};

export default CarSolvencia;
