import React, { useEffect, useState, useContext } from 'react';

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
// import socket from '../componentes/Socket';
import Menu from "../componentes/Menu";
import dataSuper from '../hooks/Conf';
import dataDE from '../hooks/useDireccionEstudiantil';

// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";

const EventosForm = () => {

    const history = useHistory();
    const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');

    const [titulo, setTitulo] = useState(null);
    const [noticia, setNoticia] = useState(null);
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [urlOldIMG, setUrlOldIMG] = useState(null);

    const [file, setFile] = useState(null);
    const [buttonEliminar, setButtonEliminar] = useState(0);

    if (datos) {
        let cred = JSON.parse(datos);
        dataUser.token = cred.token;
        dataUser.nombre = cred.nombre;
        dataUser.carnet = cred.carnet;
        dataUser.email = cred.correo;

        if (dataSuper.indexOf(cred.carnet.trim()) === -1) {
            if (dataDE.indexOf(cred.carnet.trim()) === -1) {
                history.push("/");
            }
        }
    } else {
        history.push("/");
    }

    let formatFecha = (fecha) => {
        let preFecha = new Date(fecha);
        let fechaPre = preFecha.toLocaleString().substr(0, 10).split('/');
        const es = navigator.userAgent.indexOf('Edg/') > -1;
        if (es) { // Si el navegado es Edge
            return fechaPre[2].substr(0, 4) + '-' + fechaPre[1] + '-' + fechaPre[0];
        } else {
            if (fechaPre[1] < 10) {
                fechaPre[1] = '0' + fechaPre[1];
            }
            if (fechaPre[0] < 10) {
                fechaPre[0] = '0' + fechaPre[0];
            }
            return fechaPre[2].substr(0, 4) + '-' + fechaPre[1].substr(-0, 2) + '-' + fechaPre[0].substr(-0, 2);
        }
    }

    const query = new URLSearchParams(window.location.search);

    useEffect(() => {

        let dataUpdate = () => {
            let ryequ = new URLSearchParams(window.location.search);
            if (ryequ.get('id_noticia')) {
                GetFetch.getEventosID(ryequ.get('id_noticia'), dataUser.token)
                    .then((eventosData) => {
                        setTitulo(eventosData[0].titulo);
                        setNoticia(eventosData[0].noticia.replace(/<br \/>/g, ' '));

                        setFechaInicio(formatFecha(eventosData[0].fecha_inicio));
                        setFechaFin(formatFecha(eventosData[0].fecha_caducidad));
                        prePhotoupdate(eventosData[0].ruta_archivo);
                        setUrlOldIMG(eventosData[0].ruta_archivo);
                        setButtonEliminar(1);
                    });
            }
        }

        dataUpdate();
        loadMenu();
    }, [dataUser]);

    let tituloSet = (e) => {
        setTitulo(e.target.value)
    }

    let noticiaSet = (e) => {
        setNoticia(e.target.value)
    }

    let fechaInicioSet = (e) => {
        setFechaInicio(e.target.value)
    }

    let fechaFinSet = (e) => {
        setFechaFin(e.target.value)
    }

    let cargarDatosPhoto = (e) => {
        prePhoto(e);
        setFile(e.target.files[0])
    }

    let prePhoto = (e) => {
        let imgDiv = document.getElementById('loadImagen'); //Elemento donde se carga la img vista previa
        let TmpPath = URL.createObjectURL(e.target.files[0]);
        imgDiv.innerHTML = `<img src="${TmpPath}" style='width: 45%' />`;
    }

    let prePhotoupdate = (url) => {
        let imgDiv = document.getElementById('loadImagen'); //Elemento donde se carga la img vista previa
        let TmpPath = url;
        imgDiv.innerHTML = `<img src="${TmpPath}" style='width: 45%' />`;
    }

    let onSutmit = async (e) => {

        e.preventDefault();
        const data = new FormData();
        data.append("secret", "5ec4702eff406849a82818cb");
        data.append("user", dataUser.email);
        data.append("file", file);
        data.append("titulo", titulo);
        data.append("noticia", noticia);
        data.append("fecha_inicio", fechaInicio);
        data.append("fecha_fin", fechaFin);
        data.append("carnet", dataUser.carnet);
        if (!query.get('id_noticia')) { //Insertar registro
            let setRegis = await GetFetch.setEventos(data, dataUser.token)
                .then((datosSet) => {
                    if (datosSet.success) {
                        return datosSet.success;
                    } else {
                        return 0;
                    }
                });
            if (+setRegis === 1) {

                //Emitit aviso con socket.io
                // avisoEvento(titulo, noticia);

                history.push("/admineventoall");
            }
        } else { //Actualizar registo
            data.append("id", query.get('id_noticia'));
            data.append("url_imagen", urlOldIMG);
            let setRegis = await GetFetch.updateEventos(data, dataUser.token)
                .then((datosSet) => {
                    if (datosSet.success) {
                        return datosSet.success;
                    } else {
                        return 0;
                    }
                });
            if (+setRegis === 1) {
                history.push("/admineventoall");
            }
        }

    }

    // let avisoEvento = (titulo, noticia) => {
    //     socket.emit('aviso_evento', titulo, noticia, dataUser.carnet);
    // }

    const EliminarEvento = async () => {

        const data = new FormData();
        data.append("id", query.get('id_noticia'));
        data.append("user", dataUser.email);
        data.append("secret", "5ec4702eff406849a82818cb");
        let setRegis = await GetFetch.downEventos(data, dataUser.token)
            .then((datosSet) => {
                if (datosSet.success) {
                    return datosSet.success;
                } else {
                    return 0;
                }
            });
        if (+setRegis === 1) {
            history.push("/admineventoall");
        }
    }

    return (

        <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
                <div className="custom-menu">
                    <button
                        type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary"
                    >
                        <i className="fa fa-bars"></i>
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4">
                    <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

                    <HeaderMenu
                        carnet={dataUser.carnet}
                        nombre={dataUser.nombre}
                        posicion="text-center"
                    />

                    <Menu />

                    <div className="footer">
                        <p>
                            Copyright &copy;
                  {/* <script>
                                document.write(new Date().getFullYear());
                            </script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a> */}
                        </p>
                    </div>
                </div>
            </nav>

            <div id="content" className="p-4 p-md-5 pt-5">
                {
                    buttonEliminar ?
                        <h2 className="mb-4"> Editar evento</h2>
                        :
                        <h2 className="mb-4"> Crear evento</h2>
                }

                <form className="" onSubmit={onSutmit}>
                    <div class="form-group">
                        <label for="text-titulo">Título</label>
                        <input type="text" className="form-control" id="text-titulo" placeholder="Título" name="titulo" onChange={tituloSet} value={titulo} />
                    </div>

                    <div class="form-group">
                        <label for="text-eventonoticia"> Evento / Noticia </label>
                        <textarea className="textTareaPush" id="text-eventonoticia" rows="8" name="noticia" onChange={noticiaSet} value={noticia}></textarea>
                    </div>

                    <div class="form-group">
                        <label for="text-fechaInicio"> Publicar el día</label>
                        <input type="date" className="form-control" id="text-fechaInicio" name="fecha_inicio" onChange={fechaInicioSet} value={fechaInicio} />
                    </div>

                    <div class="form-group">
                        <label for="text-fechaFin"> Deshabilitar publicación el día</label>
                        <input type="date" className="form-control" id="text-fechaFin" name="fecha_fin" onChange={fechaFinSet} value={fechaFin} />
                    </div>

                    <div class="form-group custom-file mt-3">
                        <input type="file" className="custom-file-input" id="customFile" name="file" onChange={cargarDatosPhoto} />
                        <label class="custom-file-label" for="customFile"> Subir imagen de referencia</label>
                    </div>

                    <div className='form-group mt-5'>
                        <input type="submit" className="btn btn-success btn-block" value="Guardar" />
                    </div>
                    {
                        buttonEliminar ?
                            <div className='form-group'>
                                <input type="button" className="btn btn-danger btn-block" value="Eliminar" onClick={EliminarEvento} />
                            </div>
                            :
                            null
                    }
                </form>
                <div id="loadImagen" className="mt-1"></div>
            </div>
        </div>
    );
}

export default EventosForm