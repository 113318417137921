import React, { useEffect, useState } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
// import CarCovid19 from "../element/CarCovid19";
// Contexto
// import dataContext from "../context/User";

// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from "../componentes/Footer";

import "../css/styleAdmin.css";

const AlumnosCIC19 = () => {
  const history = useHistory();

  // const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  if (!datos) {
    history.push("/");
  }

  datos = JSON.parse(datos);

  if (dataSuper.indexOf(datos.carnet.trim()) === -1) {
    if (dataDireccionEstudiantil.indexOf(datos.carnet.trim()) === -1) {
      history.push("/");
    }
  }  

  const [cicData, setCICData] = useState([]);
  const [cicDataSemana, setCICDataSemanasState] = useState();
  const [carnet, setCarnet] = useState({});
  const [alumno, setAlumno] = useState({});

  let onSutmit = (e) => {
    e.preventDefault();
    // GetFetch.cicovid19(datos.token, carnet).then((saldosData) => {
    //   document.getElementById("dataContenedorAlumno").style.display = "block";
    //   setCICData(saldosData);
    //   AlumnoDatos();
    // });

    GetFetch.cicovid19Permiso(datos.token, carnet).then((datosCIC) => {
      document.getElementById("dataContenedorAlumno").style.display = "block";
      setCICData(datosCIC);
      AlumnoDatos();
    });
    GetFetch.cicovid19PermisoSemanas(datos.token, carnet).then((datosCICS) => {
      setCICDataSemanasState(datosCICS);
    });
  };

  let carnetSet = (e) => {
    setCarnet(e.target.value);
  };

  let AlumnoDatos = () => {
    let datosAlum = GetFetch.datosAlumno(carnet, datos.token);
    datosAlum.then((data) => {
      if (data[0]) {
        setAlumno(data[0]);
      } else {
        setAlumno({});
      }
    });
  };

  useEffect(() => {
    loadMenu();
    document.getElementById("textCarnet").focus();
  }, []);

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={datos.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={datos.carnet}
            nombre={datos.nombre}
            perfil={datos.perfil}
            carrera={datos.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <div className="container">
          <h1 className="mt-4 text-center">Control de ingreso por covid-19</h1>
          {/* <p className="text-center">Consulta de saldos pendientes</p> */}
          <form id="formUP" onSubmit={onSutmit}>
            <div class="form-group">
              <input
                id="textCarnet"
                type="text"
                placeholder="Carnet"
                className="form-control"
                onChange={carnetSet}
              ></input>
            </div>
            <div className="form-group">
              <input
                type="submit"
                className="btn btn-primary btn-block"
                value="Enviar"
              />
            </div>
          </form>
          <div
            id="dataContenedorAlumno"
            className="p-4 p-md-5 pt-5"
            style={{ display: "none" }}
          >
            {/* <h2 className="mb-4"> Control de ingreso</h2> */}
            <div id="content" className="row">
              <div className="col-12 col-sm-12">
                <Foto foto={alumno.usuario} alt="Alumno" />
                <h3 className="text-center">
                  {alumno.usuario}
                  <br></br>
                  {alumno.nombres + " " + alumno.apellidos}
                </h3>
              </div>

              <div className="card-deck mb-12 text-center">
                {cicData
                  ? cicData.map((element, key) => {
                      return element.activo === 1 ? (
                        <div key={key} className="row col-12">
                          <div className="card text-white bg-success col-12">
                            <div className="card-header">
                              <i
                                className="fa fa-calendar-check-o"
                                aria-hidden="true"
                              ></i>{" "}
                              ACCESO PERMITIDO
                            </div>
                            <div className="card-body">
                              <p>
                                {" "}
                                SEMANA<br></br>
                                <b className="display-1">#{element.semana}</b>
                              </p>
                              <p className="card-text">
                                Recuerde usar siempre su mascarilla dentro del
                                campus ESEN
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          key={key}
                          className="card text-white bg-danger col-12"
                        >
                          <div className="card-header">
                            LO SENTIMOS, NO SE ENCUENTRA EN LA LISTA DE ACCESO
                            PERMITIDOS PARA ESTA SEMANA
                          </div>
                          <div className="card-body">
                            <p className="display-1 card-title">
                              <i
                                className="fa fa-hand-paper-o"
                                aria-hidden="true"
                              ></i>
                            </p>
                            <p className="card-text">
                              Para mayor información escribir al correo de
                              Registro Académico <b>irodriguez@esen.edu.sv</b>
                            </p>
                          </div>
                        </div>
                      );
                    })
                  : null}
                <div className="container">
                  <hr></hr>
                  <p>Las semanas que usted puede ingresar a ESEN son: </p>
                </div>
                {cicDataSemana ? (
                  cicDataSemana.map((element, index) => {
                    let f_inicio = new Date(element.fecha_inicio);
                    let f_fin = new Date(element.fecha_fin);
                    return (
                      <div key={index} className="row col-12">
                        <div className="card text-white bg-dark col-12">
                          <div className="card-header">SEMANA</div>
                          <div className="card-body">
                            <p className="display-1 card-text">
                              {element.semana}
                            </p>
                            <p className="card-text">
                              {f_inicio.toLocaleDateString()} -{" "}
                              {f_fin.toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>
                    Permisos no otorgados para ingresar a ESEN, para mayor
                    información escribir al correo de Registro Académico{" "}
                    <b>irodriguez@esen.edu.sv</b>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlumnosCIC19;
