import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import menuControl from "../hooks/useMenuControl";
// import socket from "./Socket";

const MenuAdmin = ({
  clasecssRegistro = "registro registro-menu",
  clasecssFinanzas = "finanzas finanzas-menu",
  clasecssCCESEN = "ccesen ccesen-menu",
}) => {
  const history = useHistory();
  // const { registroMenu, finanzasMenu, ccMenu, menuLoadDefault } = menuControl;
  const { ccMenu, menuLoadDefault } = menuControl;

  let cerrarSession = (e) => {
    e.preventDefault();
    localStorage.removeItem("dataUser");
    history.push("/");
  };

  useEffect(() => {
    menuLoadDefault();
    // let loadSocjet = () => {
    //   let datos = localStorage.getItem("dataUser");
    //   if (datos) {
    //     let cred = JSON.parse(datos);
    //     socket.emit("conectado", cred.nombre, cred.carnet, cred.perfil, "---");
    //     // socket.emit('directo_mensaje', 'mensaje directo pruebas de envio privado', '1010102');
    //     // socket.to('N3-vhVtoRlVoZzDEAAAR').emit('directo_mensaje', 'Hola, este es un mensaje directo para Fátima');
    //   }
    // };
    // loadSocjet();
  }, [menuLoadDefault]);

  // const [avisoEvento, setAvisoEvento] = useState(0);
  // const [avisoNotificacion, setAvisoNotificacion] = useState(0);

  // useEffect(() => {
  //   socket.on("aviso_evento", (titulo, noticia, carnetPublicador) => {
  //     let countEvento = +avisoEvento + 1;
  //     setAvisoEvento(countEvento);

  //     const audio = document.getElementById("audio");
  //     audio.play();
  //   });

  //   socket.on(
  //     "aviso_notificacion",
  //     (notificacion, usuario_destino, carnetPublicador) => {
  //       let countNotificacion = +avisoNotificacion + 1;
  //       setAvisoNotificacion(countNotificacion);

  //       const audio = document.getElementById("audio");
  //       audio.play();
  //     }
  //   );

  //   return () => {
  //     socket.off();
  //   };
  // }, [avisoEvento, avisoNotificacion]);

  return (
    <ul className="list-unstyled components mb-5 homeLogin">
      <li className="control-ccesen-menu" onClick={ccMenu}>
        <Link to="#">
          <span className="fa fa-plus mr-3"></span> CCESEN
        </Link>
      </li>
      <li className={clasecssCCESEN}>
        <Link to="/catalogo">
          <span className="fa fa-briefcase ml-3 mr-1"></span> Catálogo CCESEN
        </Link>
      </li>
      <li className={clasecssCCESEN}>
        <Link to="/erecursos">
          <span className="fa fa-book ml-3 mr-1"></span> E-RECURSOS
        </Link>
      </li>
      <li className={clasecssCCESEN}>
        <Link to="/tematicas">
          <span className="fa fa-map-signs ml-3 mr-1"></span> Guías temáticas
        </Link>
      </li>
      <li className={clasecssCCESEN}>
        <Link to="/inicio">
          <span className="fa fa-bookmark ml-3 mr-1"></span> Libros nuevos
        </Link>
      </li>
      <li className={clasecssCCESEN}>
        <Link to="/prestamos">
          <span className="fa fa-sticky-note ml-3 mr-1"></span> Préstamos
          activos
        </Link>
      </li>
      <li className={clasecssCCESEN}>
        <Link to="/salas">
          <span className="fa fa-paper-plane ml-3 mr-1"></span> Salas colectivas
          CC
        </Link>
      </li>
      <li>
        <Link to="/correo">
          <span className="fa fa-envelope-o mr-3"></span> Correo electrónico
        </Link>
      </li>
      <li>
        <Link to="/directorio">
          <span className="fa fa-address-book mr-3"></span> Directorio ESEN
        </Link>
      </li>
      <li className="active">
        <Link to="/eventos">
          <span className="fa fa-calendar mr-3"></span> Eventos
        </Link>
      </li>
      <li className="admin-general-menu">
        <Link to="/canchas">
          <span className="fa fa-connectdevelop mr-3"></span>Espacios deportivos
        </Link>
      </li>
      <li className="">
        <Link to="/helpdesk">
          <span className="fa fa-desktop mr-3"></span> HelpDesk
        </Link>
      </li>
      <li className="admin-general-menu">
        <Link to="/manualestilo">
          <span className="fa fa-stack-overflow mr-3"></span> Manual de estilo
        </Link>
      </li>
      <li className="admin-general-menu">
        <Link to="/notificaciones">
          <span className="fa fa-bullhorn mr-3"></span> Notificaciones
          {/* <span className="ml-2">
            {avisoNotificacion > 0 ? avisoNotificacion : null}
          </span> */}
        </Link>
      </li>
      <li>
        <Link to="/socialmedia">
          <span className="fa fa-camera-retro mr-3"></span> Redes sociales
        </Link>
      </li> 
      <li>
        <Link to="/passchange">
          <span className="fa fa-key mr-3"></span> Contraseña
        </Link>
      </li> 
      <li>
        <Link to="/" onClick={cerrarSession}>
          <span className="fa fa-sign-out mr-3"></span> Cerrar sesión
        </Link>

        <div>
          <audio id="audio" className="audio-element">
            <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source>
          </audio>
        </div>
      </li>
    </ul>
  );
};

export default MenuAdmin;
