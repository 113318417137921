import React from "react";

const FotoBiblio = ({ foto, alt, tamanio }) => {    
    // let url = `http://biblioteca.esen.edu.sv/${foto}`;
    // let url = `https://201.247.105.75:3000/static/usrs/${foto}`;
    return(
        <div className="text-center">
        {/* <img className = { tamanio } src = { url }  alt = { alt } /> */}
        <h1 className="display-1 text-warning"><i class="fa fa-book" aria-hidden="true"></i>.</h1>
        <br></br>
        </div>
    )
}

export default FotoBiblio