const GetFetchDTE = {};

const SPACE_ID = '1jypm8d9ychn';
const TOKEN = 'dNSXC25UzrdueWmeZDEsM7qzHtJ2tvr5pReqqqu7-UA';

// const urlAPI = `http://192.168.30.35:5000/api/dte/`;
const urlAPI = `https://api.esen.edu.sv/api/dte/`;

const requestData = async(url, dataForm) => {

    try {
        const entryPointLogin = url;

        return await fetch(entryPointLogin, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body:  JSON.stringify(dataForm)
        })
            .then(result => result.json())
            .then((data) => {
                return data;
            })
    } catch (error) {
        console.log(error);
        return error;
    }

}

GetFetchDTE.alumnoHistoria = (dataForm) => {
    return requestData(urlAPI + 'alumnohist', dataForm);
}

GetFetchDTE.dteHistoriaFinanzas = (dataForm) => {
    return requestData(urlAPI + 'histFinanzas', dataForm);
}



export default GetFetchDTE