import React, { useState } from "react";
import CarErecursos from "../element/CarErecursos";

import useErecursos from '../hooks/useErecursos';

const ErecursosItems = ({ carnet, recursos }) => {

    const [recurso] = useErecursos(recursos);

    const [db, setDB] = useState({});

    let inicalData = recurso.filter(erecurso => erecurso.tipo === 'DB');

    const carbody = document.getElementById('content');

    let dbErecursos = () => {
        setDB(recurso.filter(erecurso => erecurso.tipo === 'DB'));
        carbody.scrollIntoView({ behavior: 'smooth' });
    }

    let journalErecursos = () => {
        setDB(recurso.filter(erecurso => erecurso.tipo === 'JOURNAL'));
        carbody.scrollIntoView({ behavior: 'smooth' });
    }

    let ebookErecursos = () => {
        setDB(recurso.filter(erecurso => erecurso.tipo === 'EBOOK'));
        carbody.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className="text-center">
            <div className="btn-group" role="group" aria-label="Basic example">
                <button type="button" className="btn btn-success" onClick={dbErecursos}> Bases datos</button>
                <button type="button" className="btn btn-success" onClick={journalErecursos}> Journal</button>
                <button type="button" className="btn btn-success" onClick={ebookErecursos}> E-books</button>
            </div>
            <div className="card mt-1">
                <div className="card-body">
                    {
                        db.length > 0
                            ?
                            db.map((element) => {
                                return (
                                    <CarErecursos
                                        recurso={element}
                                        carnet={carnet}
                                    />
                                )
                            })
                            :
                            inicalData.map((element) => {
                                return (
                                    <CarErecursos
                                        recurso={element}
                                        carnet={carnet}
                                    />
                                )
                            })
                    }
                </div>
            </div>
            <div className="btn-group mt-2" role="group" aria-label="Basic example">
                <button type="button" className="btn btn-success" onClick={dbErecursos}> Bases datos</button>
                <button type="button" className="btn btn-success" onClick={journalErecursos}> Journal</button>
                <button type="button" className="btn btn-success" onClick={ebookErecursos}> E-books</button>
            </div>
        </div>

    );
}

export default ErecursosItems;