import React, { useEffect, useState, useContext } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory, Link } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import CarPasantiaAplicaciones from "../element/CarPasantiaAplicaciones";
// import socket from '../componentes/Socket';
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDE from "../hooks/useDireccionEstudiantil";

// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";

const PasantiasAllOFF = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [pasantias, setPasantias] = useState();

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
    dataUser.email = cred.correo;

    if (dataSuper.indexOf(cred.carnet.trim()) === -1) {
      if (dataDE.indexOf(cred.carnet.trim()) === -1) {
        history.push("/");
      }
    }
  } else {
    history.push("/");
  }

  useEffect(() => {
    let dataPasantias = () => {
      GetFetch.getPasantiaAllOFF(dataUser.token).then((pasantiaData) => {
        setPasantias(pasantiaData);
      });
    };
    dataPasantias();
    loadMenu();
  }, [dataUser]);

  // const pasantiaBody = (descripcion) => {
  //   return { __html: descripcion };
  // };

  let contador = 0;

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <div className="footer">
            <p>
              Copyright &copy;
              {/* <script>
                                document.write(new Date().getFullYear());
                            </script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a> */}
            </p>
          </div>
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Pasantías OFF</h2>
        {pasantias
          ? pasantias.map((data, i) => {
            contador++;
              return (
                <div key={i} className="card border-primary mb-3">
                  <h5 className="card-header">{contador}.  {data.titulo}</h5>
                  <div className="card-body">
                    {/* <p className="card-text" dangerouslySetInnerHTML={pasantiaBody(data.descripcion)}></p> */}
                    <p className="card-title">Empresa: <b>{data.empresa}</b></p>
                    <p >Área: {data.area}</p>
                    <Link to={'pasantias?id_pasantia='+ data._id} className="btn btn-primary btn-sm">
                      <i class="fa fa-pencil" aria-hidden="true"></i>  Editar
                    </Link>                      
                    <Link to={'adminpasantiasapply?id_pasantia='+ data._id} className="btn btn-warning btn-sm">
                      <i class="fa fa-check-circle-o" aria-hidden="true"></i>  Aplicantes:  <span><b>{data.aplicaciones.length}</b></span>
                    </Link>   
                    <CarPasantiaAplicaciones
                    datos = {data.aplicaciones}
                    _id = {data._id}
                     />                   
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default PasantiasAllOFF;
