import React, { useEffect, useContext, useState } from 'react';
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';

// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu"

//Element
import GuiasTematicasCollapse from '../element/GuiasTematicasCollapse';

//Hooks
import useErecursos from '../hooks/useErecursos';
import useGuiasTematicas from '../hooks/useGuiasTematicas';

// Contexto
import dataContext from "../context/User";

//Helper
import GetFetchContentfulCMS from "../helper/GetFetchContentfulCMS";
import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";
import Footer from './Footer';

const GuiasTematicas = () => {

    const history = useHistory();
    const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');

    const [guias, setGuias] = useState([]);
    const [source, setSource] = useState([]);

    const [recursos] = useErecursos(source);
    const [recursosGuias] = useGuiasTematicas(guias);


    useEffect(() => {

        if (datos) {
            let cred = JSON.parse(datos);
            dataUser.token = cred.token;
            dataUser.nombre = cred.nombre;
            dataUser.carnet = cred.carnet;

            let getContenido = async () => {
                let contenido = GetFetchContentfulCMS.allContenido();
                let content = await contenido.then((datosC) => {
                    setSource(datosC);
                    return datosC;
                });
                return content;
            }

            let getContenidoGuiasTematicas = async () => {
                let contenido = GetFetchContentfulCMS.allContenidoGuiasTematicas();
                let content = await contenido.then((datosC) => {
                    setGuias(datosC);
                    return datosC;
                });
                return content;
            }

            SetBitacora.set(cred.carnet, 'Guias_Tematicas', cred.token);

            getContenido();
            getContenidoGuiasTematicas();

            loadMenu();
        } else {
            history.push("/");
        }
    }, [datos, history, dataUser]);

    let guiaTematicasALL = [];
    let guiaTematicasItem = [];

    if (recursosGuias.length > 0) {

        recursosGuias.map((guia) => {
            guiaTematicasItem.push(guia.titulo);
            return null;
        })

    }

    guiaTematicasItem.sort();

    guiaTematicasItem.map((item) => {

        let ju = [];
        // PARA LAS GUIAS TEMATICAS SOLO LA INTRODUCCION DEL PROCEDIMIENTO
        recursos.map(erecurso => {
            let aarg = erecurso.guiasTematicas;
            if (aarg) {
                let list = aarg.filter(elemt => elemt === item);
                if (list.length > 0) {
                    // erecurso.guiasTematicas = [item];
                    ju.push(erecurso);
                }
            }
            return null;
        });

        guiaTematicasALL.push(ju);
        return null;
    })

    // console.log(recursos);
    // console.log(recursosGuias);
    // console.log(guiaTematicasALL);

    return (
        <div className="wrapper d-flex align-items-stretch" >
            <nav id="sidebar">
                <div className="custom-menu">
                    <button type="button" id="sidebarCollapse" className="btn btn-primary">
                        <i className="fa fa-bars" > </i> <span className="sr-only" > Toggle Menu </span> </button > </div> <div className="p-4">
                    <Foto foto={dataUser.carnet}
                        alt="Foto alumno"
                        tamanio="rounded" />

                    <HeaderMenu carnet={dataUser.carnet}
                        nombre={dataUser.nombre}
                        posicion="text-center"
                    />

                    <Menu />

                    <Footer /> 
                </div> 
            </nav >

            <div id="content" className="p-4 p-md-5 pt-5">
                <h2 className="mb-4" > Guías Temáticas </h2>

                {
                    guiaTematicasALL
                        ?
                        <
                            GuiasTematicasCollapse
                            guias={guiaTematicasALL}
                            guiasTitulo={guiaTematicasItem}
                            carnet={dataUser.carnet}
                        /> :
                        null
                }

            </div> </div>
    )

}


export default GuiasTematicas;