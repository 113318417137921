import React, { useEffect } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";

// Helper
import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";
import Footer from "./Footer";

const RedesSociales = () => {
  const history = useHistory();
  let datos = localStorage.getItem("dataUser");

  if (!datos) {
    history.push("/");
  }

  let cred = JSON.parse(datos);
  SetBitacora.set(cred.carnet, 'Redes_sociales', cred.token);
  useEffect(() => {
    loadMenu();
  }, []);

  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={cred.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={cred.carnet}
            nombre={cred.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Redes sociales</h2>

        <div className="container">
          <div class="list-group">
            <a
              href="https://www.instagram.com/esenelsalvador/"
              target="_blank"
              class="list-group-item list-group-item-action"
              aria-current="true"
              rel="noopener noreferrer"
            >
              <h2><i class="fa fa-instagram text-warning" aria-hidden="true"></i> <small> Instagram </small></h2>
            </a>
            <a
              href="https://www.facebook.com/ESENELSALVADOR"
              class="list-group-item list-group-item-action"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2><i class="fa fa-facebook-official text-primary" aria-hidden="true"></i><small>  Facebook</small></h2>
            </a>
            <a
              href="https://www.linkedin.com/school/esen/"
              class="list-group-item list-group-item-action"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2><i class="fa fa-linkedin-square text-info" aria-hidden="true"></i>  <small>LinkedIn </small></h2>
            </a>
            <a
              href="https://twitter.com/esenelsalvador"
              class="list-group-item list-group-item-action" 
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2><i class="fa fa-twitter-square text-primary" aria-hidden="true"></i>  <small>Twitter</small></h2>
            </a>
            <a
              href="https://www.youtube.com/channel/UCYYmOUkD3Z0RiWNvF5TC_sw"
              class="list-group-item list-group-item-action"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2><i class="fa fa-youtube-play text-danger" aria-hidden="true"></i>  <small>Youtube</small></h2>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedesSociales;
