import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory, Link } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import CarCanchas from "../element/CarCanchas";
import Menu from "./Menu";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import Horas from "../hooks/HorasEspaciosDeportivos";
// import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";
import Footer from "./Footer";

const Canchas = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;

    // SetBitacora.set(cred.carnet, 'Canchas', cred.token);
  } else {
    history.push("/");
  }

  const [reservas, setReservas] = useState();

  useEffect(() => {
    GetFetch.getDeportiosReservasAlumno(dataUser.carnet).then((data) => {
      if (data.length > 0) {
        setReservas(data);
      } else {
        setReservas(0);
      }
    });
    loadMenu();
  }, [dataUser.carnet]);

  let correlativo = 0;

  let formatoFecha = (fecha) => {
    let dia = fecha.substr(0, 2);
    let mes = fecha.substr(3, 2);
    let anio = fecha.substr(6, 4);

    return anio + "-" + mes + "-" + dia;
  };

  //Formato para calendario del navegador
  function FechaActual(i = 0) {
    let fecha = new Date(Date.now());

    let dd = fecha.getDate() + i;
    let mm = fecha.getMonth() + 1;
    let yyyy = fecha.getFullYear();

    if (mm < 10) {
      mm = "0" + mm;
    }

    if (dd < 10) {
      dd = "0" + dd;
    }

    return yyyy + "-" + mm + "-" + dd;
  }

  //CancelarReservas
  const btnCancelarReserva = (e) => {
    let _id = e.target.dataset.id;
    let carnet = e.target.dataset.carnet;
    let stado = "2";
    let spacios_libres = e.target.dataset.libres;
    let spacios_reservados = e.target.dataset.reservados;
    let hora_inicio = e.target.dataset.inicio;
    let hora_fin = e.target.dataset.fin;

    let idElemento = e.target.dataset.iid;

    const data = new FormData();
    data.append("secret", "5ec4702eff406849a82818cb");
    data.append("_id", _id);
    data.append("carnet", carnet);
    data.append("stado", stado);
    data.append("spacios_libres", spacios_libres);
    data.append("spacios_reservados", spacios_reservados);
    data.append("hora_inicio", hora_inicio);
    data.append("hora_fin", hora_fin);
    data.append(
      "horas",
      Horas.horas[hora_inicio] + " / " + Horas.horas[hora_fin]
    );

    GetFetch.setDeportiosCancelarReservaAlumno(data, dataUser.token).then(
      (data) => {
        if (+data.success === 1) {
          document.getElementById(idElemento).style.display = "none";
        }
      }
    );

    console.log(_id, carnet, spacios_libres, spacios_reservados);
  };

  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Espacios deportivos</h2>
        <Link className="btn btn-primary btn-block mt-1" to="/canchafutbol">
          FOOTBALL
        </Link>
        <Link
          className="btn btn-primary btn-block mt-1"
          to="/canchabasketsalafutvolei"
        >
          BASKETBALL
        </Link>
        <Link
          className="btn btn-primary btn-block mt-1"
          to="/canchafootballsala"
        >
          FOOTBALL SALA
        </Link>
        <Link
          className="btn btn-primary btn-block mt-1"
          to="/canchabasvoleibol"
        >
          VOLEIBOL
        </Link>
        <Link className="btn btn-primary btn-block mt-1" to="/canchapp1">
          TENIS DE MESA 1
        </Link>
        <Link className="btn btn-primary btn-block mt-1" to="/canchapp2">
          TENIS DE MESA 2
        </Link>
        <hr></hr>
        {reservas
          ? reservas.map((elemento, key) => {
              let fechaLimite = new Date(formatoFecha(elemento.fecha));
              let fechaPresente = new Date(FechaActual());
              if (fechaLimite >= fechaPresente) {
                correlativo++;
                return (
                  <div key={"bljd" + key}>
                    <div className="card border-primary mb-3">
                      <div className="card-header">
                        RESERVA # {correlativo}
                        <br></br>
                      </div>
                      <CarCanchas
                        reservasData={elemento.reservas}
                        fecha={elemento.fecha}
                        espacio={elemento.espacio}
                        Horas={Horas}
                        spacios_libres={elemento.spacios_libres}
                        spacios_reservados={elemento.spacios_reservados}
                        _id={elemento._id}
                        btnCancelarReserva={btnCancelarReserva}
                      />
                    </div>
                  </div>
                );
              }
              return null;
            })
          : null}
        <div className="container text-center">
          <i
            className="fa fa-futbol-o mr-2 display-4 mr-5 fa-spin"
            aria-hidden="true"
          ></i>
          <i
            className="fa fa-dribbble mr-2 fa-5x display-4 fa-spin"
            aria-hidden="true"
          ></i>
        </div>
        {/* <i
          key={"list"}
          className="fa fa-thumbs-up text-success display-4 col-12"
          aria-hidden="true"
        ></i>{" "}
        <span className="text-info">
          {" "}
          <b>Aprobada</b> por la adminsitración
        </span>
        <br></br> */}
        {/* <i
          key={"list"}
          className="fa fa-clock-o text-warning display-4 col-12"
          aria-hidden="true"
        ></i>{" "}
        <span className="text-info">
          <b>Espera de aprobación</b> por la adminsitración
        </span>
        <br></br>
        <i
          key={"list"}
          className="fa fa-hand-paper-o text-info display-4 co-12"
          aria-hidden="true"
        ></i>{" "}
        <span className="text-info">
          <b>Cancelada por alumno</b>
        </span>
        <br></br> */}
        {/* <i
          key={"list"}
          className="fa fa-thumbs-down text-danger display-4 col-12"
          aria-hidden="true"
        ></i>{" "}
        <span className="text-info">
          <b>Cancelada la administración</b>
        </span> */}
      </div>
    </div>
  );
};

export default Canchas;
