import apiURL from '../hooks/useAPI';

const { fetchAPI } = apiURL;

const setBitacora = {};

const requestData = async(url, token) => {
    if (url) {
        try {
            const entryPointLogin = url;
            const optionFetch = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'secret': '5ec4702eff406849a82818cb'
                }
            }
            return await fetch(entryPointLogin, optionFetch)
                .then(result => result.json())
                .then((data) => {
                    if (+data.success === 1) {
                        return data.result;
                    } else {
                        return data.result = {};
                    }
                })
        } catch (error) {
            console.log(error);
            return error;
        }
    } else {
        let res;
        return res.json({
            'message': 'EndPoint no definido'
        });
    }
}

setBitacora.set = (carnet, consulta, token) => {
    const url = `${fetchAPI}audibita/setbitacora?carnet=${carnet}&consulta=${consulta}`;
    return requestData(url, token);
}

export default setBitacora