import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
import FotoBiblio from "../element/FotoBiblio";
import CarPrestamosActivos from "../element/CardPrestamosBiblio";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from './Footer';

const PrestamosActivos = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem('dataUser');
  const [prestamos, setPrestamos] = useState([]);

  useEffect(() => {

    if (datos) {
      let cred = JSON.parse(datos);
      dataUser.token = cred.token;
      dataUser.nombre = cred.nombre;
      dataUser.carnet = cred.carnet;

      GetFetch.prestamosActivos(dataUser.carnet, dataUser.token)
      .then((dataPrestamos) => {
          setPrestamos(dataPrestamos);
        }
      );

      loadMenu();
    } else {
      history.push("/");
    }
  }, [datos, history, dataUser]);

  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Libros en préstamo</h2>
        <div className="card">
          <div className="card-body">
            {prestamos.length > 0 ? (
              prestamos.map((element) => {
                return (
                  <div className="row">
                    <div className="col-4">
                      <FotoBiblio
                        foto={element.portada}
                        alt='Libro de biblioteca CCESEN'
                        tamanio='rounded mx-auto d-block'
                      />
                    </div>
                    <div className="col-8">
                      <CarPrestamosActivos
                        titulo={element.titulo}
                        inventario={element.idcat}
                        devolucion={element.datedev}
                        complemento={element.complemento}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
                <h4 className="text-warning">
                  No se encuentran préstamos activos
                </h4>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrestamosActivos;
