import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from '../componentes/Menu';
import dataSuper from '../hooks/Conf';
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
//Hook
import useNotificaciones from '../hooks/useNotificaciones';

// Helper 
import GetFetchPush from '../helper/GetFetchPush';
import GetFetchNotificacion from "../helper/GetFetchNotificaciones";
import loadMenu from '../js/main';
import Footer from '../componentes/Footer';

import '../css/styleAdmin.css';
// import { data } from 'jquery';

const PushAdmin = () => {

    const history = useHistory();
    const {noti} = useNotificaciones;

    // const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');

    if (!datos) {
        history.push("/");
    }

    let cred = JSON.parse(datos);
    if (dataSuper.indexOf(cred.carnet.trim()) === -1) {
        if (dataDireccionEstudiantil.indexOf(cred.carnet.trim()) === -1) {
            history.push("/");
        }
    }

    datos = JSON.parse(datos);

    const [textMensage, setTextMensage] = useState(null);
    const [file, setFile] = useState(null);

    let cargarDatosMensaje = (e) => {
        setTextMensage(e.target.value)
    }

    let cargarDatosPhoto = (e) => {
        prePhoto(e);

        setFile(e.target.files[0])
    }

    let onSutmit = async (e) => {

        e.preventDefault();
        const data = new FormData();
        data.append("message", textMensage);
        data.append("file", file);
        data.append('idColaborador', cred.carnet)

        // console.log(data);

        btnEnviar(data);
               
        let pathImagen = {};
        pathImagen.data = '';
        if (file) {
            pathImagen = await loadImagenChange(data);
        }

        await GetFetchNotificacion.setNotificacion(
            textMensage,
            pathImagen.data,
            "Administrativo",
            cred.nombre,
            cred.token,
            '',
            cred.carnet
        );

        noti(textMensage, "Administrativo", cred.carnet);
    }

    const btnEnviar = async (data) => {
        if (textMensage) {
            let message = textMensage;
            // console.log(message);
            let result = await GetFetchPush.sendMessageAdmin(data);
            if (+result.success === 1) {
                document.getElementById('texttareaMessage').value = '';
                document.getElementById('pushAlert').innerHTML = `<div class="alert alert-warning alert-dismissible fade show" role="alert">
                <strong>Notificación enviado con exíto</strong> <br></br>
                ${message}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>`;
            }
            console.log(result.success);
        }
    }

    let loadImagenChange = async (dataFile) => {
        if (dataFile) {
           return await GetFetchPush.sendPhotoAdmin(dataFile);
        }
    }

    let prePhoto = (e) => {
        let imgDiv = document.getElementById('loadImagen'); //Elemento donde se carga la img vista previa
        let TmpPath = URL.createObjectURL(e.target.files[0]);
        imgDiv.innerHTML = `<img src="${TmpPath}" style='width: 45%' />`;
    }

    useEffect(() => {
        loadMenu();
    }, []);
    return (
        <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
                <div className="custom-menu">
                    <button
                        type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary"
                    >
                        <i className="fa fa-bars"></i>
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4">
                    <Foto
                        foto={datos.carnet}
                        alt="Foto alumno"
                        tamanio="rounded" />

                    <HeaderMenu
                        carnet={datos.carnet}
                        nombre={datos.nombre}
                        perfil={datos.perfil}
                        carrera={datos.carrera}
                        posicion="text-center"
                    />

                    <Menu />

                    <Footer />
                </div>
            </nav>

            <div id="content" className="p-4 p-md-5 pt-5">
                <div className="container text-center">
                    <h1 className="mt-1 text-secondary"> <i class="fa fa-bullhorn" aria-hidden="true"></i> <br></br>Personal administrativo </h1>
                    <p className="text-secondary"><b>Envío de notificaciones</b></p>
                    <form id='formUP' onSubmit={onSutmit} >
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Dígite mensaje de la notificación</label>
                            <textarea className="textTareaPush" style={{ height: '228px' }} id="texttareaMessage" name="message" rows="8" onChange={cargarDatosMensaje}></textarea>
                        </div>
                        <div class="form-group">
                            <input type="file" name="photo" id="fileIMGE" onChange={cargarDatosPhoto} />
                            <div id="loadImagen"></div>
                        </div>
                        <div className='form-group'>
                            <input type="submit" className="btn btn-secondary btn-block" value="Enviar" />
                        </div>
                    </form>
                    <hr></hr>
                    <div id="pushAlert"></div>
                </div>
                <Link to="/alertmenu" class="btn btn-dark btn-block"> <i class="fa fa-reply" aria-hidden="true"></i> Menú de alertas</Link>
            </div>
        </div>
    )

}

export default PushAdmin;