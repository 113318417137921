// import socket from "../componentes/Socket";

const useNotificaciones = [];

useNotificaciones.noti = (notificacion, usuarios_destino, carnet, anio) => {

    // socket.emit("aviso_notificacion", notificacion, usuarios_destino, carnet, anio);
    console.log(notificacion, usuarios_destino, carnet, anio);

}

export default useNotificaciones;