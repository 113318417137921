import React, { useEffect, useState } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
import CarAlumnoDatos from '../element/CarAlumnoDatos';
// Contexto
// import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from "../componentes/Footer";

import "../css/styleAdmin.css";
// import { data } from 'jquery';

const CargosMorasCC = () => {
  const history = useHistory();

  const [cargos, setCargos] = useState({});
  const [moras, setMoras] = useState(0);
  const [morasTitulos, setMorasTitulos] = useState();
  const [carnet, setCarnet] = useState({});
  const [alumno, setAlumno] = useState({});

  // const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  if (!datos) {
    history.push("/");
  }

  datos = JSON.parse(datos);

  if (dataSuper.indexOf(datos.carnet.trim()) === -1) {
    if (dataDireccionEstudiantil.indexOf(datos.carnet.trim()) === -1) {
      history.push("/");
    }
  }  

  let carnetSet = (e) => {
    setCarnet(e.target.value);
  };

  let onSutmit = (e) => {
    e.preventDefault();
    localStorage.setItem("currentCarnet", carnet);

    document.getElementById('dataContenedorAlumno').style.display = 'block';
    AlumnoDatos()

    let cargo = GetFetch.getCargosMorasBiblio(carnet, datos.token);
    let mora = GetFetch.getMorasBiblio(carnet, datos.token);
    let mt = GetFetch.getMorasTitulosBiblio(carnet, datos.token);

    Promise.all([cargo, mora, mt]).then((values) => {
      setCargos(values[0]);
      setMoras(values[1][0].valor);
      setMorasTitulos(values[2]);
    });
  };

  let AlumnoDatos = () => {
    let datosAlum = GetFetch.datosAlumno(carnet, datos.token);
    datosAlum.then((data) => {
      if (data[0]) {
        setAlumno(data[0]);
      }
    });
  };

  useEffect(() => {
    let currentCarnet = localStorage.getItem("currentCarnet");
    if (currentCarnet) {
      setCarnet(currentCarnet);
    } else {
      setCarnet("");
    }
    loadMenu();
    document.getElementById("textCarnet").focus();
  }, []);

  let totalCargo = 0;
  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={datos.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={datos.carnet}
            nombre={datos.nombre}
            perfil={datos.perfil}
            carrera={datos.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <div className="container">
          <h1 className="mt-4 text-center">
            {" "}
            Cargos y moras <br></br> <small>CCESEN</small>
          </h1>
          <form id="formUP" onSubmit={onSutmit}>
            <div class="form-group">
              <input
                id="textCarnet"
                type="text"
                placeholder="Carnet"
                className="form-control"
                onChange={carnetSet}
                autofocus
                value={carnet}
              ></input>
            </div>
            <div className="form-group">
              <input
                type="submit"
                className="btn btn-primary btn-block"
                value="Enviar"
              />
            </div>
          </form>

          <CarAlumnoDatos alumno={alumno} />
          <hr></hr>

          {moras ? <p className="lead">Mora: ${moras}</p> : null}
          {morasTitulos ? (
            <ul className="list-group">
              {morasTitulos.map((element) => {
                let prestamoFecha = new Date(element.dateadd);
                let devolucionFecha = new Date(element.datedev);
                let realDevolucionFecha = new Date(element.fechadevolucion);
                return (
                  <li key={element.dateadd} className="list-group-item">
                    {element.titulos} <br></br>
                    <span className="text-info">
                      <i class="fa fa-calendar" aria-hidden="true"></i> Fecha
                      del préstamo:<br></br> {prestamoFecha.toLocaleString()}
                    </span>
                    <br></br>
                    <span className="text-default">
                      <i class="fa fa-calendar" aria-hidden="true"></i> Fecha de
                      la devolución:<br></br> {devolucionFecha.toLocaleString()}
                    </span>
                    <br></br>
                    <span className="text-warning">
                      <i class="fa fa-calendar" aria-hidden="true"></i> Fecha
                      real de devolución:<br></br>{" "}
                      {realDevolucionFecha.toLocaleString()}
                    </span>
                  </li>
                );
              })}
            </ul>
          ) : null}

          {cargos.length > 0 ? (
            <>
              {cargos.map((el) => {
                totalCargo += el.cargo_costo;
                return null;
              })}
              <p id="co" className="lead">
                {" "}
                Cargos: ${totalCargo}
              </p>
              <ul className="list-group">
                {cargos.map((element) => {
                  let fecha = new Date(element.cargo_fecha);
                  return (
                    <li key={element.cargo_id} className="list-group-item">
                      {element.cargo_desc} <br></br>
                      <span className="text-info">
                        <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                        Fecha del cargo:<br></br> {fecha.toLocaleString()}
                      </span>
                      <br></br>
                      Cargo: ${element.cargo_costo}
                      <br></br>
                      <span className="text-success">
                        {element.cargos_tipo_desc}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : null}
          <div className="row">
            <p
              id="totalCargoMora"
              className="lead text-right text-primary mt-4 ml-4"
            >
              {" "}
              Total de mora + cargos:{" "}
              <span className="display-4"> ${+totalCargo + +moras}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CargosMorasCC;
