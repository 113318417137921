import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';

// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu"
import ErecursosItems from "../element/ErecursosItems";
// import { FacturaElectronica } from "./FacturaElectronica";
// Contexto
import dataContext from "../context/User";

//Helper
import GetFetchContentfulCMS from "../helper/GetFetchContentfulCMS";
import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";
import Footer from './Footer';

const Erecursos = () => {
    const history = useHistory();
    const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');

    const [recursos, setRecursos] = useState();

    useEffect(() => {

        if (datos) {
            let cred = JSON.parse(datos);
            dataUser.token = cred.token;
            dataUser.nombre = cred.nombre;
            dataUser.carnet = cred.carnet;

            let getContenido = async () => {
                let contenido = GetFetchContentfulCMS.allContenido();
                await contenido.then((datosC) => {
                    setRecursos(datosC);
                    return datosC;
                });
            }

            SetBitacora.set(cred.carnet, 'E-recursos', cred.token);

            getContenido();

            loadMenu();
        } else {
            history.push("/");
        }
    }, [datos, history, dataUser]);

    return (
        <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
                <div className="custom-menu" >
                    <button type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary" >
                        <i className="fa fa-bars" > </i> <span className="sr-only" > Toggle Menu </span> </button>
                </div>
                <div className="p-4">
                    <Foto foto={dataUser.carnet}
                        alt="Foto alumno"
                        tamanio="rounded" />

                    <HeaderMenu carnet={dataUser.carnet}
                        nombre={dataUser.nombre}
                        posicion="text-center" />

                    <Menu />

                    <Footer />
                </div>
            </nav>

            <div id="content" className="p-4 p-md-5 pt-5" >
                <h2 className="mb-4" > E - Recursos </h2>

                {/* <FacturaElectronica /> */}
                {
                    recursos
                        ?
                        <
                            ErecursosItems
                            carnet={dataUser.carnet}
                            recursos={recursos}
                        /> :
                        null
                }



            </div>
        </div>
    );
}

export default Erecursos;