
const useGuiasTematicas = (content) => {

    let recurso = [];

    if (content.items) {
        content.items.map((elemento) => {

            let itemRecurso = {};
            itemRecurso.titulo = elemento.fields.titulo;
            itemRecurso.descripcion = elemento.fields.descripcin;

            recurso.push(itemRecurso);
            return null
        });
    }

    return [recurso];

}

export default useGuiasTematicas;