import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import menuControl from "../hooks/useMenuControl";
// import socket from "./Socket";

const MenuSuperAdmin = ({
  clasecssRegistro = "registro registro-menu",
  clasecssFinanzas = "finanzas finanzas-menu",
  clasecssCCESEN = "ccesen ccesen-menu",
  clasecssNoti = "noti noti-menu",
  clasecssDireccionEstudiantil = "direccionestudiantil direccionestudiantil-menu"
}) => {
  const history = useHistory();

  const {
    registroMenu,
    finanzasMenu,
    ccMenu,
    menuLoadDefault,
    notificacionMenu,
    direccionEstudiantilsMenu
  } = menuControl;

  let cerrarSession = (e) => {
    e.preventDefault();
    localStorage.removeItem("dataUser");
    history.push("/");
  };

  useEffect(() => {
    menuLoadDefault();
    // let loadSocjet = () => {
    //   let datos = localStorage.getItem("dataUser");
    //   if (datos) {
    //     let cred = JSON.parse(datos);
    //     socket.emit("conectado", cred.nombre, cred.carnet, cred.perfil);
    //   }
    // };
    // loadSocjet();
  }, [menuLoadDefault]);

  // const [avisoEvento, setAvisoEvento] = useState(0);
  // const [avisoNotificacion, setAvisoNotificacion] = useState(0);

  // useEffect(() => {
  //   socket.on("aviso_evento", (titulo, noticia, carnetPublicador) => {
  //     console.log(titulo);
  //     console.log(noticia);
  //     console.log(carnetPublicador);

  //     let countEvento = +avisoEvento + 1;
  //     setAvisoEvento(countEvento);

  //     const audio = new Audio("../audio/new-evento.mp3");
  //     audio.play();
  //   });

  //   socket.on(
  //     "aviso_notificacion",
  //     (notificacion, usuario_destino, carnetPublicador) => {
  //       let countNotificacion = +avisoNotificacion + 1;
  //       setAvisoNotificacion(countNotificacion);

  //       const audio = document.getElementById("audio");
  //       audio.play();
  //     }
  //   );

  //   return () => {
  //     socket.off();
  //   };
  // }, [avisoEvento, avisoNotificacion]);

  return (
    <ul className="list-unstyled components mb-5 homeLogin">
            <li className="active">
        <Link to="/eventosaulas">
          <span className="fa fa-calendar mr-3"></span> Aulas y eventos
          {/* <span id="avisoEvento" className="ml-2">
            {avisoEvento > 0 ? avisoEvento : null}
          </span> */}
        </Link>
      </li>
      <li className="control-ccesen-menu" onClick={ccMenu}>
        <Link to="#">
          <span className="fa fa-plus mr-3"></span> CCESEN
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/catalogo">
          <span className="fa fa-briefcase ml-3 mr-1"></span> Catálogo CCESEN
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/erecursos">
          <span className="fa fa-book ml-3 mr-1"></span> E-RECURSOS
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/tematicas">
          <span className="fa fa-map-signs ml-3 mr-1"></span> Guías temáticas
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/inicio">
          <span className="fa fa-bookmark ml-3 mr-1"></span> Nueva bibliografía
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/prestamos">
          <span className="fa fa-sticky-note ml-3 mr-1"></span> Préstamos activos
        </Link>
      </li> 
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/salas">
          <span className="fa fa-paper-plane ml-3 mr-1"></span> Salas colectivas
          CC
        </Link>
      </li>

      {/* <li className="admin-general-menu">
        <Link to="/cicadmin">
          <span className="fa fa-thermometer mr-3"></span> Covid-19
        </Link>
      </li> */}
      <li>
        <Link to="/correo">
          <span className="fa fa-envelope-o mr-3"></span> Correo electrónico
        </Link>
      </li>
      <li className="control-direccionestudiantil-menu" onClick={direccionEstudiantilsMenu}>
        <Link to="#">
          <span className="fa fa-plus mr-3"></span> Dirección estudiantil
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssDireccionEstudiantil}>
        <Link to="/pasantias">
          <span className="fa fa-newspaper-o ml-3 mr-1"></span> Crear pasantía
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssDireccionEstudiantil}>
        <Link to="/adminpasantiasall">
          <span className="fa fa-briefcase ml-3 mr-1"></span> Pasantías
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssDireccionEstudiantil}>
        <Link to="/adminpasantiasalloff">
          <span className="fa fa-list ml-3 mr-1"></span> Pasantías OFF
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssDireccionEstudiantil}>
        <Link to="/pasantiasalumnosb">
          <span className="fa fa-search ml-3 mr-1"></span> Pasantías alumnos
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssDireccionEstudiantil}>
        <Link to="/pasantiaspf">
          <span className="fa fa-question-circle ml-3 mr-1"></span> Preguntas frecuentes
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssDireccionEstudiantil}>
        <Link to="/pasantiastips">
          <span className="fa fa-black-tie ml-3 mr-1"></span> Tips sobre pasantías
        </Link>
      </li>
      <li>
        <Link to="/directorio">
          <span className="fa fa-address-book mr-3"></span> Directorio ESEN
        </Link>
      </li>
      <li className="admin-general-menu">
        <Link to="/canchasadmin">
          <span className="fa fa-connectdevelop mr-3"></span>Espacios deportivos
        </Link>
      </li>
      <li className="control-finanzas-menu" onClick={finanzasMenu}>
        <Link to="#">
          <span className="fa fa-plus mr-3"></span> Finanzas
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/alumnossolvencia">
          <span className="fa fa-user-circle-o ml-3 mr-1"></span> Alumnos
          solvencia
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/alumnossaldo">
          <span className="fa fa-usd ml-3 mr-1"></span> Alumnos saldos
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/admincargomora">
          <span className="fa fa-money ml-3 mr-1"></span> Cargos y moras CC
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/dtefinanzas">
          <span className="fa fa-money ml-3 mr-1"></span> DTEs
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/fechaspago">
          <span className="fa fa-suitcase ml-3 mr-1"></span> Fechas de pago
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/admintalonario">
          <span className="fa fa-sticky-note ml-3 mr-1"></span>  Talonarios
        </Link>
      </li>
      <li className="">
        <Link to="/helpdesk">
          <span className="fa fa-desktop mr-3"></span> HelpDesk
        </Link>
      </li>
      <li className="admin-general-menu">
        <Link to="/manualestilo">
          <span className="fa fa-stack-overflow mr-3"></span> Manual de estilo
        </Link>
      </li>
      <li className="active">
        <Link to="/admineventoall">
          <span className="fa fa-calendar mr-3"></span> Noticias y avisos
          {/* <span id="avisoEvento" className="ml-2">
            {avisoEvento > 0 ? avisoEvento : null}
          </span> */}
        </Link>
      </li>
      <li className="control-notificaciones-menu" onClick={notificacionMenu}>
        <Link to="#">
          <span className="fa fa-plus mr-3"></span> Notificaciones
          {/* <span className="ml-2">
            {avisoNotificacion > 0 ? avisoNotificacion : null}
          </span> */}
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssNoti}>
        <Link to="/alertmenu">
          <span className="fa fa-bullhorn ml-3 mr-1"></span> Nueva notificación
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssNoti}>
        <Link to="/notificaciones">
          <span className="fa fa-eye ml-3 mr-1"></span> Ver notificaciones
        </Link>
      </li>

      <li className="control-registro-menu" onClick={registroMenu}>
        <Link to="#">
          <span className="fa fa-plus mr-3"></span> Registro Académico
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/admincum">
          <span className="fa fa-diamond ml-3 mr-1"></span> CUM
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/adminhorassociales">
          <span className="fa fa-hourglass-half ml-3 mr-1"></span> Horas sociales
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/adminalumnimateriasinscritas">
          <span className="fa fa-folder-open ml-3 mr-1"></span> Materias inscritas
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/alumnosnotas">
          <span className="fa fa-id-card-o ml-3 mr-1"></span> Notas
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/adminpracticas">
          <span className="fa fa-briefcase ml-3 mr-1"></span> Prácticas profesionales
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/admintoefl">
          <span className="fa fa-globe ml-3 mr-1"></span> Toefl
        </Link>
      </li>
      <li>
        <Link to="/socialmedia">
          <span className="fa fa-camera-retro mr-3"></span> Redes sociales
        </Link>
      </li> 
      <li>
        <Link to="/passchange">
          <span className="fa fa-key mr-3"></span> Contraseña
        </Link>
      </li> 
      <li>
        <Link to="/" onClick={cerrarSession}>
          <span className="fa fa-sign-out mr-3"></span> Cerrar sesión
        </Link>
        <div>
          <audio id="audio" className="audio-element">
            <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source>
          </audio>
        </div>
      </li>
    </ul>
  );
};
export default MenuSuperAdmin;
