import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetchContentfulCMS from "../helper/GetFetchContentfulCMS";
import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";
import Footer from "./Footer";

const PasantiasTips = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [tips, setTips] = useState();
  useEffect(() => {
    if (datos) {
      let cred = JSON.parse(datos);
      dataUser.token = cred.token;
      dataUser.nombre = cred.nombre;
      dataUser.carnet = cred.carnet;

      let getContenido = async () => {
        let contenido = GetFetchContentfulCMS.allContenidoPasantiasTips();
        await contenido.then((datosC) => {
          setTips(datosC.items);
        });
      };

      SetBitacora.set(cred.carnet, 'Pasantias_PF', cred.token);
      getContenido();

      loadMenu();
    } else {
      history.push("/");
    }
  }, [datos, history, dataUser]);
  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Pasantias TIPS</h2>       
        <div id="content" className="row">
        {
              tips 
              ?
              (
                tips.map((el, key) => {

                  return <div key={key} className="col-12 ">
                            <p className="text-info">{el.fields.pregunta}</p>
                            <p className="text-justify">{el.fields.respuesta.content[0].content[0].value}</p>
                            <hr></hr>
                         </div>
                })
              )
              :
              null
          }
        </div>
      </div>
    </div>
  );
};

export default PasantiasTips;
