import React, { useEffect, useState } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";

// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import CarTalonario from "../element/CarTalonario";
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
import dataFinanzas from '../hooks/useFinzas';
import CarAlumnoDatos from "../element/CarAlumnoDatos";

import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from "../componentes/Footer";

import "../css/styleAdmin.css";

const TalonariosAdmin = () => {
  const history = useHistory();

  const [talonarios, setTalonarios] = useState({});
  const [carnet, setCarnet] = useState({});
  const [alumno, setAlumno] = useState({});

  let datos = localStorage.getItem("dataUser");

  if (!datos) {
    history.push("/");
  }

  datos = JSON.parse(datos);

  if (dataSuper.indexOf(datos.carnet.trim()) === -1) {
    if (dataDireccionEstudiantil.indexOf(datos.carnet.trim()) === -1 && dataFinanzas.indexOf(datos.carnet.trim()) === -1) {
      history.push("/");
    }
  }  

  let carnetSet = (e) => {
    setCarnet(e.target.value);
  };

  let onSutmit = (e) => {
    e.preventDefault();
    localStorage.setItem("currentCarnet", carnet);
    let talonario = GetFetch.talonarios(carnet, datos.token);
    talonario.then((dataTalonario) => {
      if (!dataTalonario.length <= 0) {
        document.getElementById("dataContenedorAlumno").style.display = "block";
        setTalonarios(dataTalonario);
        AlumnoDatos();
      } else {
        document.getElementById("dataContenedorAlumno").style.display = "block";
        setTalonarios(0)
        AlumnoDatos();
      }
      //     dataTalonario.then((dataT) => {
      //   });
    });

    let AlumnoDatos = () => {
      let datosAlum = GetFetch.datosAlumno(carnet, datos.token);
      datosAlum.then((data) => {
        if (data[0]) {
          setAlumno(data[0]);
        }else{
            setAlumno({});
        }
      });
    };
  };

  useEffect(() => {
    let currentCarnet = localStorage.getItem("currentCarnet");
    if (currentCarnet) {
      setCarnet(currentCarnet);
    } else {
      setCarnet("");
    }
    loadMenu();
    document.getElementById("textCarnet").focus();
  }, []);

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={datos.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={datos.carnet}
            nombre={datos.nombre}
            perfil={datos.perfil}
            carrera={datos.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <div className="container">
          <h1 className="mt-4 text-center"> Talonario</h1>
          <p className="text-center">Consulta de talonario por alumno</p>
          <form id="formUP" onSubmit={onSutmit}>
            <div class="form-group">
              <input
                id="textCarnet"
                type="text"
                placeholder="Carnet"
                className="form-control"
                onChange={carnetSet}
                autofocus
                value={carnet}
              ></input>
            </div>
            <div className="form-group">
              <input
                type="submit"
                className="btn btn-primary btn-block"
                value="Enviar"
              />
            </div>
          </form>

          <CarAlumnoDatos alumno={alumno} />
          {talonarios.length > 0
            ? talonarios.map((element) => {
                return <CarTalonario datos={element} />;
              })
            : <span>---</span>}
        </div>
      </div>
    </div>
  );
};

export default TalonariosAdmin;
