import React, { useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
// Contexto
import dataContext from "../context/User";
// Helper
import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";
import Footer from './Footer';

const Correo = () => {

  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem('dataUser');
  let correo = null;
  let correoPrint = null;


  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
    correo = 'mailto:' + cred.carnet + '@esen.edu.sv?Subject=APPESEN'
    correoPrint = cred.carnet + '@esen.edu.sv';
    if (cred.perfil === 'Administrativo' || cred.perfil === 'Profesores' || cred.perfil === 'Decanos' || cred.perfil === 'Director General' || cred.perfil === 'Docente') {
      correo = 'mailto:' + cred.correo + '?Subject=APPESEN'
      correoPrint = cred.correo;
    }
    SetBitacora.set(cred.carnet, 'Correo_electronico', cred.token);
  } else {
    history.push("/");
  }

  useEffect(() => {
    loadMenu();
  }, []);

  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Correo institucional</h2>
        <br></br>
        <br></br>
        <div className="container text-center">
        <i class="fa fa-envelope-o mr-2 display-1" aria-hidden="true"></i>
          <h2 className="text-primary">{correoPrint}</h2>
          <a id="" href={correo} className="btn btn-dark btn-block"> <i class="fa fa-envelope-o mr-2" aria-hidden="true"></i> Abrir correo institucional</a>

        </div>
      </div>
    </div>
  );
};

export default Correo
