import React from 'react';
import apiURL from '../hooks/useAPI';

const CarDirectorioDetalle = ({ data }) => {
    const { fotoAPI } = apiURL;
    let Error_Cargar = (e) => {
        // let src = `http://biblioteca.esen.edu.sv/images/usrs/nouser.png`;
        let src = `${fotoAPI}static/usrs/nouser.png`;
        e.target.setAttribute("src", src);
    }

    const dataPrint = data.map((el) => {
        return el.fields
    })


    const dataSort = dataPrint.sort(function (a, b) {
        return a.nombre.localeCompare(b.nombre);
    });

    const goUp = document.getElementById('content');

    let upGo = () => {
        goUp.scrollIntoView({ behavior: 'smooth' });
    }
    // console.log(dataSort.find(elemento => elemento.nombre == '\/Blanca\/'));
    return (

        dataSort.map((elemento) => {
            const { codigo, nombre, cargo, correo, alias } = elemento;
            // let userFoto = `http://biblioteca.esen.edu.sv/images/usrs/${codigo}.jpg`;
            let userFoto = `${fotoAPI}static/usrs/${codigo}.jpg`;
            let email = `mailto:${correo}`;
            return (
                <div className="card col-12 col-sm-4 col-lg-3 text-center" style={{ width: '18rem' }}>
                    <img src={userFoto} className="rounded mx-auto d-block" style={{ width: '50%' }} alt="..." onError={Error_Cargar} />
                    <div className="card-body">                     
                            <small><b>{alias}</b></small>                        
                        <h5 className="card-title">{nombre}</h5>
                        <p className="card-text"> {cargo}</p>
                        <div className="row">
                            <div className="col-10">
                                <a href={email} className="btn btn-primary"> {correo}</a>

                            </div>
                            <div className="col-2">

                                <button className="btn btn-outline-info mb-1 goUP" href="#" onClick={upGo}> <i class="fa fa-arrow-up" aria-hidden="true"></i> </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    )

}

export default CarDirectorioDetalle;