import React from "react";

const CardCatalogoBiblio = ({titulo, resumen}) => (  
   
    <div>
        <p className="text-success">Título <br></br>
            <b>{titulo}</b>
        </p>
        <p><b className="text-info">Resumen </b><br></br> {resumen}</p>
        <hr></hr>
    </div>
);

export default CardCatalogoBiblio;
