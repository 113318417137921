import React, { useEffect, useState } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "../componentes/Menu";
// Helper
import GetFetch from "../helper/GetFetch";
import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";
import Footer from "../componentes/Footer";

import "../css/stylePlaceHolder.css";

const PassChange = () => {
  const history = useHistory();

  // const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  if (!datos) {
    history.push("/");
  }

  let cred = JSON.parse(datos);

  const [oldPass, setOldPass] = useState(null);
  const [newPass, setNewPass] = useState(null);
  const [confirPass, setConfirPass] = useState(null);
//   const [passCoincidencia, setPassCoincidencia] = useState(null);

  datos = JSON.parse(datos);
  SetBitacora.set(datos.carnet, 'Cambio_contraseña', datos.token);
  const getOldPass = (e) => {
    let pass = e.target.value;
    if (pass.length >= 7) {
      const data = new FormData();
      data.append("carnet", cred.carnet);
      data.append("password", pass);
      GetFetch.getPass(data, cred.token).then((datos) =>
        setOldPass(datos[0].total)
      );
    } else {
      setOldPass(null);
    }
  };

  const newPasss = (e) => {
    setNewPass(e.target.value);
  };

  const confirPasss = (e) => {
    setConfirPass(e.target.value);
  };

  const onSubmit = (e)=>{
      e.preventDefault();
      if (newPass &&  confirPass ) {
          const data = new FormData();
          data.append('carnet', cred.carnet);
          data.append('password', newPass);
          GetFetch.getPassUpdate(data, cred.token)
          .then((datos) => {
              if (+datos.changedRows === 1) {
                  setOldPass(null);
                  document.getElementById('alert').innerHTML = '<i class="fa fa-check display-3" aria-hidden="true"></i><p className="text-primary"><b>CAMBIO REALIZADO</b></p>';
                  document.getElementById("passactual").value = '';
              }
            })
      }
  }

  useEffect(() => {
    loadMenu();
    document.getElementById("passactual").focus();
  }, []);

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={datos.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={datos.carnet}
            nombre={datos.nombre}
            perfil={datos.perfil}
            carrera={datos.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

       <div id="content" className="p-4 p-md-5 pt-5">
        <div className="container">
          <h1 className="mt-4 text-center"> Cambio de contraseña</h1>
          <form>
            <fieldset>
              <legend>
                <i class="fa fa-key" aria-hidden="true"></i> Contraseña actual
              </legend>
              <div class="form-group">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  id="passactual"
                  onChange={getOldPass}
                />
              </div>
            </fieldset>
          </form>
          {oldPass !== 1 ? (
            <form>
              <fieldset disabled>
                <legend className="text-danger">
                  <b>
                    <i class="fa fa-lock" aria-hidden="true"></i>
                  </b>{" "}
                  Nueva contraseña
                </legend>
                <div class="form-group">
                  <input
                    type="password"
                    name="newpassword"
                    className="form-control"
                    placeholder="Nueva contraseña"
                    value=''
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    name="newpasswordrepet"
                    className="form-control"
                    placeholder="Repetir contraseña" 
                    value = ''
                  />
                </div>
              </fieldset>
            </form>
          ) : (
            <form onSubmit={onSubmit}>
              <fieldset>
                <legend className="text-success">
                  <b>
                    <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                  </b>{" "}
                  Nueva contraseña
                </legend>
                <div class="form-group">
                  <input
                    type="password"
                    name="newpassword"
                    className="form-control"
                    placeholder="Nueva contraseña"
                    onChange={newPasss}
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    name="newpasswordrepet"
                    className="form-control"
                    placeholder="Repetir contraseña"
                    onChange={confirPasss}
                  />
                </div>

                {newPass === confirPass ? (
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-block">
                      Cambiar
                    </button>
                  </div>
                ) : null}
              </fieldset>
            </form>
          )}
          <div id="alert" className="text-primary mt-5 text-center"></div>
        </div>
      </div>
    </div>
  );
};

export default PassChange;
