import React from "react";

const CarEspaciosParticipantes = ({ indice, getParticipante, deleteParticipante}) => {
  return (
    <div className="row">
      <div className="col-1">
        <div className="form-group">
          <input type="text" className="form-control" value={indice} disabled />
        </div>
      </div>
      <div className="col-4">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Carnet"
            name={'partipante_carnet' + indice}
            onChange={getParticipante}
            data-id = {"participantecarnet" + indice}   
            id={'textcarnet' + indice}       
            data-correlativo={indice}  
          />
          
        </div>
      </div>
      <div className="col-6">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name={'partipante_nombre' + indice}
            placeholder="Nombre"
            disabled
            id={"participantecarnet" + indice} 
          />
        </div>
      </div>
      <div className="col-1">
        <div className="form-group">
          <i className="fa fa-trash-o text-danger fa-2x" aria-hidden="true" data-correlativo={indice} id={"deleleparticipante" + indice}  data-id={"deleleparticipante" + indice} onClick={deleteParticipante}></i>
        </div>
      </div>
    </div>
  );
};

export default CarEspaciosParticipantes;
