import React, { useEffect, useState } from "react";
// import GetFetchPush from "../helper/GetFetchPush";
// import { FacturaElectronica } from "../componentes/FacturaElectronica";

const HeaderMenu = ({ carnet, nombre, perfil, carrera, posicion }) => {
  // let datos = localStorage.getItem("dataUser");
  // let correo = 'mailto:' + carnet + '@esen.edu.sv?Subject=APPESEN'
  // const [canalTelegram, setCanalTelegram] = useState();
  const [perfilAlumno, setPerfilAlumno] = useState();
  // const [carne, setCarne] = useState();
  const [carreraAlumno, setCarrreraAlumno] = useState();

  // let btn_activar_noti = 'btn btn-sm btn-warning mt-0';
  let leyenda_btn = 'Reactivar  notificaciones';

  // let showNotificacion = () => {
  //   let esen_new = localStorage.getItem("notificationSet");
  //   if (esen_new) {
  //     document.getElementById("campanaNoti").style.display = "inline";
  //     // document.getElementById("notificationActive").style.display = "none";
  //     document.getElementById("notificationActive").textContent = leyenda_btn;
  //     document.getElementById("notificationActive").classList.remove('btn-dark');
  //     document.getElementById("notificationActive").classList.add('btn-warning');
  //     document.getElementById("notificationActive").classList.add('btn-sm');
  //   }
  // };

  useEffect(() => {

    // let showNotificacion = () => {
    //   let esen_new = localStorage.getItem("notificationSet");
    //   if (esen_new) {
    //     document.getElementById("campanaNoti").style.display = "inline";
    //     // document.getElementById("notificationActive").style.display = "none";
    //     document.getElementById("notificationActive").textContent = leyenda_btn;
    //     document.getElementById("notificationActive").classList.remove('btn-dark');
    //     document.getElementById("notificationActive").classList.add('btn-warning');
    //     document.getElementById("notificationActive").classList.add('btn-sm');
    //   }
    // };

    document.getElementById("campanaNoti").style.display = "none";
    // showNotificacion();

    // let notificacionSet = localStorage.getItem("notificationSet");
    let datos = localStorage.getItem("dataUser");
    let cred = JSON.parse(datos);

    let carreraValidar = (perfil, carrera) => {
      if (
        perfil === "Administrativo" ||
        perfil === "Profesores" ||
        perfil === "Decanos" ||
        perfil === "Director General" ||
        perfil === "Docente"
      ) {
        carrera = null;
      }

      return carrera;
    };

    setPerfilAlumno(cred.perfil);
    setCarrreraAlumno(carreraValidar(cred.perfil, cred.carrera));

    // let carreraAnioCanal = null;
    // let preFijoFijo = "ESEN_";

    // if (!notificacionSet) {
      // switch (cred.perfil) {
      //   case "Administrativo":
      //     setCanalTelegram("https://t.me/joinchat/kgQb98TB5I41NTQ5");
      //     break;
      //   case "Profesores":
      //   case "Decanos":
      //   case "Director General":
      //   case "Docente":
      //     setCanalTelegram("https://t.me/joinchat/aNi01XwWCZ85YmFh");
      //     if (cred.sexo.trim() === 'm')  {
      //       setPerfilAlumno('Catedrático')            
      //     }else{
      //       setPerfilAlumno('Catedrática') 
      //     }
      //     break;
      //   case "Alumnos":
      //     switch (cred.carrera) {
      //       case "Ingeniería de Negocios":
      //         carreraAnioCanal =
      //           preFijoFijo + "IDN" + cred.carnet.substring(0, 4);
      //         GetFetchPush.getCanalTelegram(carreraAnioCanal).then(
      //           (dataCanal) => {
      //             if (dataCanal.length > 0) {
      //               setCanalTelegram(dataCanal[0].ruta);
      //             } else {
      //               //Si no encuentra canal para el año consultado asigna el del último año por default
      //               GetFetchPush.getCanalTelegramUltimoAnio("IDN").then(
      //                 (dataCanal) => {
      //                   if (dataCanal.length > 0) {
      //                     setCanalTelegram(dataCanal[0].ruta);
      //                   }
      //                 }
      //               );
      //             }
      //           }
      //         );
      //         break;
      //       case "Economía y Negocios":
      //         carreraAnioCanal =
      //           preFijoFijo + "LEN" + cred.carnet.substring(0, 4);
      //         GetFetchPush.getCanalTelegram(carreraAnioCanal).then(
      //           (dataCanal) => {
      //             if (dataCanal.length > 0) {
      //               setCanalTelegram(dataCanal[0].ruta);
      //             } else {
      //               //Si no encuentra canal para el año consultado asigna el del último año por default
      //               GetFetchPush.getCanalTelegramUltimoAnio("LEN").then(
      //                 (dataCanal) => {
      //                   if (dataCanal.length > 0) {
      //                     setCanalTelegram(dataCanal[0].ruta);
      //                   }
      //                 }
      //               );
      //             }
      //           }
      //         );
      //         break;
      //       case "Ciencias Jurídicas":
      //         carreraAnioCanal =
      //           preFijoFijo + "LCJ" + cred.carnet.substring(0, 4);
      //         GetFetchPush.getCanalTelegram(carreraAnioCanal).then(
      //           (dataCanal) => {
      //             if (dataCanal.length > 0) {
      //               setCanalTelegram(dataCanal[0].ruta);
      //             } else {
      //               //Si no encuentra canal para el año consultado asigna el del último año por default
      //               GetFetchPush.getCanalTelegramUltimoAnio("LCJ").then(
      //                 (dataCanal) => {
      //                   if (dataCanal.length > 0) {
      //                     setCanalTelegram(dataCanal[0].ruta);
      //                   }
      //                 }
      //               );
      //             }
      //           }
      //         );
      //         break;
      //       default:
      //         setCanalTelegram("");
      //         break;
      //     }
      //     break;

      //   default:
      //     setCanalTelegram("");
      //     break;
      // }
    // }else{
      if (cred.perfil  === 'Profesores' || cred.perfil === 'Decanos' || cred.perfil === 'Director General' || cred.perfil === 'Docente') {
          if (cred.sexo.trim() === 'm')  {
            setPerfilAlumno('Catedrático')            
          }else{
            setPerfilAlumno('Catedrática') 
          }
      }
    // }
  }, [leyenda_btn]);

  // let notificacionSet = () => {
  //   localStorage.setItem("notificationSet", 1);
  //   // document.getElementById("notificationActive").style.display = "none";
  //   document.getElementById("campanaNoti").style.display = "inline";
  //   showNotificacion();
  // };

  return (
    <div className="text-center">
      <h1 className={posicion}>
        <a href="/" className="logo  homeLogin">
          {carnet}{" "}
          <i
            id="campanaNoti"
            className="fa fa-bell text-warning"
            aria-hidden="true"
          ></i>
          <span>{nombre}</span>
          <span> {carreraAlumno}</span>
          <span>{perfilAlumno}</span>
        </a>
      </h1>
      {/* <a
        id="notificationActive"
        href={canalTelegram}
        target="_blank"
        rel="noopener noreferrer"
        class="btn btn-dark btn-sm mt-0"
        onClick={notificacionSet}
        onLoad={showNotificacion}
      >
        {" "}
        <i class="fa fa-bell" aria-hidden="true"></i> Activar notificaciones
      </a> */}

      {/* <FacturaElectronica /> */}
    </div>
  );
};

export default HeaderMenu;
