import React, { useEffect, useState, useContext } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory, Link } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
// import socket from '../componentes/Socket';
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDE from "../hooks/useDireccionEstudiantil";

// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";

const PasantiasAlumnosB = () => {
    const history = useHistory();
    const dataUser = useContext(dataContext);
    let datos = localStorage.getItem("dataUser");
    if (datos) {
        let cred = JSON.parse(datos);
        dataUser.token = cred.token;
        dataUser.nombre = cred.nombre;
        dataUser.carnet = cred.carnet;
        dataUser.email = cred.correo;
    
        if (dataSuper.indexOf(cred.carnet.trim()) === -1) {
          if (dataDE.indexOf(cred.carnet.trim()) === -1) {
            history.push("/");
          }
        }
      } else {
        history.push("/");
      }

      const [numPages, setNumPages] = useState(null);
      const [pageNumber, setPageNumber] = useState(1);
    
      function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
      }
    

      const [carnet, setCarnet] = useState('');
      const [pasantias, setPasantias] = useState();
      const [nombreAlumno, setNombreAlumno] = useState();
      const [correoAlumno, setCorreoAlumno] = useState();
      const [carreraAlumno, setCarreraAlumno] = useState();

      const [rutaCV, setRutaCV] = useState('wait');

      useEffect(() => {
        loadMenu();
      }, []);
      

      let carnetSet = (e) => {
        setCarnet(e.target.value);
      };

      let onSutmit = async (e) => {
        e.preventDefault();

        if (carnet !== '') {
          if (carnet.length === 8) {

            setRutaCV(`https://api.esen.edu.sv/cvs/${carnet}.pdf`); 

            const data = new FormData();
            data.append("secret", "5ec4702eff406849a82818cb");
            data.append("carnet", carnet);
            GetFetch.getApplyAlumno(data, dataUser.token).then((pasantiaData) => {
              setPasantias(pasantiaData.result); 
            });             

            let dataAlumno = await GetFetch.getDeportiosParticipante(
              carnet,
              dataUser.token
            );

            if (dataAlumno.length > 0) {
              setNombreAlumno(dataAlumno[0].nombres + " " + dataAlumno[0].apellidos);
              setCorreoAlumno(dataAlumno[0].correo_electronico);
              setCarreraAlumno(dataAlumno[0].carrera);               
    
            } else {
              setNombreAlumno("alumno no encontrado..");
              setCorreoAlumno('');
            }
          }
        }       
      };

  let contador = 0;

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <div className="footer">
            <p>
              Copyright &copy;
              {/* <script>
                                    document.write(new Date().getFullYear());
                                </script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a> */}
            </p>
          </div>
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
            
        <h2 className="mb-4"> Pasantías</h2>
        <small>Buscar pasantías por alumnos donde han aplicado y CV</small>

        <form onSubmit={onSutmit} className="mt-3">
            <div className="form-group">
                <label htmlFor="carnet">Carnet</label>
                <input type="number" className="form-control" id="carnet" name="carnet" placeholder="Carnet a buscar" onChange={carnetSet} />
            </div>
            <button type="submit" className="btn btn-primary btn-block">Buscar</button>
        </form>
        <br></br>
        {
          nombreAlumno
          ?
          <div>
            <p className="text-info">Nombre: <b>{nombreAlumno}</b><br></br><small>{carreraAlumno}</small><br></br><small>{correoAlumno}</small></p>
          </div>
          :
          null
        }
        <div className="row">
          <div className="col">
          {pasantias
            ? pasantias.map((data, i) => {
              contador++;
                return (
                  <div key={i} className="card border-primary mb-3">
                    <h5 className="card-header">{contador}. {data.titulo}</h5>
                    <div className="card-body">
                      <p className="card-title">Empresa: <b>{data.empresa}</b></p>
                      <p className="card-title">Área: {data.area}</p>
                      <Link to={'adminpasantiasapply?id_pasantia='+ data._id} className="btn btn-info btn-sm">
                        <i className="fa fa-check-square" aria-hidden="true"></i>  Ver pasantía
                      </Link>                      
                      {/* <Link to={'adminpasantiasapply?id_pasantia='+ data._id} className="btn btn-warning btn-sm">
                        <i class="fa fa-check-circle-o" aria-hidden="true"></i>  Aplicantes:  <span><b>{data.aplicaciones.length}</b></span>
                      </Link>                       */}
                    </div>
                  </div>
                );
              })
            : null}
          </div>
          <div className="col" as={pageNumber}>
          <Document renderMode='canvas' file={rutaCV} onLoadSuccess={onDocumentLoadSuccess}  error=""  className='col-12 text-center'>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
          </Document>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasantiasAlumnosB;
