import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import CarCanchasReservas from "../element/CarCanchasReservas";
import Menu from "../componentes/Menu";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import Horas from "../hooks/HorasEspaciosDeportivos";
// import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";
import Footer from "../componentes/Footer";

const CanchasConsultasAdmin = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
    dataUser.perfil = cred.perfil;

    // SetBitacora.set(cred.carnet, 'Canchas', cred.token);
  } else {
    history.push("/");
  }

  datos = JSON.parse(datos);

  if (dataUser.perfil !== 'Profesores'&& dataUser.perfil !== 'Decanos' && dataUser.perfil !== 'Administrativo' && dataUser.perfil !== 'Docente') {
    history.push("/");
  }

  const [reservas, setReservas] = useState();
  const [reservasApro, setReservasApro] = useState();
  const [cancelAlumno, setCancelAlumno] = useState();
  const [cancelAdmin, setCancelAdmin] = useState();
  const [espera, setEspera] = useState(0);
  const [upok, setUpOK] = useState(0);

  const esperaBTN = ()=>{
    if (+espera === 0) {
      setEspera(1);
      setUpOK(0);
    }else{
      setEspera(0);
      setUpOK(1);
    }
  }

  // const upOKBTN = (e)=>{
  //   if (+upok === 0) {
  //     setUpOK(1);
  //     setEspera(0);
  //   }else{
  //     setUpOK(0);
  //     setEspera(1);
  //   }
  // }

  useEffect(() => {
    // let fecha = Horas.formatFecha(new Date(Date.now()));
    let fecha = Horas.FechaActual();
    const data = new FormData();
    data.append("secret", "5ec4702eff406849a82818cb");
    data.append("fecha", fecha);
    GetFetch.getDeportiosAllFecha(data).then((data) => {
      setReservas(data.result);
    });
    GetFetch.getDeportiosAllFechaApro(data).then((data) => {
      setReservasApro(data.result);
    });

    const dataCA = new FormData();
    dataCA.append("secret", "5ec4702eff406849a82818cb");
    dataCA.append("fecha", fecha);
    dataCA.append("stado", '2');

    GetFetch.getDeportiosAllFechaGeneral(dataCA).then((data) => {
      setCancelAlumno(data.result);
    });

    const dataCADMIN = new FormData();
    dataCADMIN.append("secret", "5ec4702eff406849a82818cb");
    dataCADMIN.append("fecha", fecha);
    dataCADMIN.append("stado", '3');
    GetFetch.getDeportiosAllFechaGeneral(dataCADMIN).then((data) => {
      setCancelAdmin(data.result);
    });

    loadMenu();
  }, []);

  let correlativo = 0;

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Espacios deportivos</h2>
        <div className="container text-right">
        <i key={"list"} className="fa fa-microchip text-info display-4" aria-hidden="true" onClick={esperaBTN}></i> <span className="text-warning mr-3">  </span> 
        </div>

        {
          +espera === 0
          ?
          <div className="col">
          {reservas
            ? reservas.map((elemento, key) => {
                // console.log(elemento.reservas);
                correlativo++;
                return (
                  <div key={"bljd" + correlativo}>
                    <i key={"list"} aria-hidden="true" className="fa fa-clock-o text-warning display-4"></i> <span className="text-warning"> Por aprobar </span>
                    <div className="card border-warning mb-3">
                      <div className="card-header bg-warning">
                        <h3>{elemento.fecha}</h3>
                      </div>
                      <div className="card-body">
                        <CarCanchasReservas
                          reservasData={elemento.reservas}
                          correlativo={correlativo}
                          fecha={elemento.fecha}
                          horas={Horas}
                          espacio={elemento.espacio}
                          btnAprobar=''
                          idPasantia={elemento._id}
                          stado = '0'
                          libres = { JSON.stringify(elemento.spacios_libres)}
                          reservadas = {JSON.stringify(elemento.spacios_reservados)}
                          btnCancelarReserva = ''
                          hide = "1"
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
          :
          null
        }

        {
          +upok === 0
          ?
          <div className="col">
          {reservasApro
            ? reservasApro.map((elemento, key) => {
                // console.log(elemento);
                correlativo++;
                return (
                  <div key={"bljd" + correlativo}>
                    <i aria-hidden="true" className="fa fa-thumbs-up text-success display-4"></i> <span className="text-success">Aprobadas</span>
                    <div className="card border-success mb-3">
                      <div className="card-header bg-success ">
                        <h3>{elemento.fecha}</h3>
                      </div>
                      <div className="card-body">
                        <CarCanchasReservas
                          reservasData={elemento.reservas}
                          correlativo={correlativo}
                          fecha={elemento.fecha}
                          horas={Horas}
                          espacio={elemento.espacio}
                          btnAprobar=''
                          idPasantia={elemento._id}
                          stado = '1' 
                          libres = { JSON.stringify(elemento.spacios_libres)}
                          reservadas = {JSON.stringify(elemento.spacios_reservados)}
                          btnCancelarReserva = ''
                          hide = "1"
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
          :
          null
        }


         
          <div className="col">
          {cancelAlumno
            ? cancelAlumno.map((elemento, key) => {
                // console.log(elemento.reservas);
                correlativo++;
                return (
                  <div key={"bljd" + correlativo}>
                    <i key={"list"} aria-hidden="true" className="fa fa-hand-paper-o text-info display-4"></i> <span className="text-info"> Canceladas por alumnos</span>
                    <div className="card border-info mb-3">
                      <div className="card-header bg-info">
                        <h3>{elemento.fecha}</h3>
                      </div>
                      <div className="card-body">
                        <CarCanchasReservas
                          reservasData={elemento.reservas}
                          correlativo={correlativo}
                          fecha={elemento.fecha}
                          horas={Horas}
                          espacio={elemento.espacio}
                          btnAprobar=''
                          idPasantia={elemento._id}
                          stado = '2'
                          libres = { JSON.stringify(elemento.spacios_libres)}
                          reservadas = {JSON.stringify(elemento.spacios_reservados)} 
                          btnCancelarReserva = ''
                          hide = "1"
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        <div className="col">
          {cancelAdmin
            ? cancelAdmin.map((elemento, key) => {
                // console.log(elemento.reservas);
                correlativo++;
                return (
                  <div key={"bljd" + correlativo}>
                    <i key={"list"} aria-hidden="true" className="fa fa-thumbs-down text-danger display-4"></i> <span className="text-danger"> Canceladas por admin </span>
                    <div className="card border-danger mb-3">
                      <div className="card-header bg-danger">
                        <h3>{elemento.fecha}</h3>
                      </div>
                      <div className="card-body">
                        <CarCanchasReservas
                          reservasData={elemento.reservas}
                          correlativo={correlativo}
                          fecha={elemento.fecha}
                          horas={Horas}
                          espacio={elemento.espacio}
                          btnAprobar=''
                          idPasantia={elemento._id}
                          stado = '3'
                          libres = { JSON.stringify(elemento.spacios_libres)}
                          reservadas = {JSON.stringify(elemento.spacios_reservados)} 
                          btnCancelarReserva = ''
                          hide = "1"
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
     

      </div>
    </div>
  );
};

export default CanchasConsultasAdmin;
