const ShortData = {}

ShortData.MateriaHoraios = (materias) => {

    const grouped = [];
    const materiasMap = new Map();

    for (const entry of materias) {
        let materia = materiasMap.get(entry.materia);
        if (!materia) {
            materia = {
                materia: entry.materia,
                data: [],
            };
            grouped.push(materia);
            materiasMap.set(entry.materia, materia);
        }

        const dataEntry = Object.assign({}, entry);
        delete dataEntry.materia;
        materia.data.push(dataEntry);
    }
    return grouped;
};

ShortData.notasMaterias = (ciclo) => {

    const grouped = [];
    const cicloMap = new Map();

    for (const entry of ciclo) {
        let ciclo = cicloMap.get(entry.ciclo);
        if (!ciclo) {
            ciclo = {
                ciclo: entry.ciclo,
                data: [],
            };
            grouped.push(ciclo);
            cicloMap.set(entry.ciclo, ciclo);
        }

        const dataEntry = Object.assign({}, entry);
        delete dataEntry.ciclo;
        ciclo.data.push(dataEntry);
    }
    return grouped;
};

export default ShortData;