import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from '../componentes/Menu';
import dataSuper from '../hooks/Conf';
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
// Contexto
// import dataContext from "../context/User";

// Helper 
import loadMenu from '../js/main';
import Footer from '../componentes/Footer';

import '../css/styleAdmin.css';

const AlertMenu = () => {

    const history = useHistory();

    // const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');

    if (!datos) {
        history.push("/");
    }

    let cred = JSON.parse(datos);
    if (dataSuper.indexOf(cred.carnet.trim()) === -1) {
        if (dataDireccionEstudiantil.indexOf(cred.carnet.trim()) === -1) {
            history.push("/");
        }
    }

    datos = JSON.parse(datos);

    useEffect(() => {
        loadMenu();
    }, []);
    return (
        <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
                <div className="custom-menu">
                    <button
                        type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary"
                    >
                        <i className="fa fa-bars"></i>
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4">
                    <Foto
                        foto={datos.carnet}
                        alt="Foto alumno"
                        tamanio="rounded" />

                    <HeaderMenu
                        carnet={datos.carnet}
                        nombre={datos.nombre}
                        perfil={datos.perfil}
                        carrera={datos.carrera}
                        posicion="text-center"
                    />

                    <Menu />

                    <Footer />
                </div>
            </nav>

            <div id="content" className="p-4 p-md-5 pt-5">
                <div className="container text-center">
                    <h1 className="alertaAviso mt-1">Envío de notificaciones</h1>
                    <h1 className="display-1"><i class="fa fa-bullhorn" aria-hidden="true"></i> </h1>
                    <h1 className="display-5"> ALERTAS</h1>
                </div>

                <Link to="/adminpush" class="btn btn-primary btn-block"> ALUMNOS</Link>
                <hr></hr>
                <Link to="/pusheconomia" class="btn btn-warning btn-block"> Licenciatura en Economía y Negocios</Link>
                <Link to="/pushderecho" class="btn btn-danger btn-block"> Licenciatura en Ciencias Jurídicas</Link>
                <Link to="/pushing" class="btn btn-success btn-block"> Ingeniería de Negocios</Link>
                <Link to="/pushingsoft" class="btn btn-info btn-block">
                    Ingeniería de Software y Negocios Digitales
                </Link>
                    <hr></hr>
                <Link to="/pushgeneraciones" class="btn btn-dark btn-block">
                    ALUMNOS POR GENERACIÓN
                </Link>
                    <hr></hr>
                <Link to="/pushadminesen" class="btn btn-primary btn-block"> PERSONAL ESEN</Link>
                <Link to="/pushadmin" class="btn btn-secondary btn-block"> Personal administrativo</Link>
                <Link to="/pushprofesores" class="btn btn-info btn-block"> Catedráticos</Link>

            </div>
        </div>
    )

}

export default AlertMenu;