import React, { useEffect, useState, useContext } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
// import socket from '../componentes/Socket';
import Menu from "../componentes/Menu";

// Contexto
import dataContext from "../context/User";
// Helper
import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";

const ManualEstilo = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
    dataUser.email = cred.correo;
  } else {
    history.push("/");
  }

  // const [rutaCV, setRutaCV] = useState("wait");
  // const rutaCV = `http://localhost:3000/05-Manual-de-estilo-ESEN.pdf`;
  const rutaCV = `https://api.esen.edu.sv/docu/manual_de-estilo_esen.pdf`;

  useEffect(() => {
    loadMenu();
    SetBitacora.set(dataUser.carnet, 'Manual estilo', dataUser.token);
  }, [dataUser]);

  const btnAnterior = ()=>{
    if (pageNumber > 1) {      
      setPageNumber(pageNumber -1);
    }
  }

  const btnSiguiente = ()=>{
    if (pageNumber < 108) {      
      setPageNumber(pageNumber + 1);
    }
  }

  const verPaginaNumero = (e)=>{
    setPageNumber(+e.target.value);
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <div className="footer">
            <p>
              Copyright &copy;
              {/* <script>
                                document.write(new Date().getFullYear());
                            </script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a> */}
            </p>
          </div>
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5 text-center">
        <h2 className="mb-4"> Manual de estilo</h2>
        
        <span>Ver número de página: </span>
        <input className="mb-3" type="number" name="num" min="1" max="108" onChange={verPaginaNumero} />

        <div>
          <Document renderMode='canvas' file={rutaCV} onLoadSuccess={onDocumentLoadSuccess} className='col-12'>
            <Page scale='2' pageNumber={pageNumber} />
          </Document>
          <p className="mt-3">
            Page {pageNumber} of {numPages}
          </p>
          <div className="row">
            <div className="col-4" onClick={btnAnterior}><h4><i class="fa fa-arrow-left" aria-hidden="true"></i> </h4></div>
            <div className="col-4" ><span>Pagina: {pageNumber}</span></div>
            <div className="col-4" onClick={btnSiguiente}><h4>  <i class="fa fa-arrow-right" aria-hidden="true"></i></h4></div>
          </div>
        </div>
      <a href={rutaCV} download="Manual de estilo esen" target="_blank" rel="noopener noreferrer">DESCARGAR DOCUMENTO</a>
      </div>
    </div>
  );
};

export default ManualEstilo;
