import React from "react";

const CardPrestamosBiblio = ({titulo, inventario, devolucion, complemento}) => (  
   
    <div>
        <p className="text-success">Título <br></br>
            <b>{titulo}</b>
        </p>
        <p>Ejemplar: <b className="text-info">{complemento}</b> Inventario: <b className="text-info">{inventario}</b></p>
        <p>Fecha devolución: <b className="text-info">{devolucion}</b></p>
        <hr></hr>
    </div>
);

export default CardPrestamosBiblio;
