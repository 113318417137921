import React, { useEffect, useState } from 'react';

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from '../componentes/Menu';
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
import dataFinanzas from '../hooks/useFinzas';
import CarNotas from '../element/CarNotas';
import CarAlumnoDatos from '../element/CarAlumnoDatos';
// Contexto
// import dataContext from "../context/User";
// Helper 
import GetFetch from '../helper/GetFetch';
import ShortData from '../helper/ShortData';
import loadMenu from '../js/main';
import Footer from '../componentes/Footer';

import '../css/styleAdmin.css';
// import { data } from 'jquery';

const AlumnosNotas = () => {

    const history = useHistory();

    const [carnet, setCarnet] = useState({});
    const [notas, setNotas] = useState({});
    const [alumno, setAlumno] = useState({});
    const [solvencia, setSolvencia] = useState();

    // const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');

    if (!datos) {
        history.push("/");
    }

    datos = JSON.parse(datos);

    if (dataSuper.indexOf(datos.carnet.trim()) === -1) {
    if (dataDireccionEstudiantil.indexOf(datos.carnet.trim()) === -1 && dataFinanzas.indexOf(datos.carnet.trim()) === -1) {
      history.push("/");
    }
  }  

    let carnetSet = (e) => {
        setCarnet(e.target.value)
    }

    let onSutmit = (e) => {
        e.preventDefault();
        localStorage.setItem('currentCarnet', carnet);
        let notasMaterias = GetFetch.notasAdmin(carnet, datos.token);
        notasMaterias.then((dataNotas) => {
            if (!dataNotas.length <= 0) {
                document.getElementById('dataContenedorAlumno').style.display = 'block';
                setNotas(ShortData.notasMaterias(dataNotas));                
                AlumnoDatos();
            }else{
                document.getElementById('dataContenedorAlumno').style.display = 'block';
                AlumnoDatos();
                if(localStorage.getItem('solvencia')){
                    setSolvencia('INSOLVENTE')
                    setTimeout(() => {
                      localStorage.removeItem('solvencia')
                    }, 5000);
                }
            }
        });
    }

    let AlumnoDatos = () => {
        let datosAlum = GetFetch.datosAlumno(carnet, datos.token);
        datosAlum.then((data) => {
            if (data[0]) {
                setAlumno(data[0]);                
            }
        });
    }

    useEffect(() => {
        let currentCarnet = localStorage.getItem('currentCarnet');
        if (currentCarnet) {
            setCarnet(currentCarnet);
        }else{
            setCarnet('');
        }
        loadMenu();
        document.getElementById('textCarnet').focus();
    }, []);

    return (
        <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
                <div className="custom-menu">
                    <button
                        type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary"
                    >
                        <i className="fa fa-bars"></i>
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4">
                    <Foto
                        foto={datos.carnet}
                        alt="Foto alumno"
                        tamanio="rounded" />

                    <HeaderMenu
                        carnet={datos.carnet}
                        nombre={datos.nombre}
                        perfil={datos.perfil}
                        carrera={datos.carrera}
                        posicion="text-center"
                    />

                    <Menu />

                    <Footer />
                </div>
            </nav>

            <div id="content" className="p-4 p-md-5 pt-5">
                <div className="container">
                    <h1 className="mt-4 text-center"> Alumnos notas</h1>
                    <p className="text-center">Consulta de notas por alumno</p>
                    <form id='formUP' onSubmit={onSutmit} >
                        <div class="form-group">
                            <input id="textCarnet" type="text" placeholder="Carnet" className="form-control" onChange={carnetSet} autofocus value={carnet}></input>
                        </div>
                        <div className='form-group'>
                            <input type="submit" className="btn btn-primary btn-block" value="Enviar" />
                        </div>
                    </form>
                    
                            <CarAlumnoDatos
                                alumno={alumno}
                            />
                    {
                        notas.length > 0
                            ?
                            notas.map((element) => {
                                return (
                                    <CarNotas
                                        ciclo={element.ciclo}
                                        datos={element.data} />
                                )
                            })
                            :
                            solvencia ? <><p className="text-danger display-4">{solvencia}</p></> :
                           null
                    }
                </div>
            </div>
        </div>
    )

}

export default AlumnosNotas;