import React, { useEffect, useState } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
import dataFinanzas from '../hooks/useFinzas';
import CardSaldos from "../element/CardSaldos";
import CardPlanesPago from "../element/CardPlanesPago";
import CarAlumnoDatos from "../element/CarAlumnoDatos";
// Contexto
// import dataContext from "../context/User";

// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from '../componentes/Footer';

import "../css/styleAdmin.css";

const AlumnosSaldo = () => {
  const history = useHistory();

  // const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  if (!datos) {
    history.push("/");
  }

  datos = JSON.parse(datos);

  if (dataSuper.indexOf(datos.carnet.trim()) === -1) {
    if (dataDireccionEstudiantil.indexOf(datos.carnet.trim()) === -1 && dataFinanzas.indexOf(datos.carnet.trim()) === -1 ) {
      history.push("/");
    }
  }  

  const [saldos, setSaldos] = useState({});
  const [carnet, setCarnet] = useState({});
  const [alumno, setAlumno] = useState({});
  const [saldosPP, setSaldosPP] = useState({});

  let onSutmit = (e) => {
    e.preventDefault();
    localStorage.setItem('currentCarnet', carnet);
    GetFetch.saldosAlumno(carnet, datos.token).then((saldosData) => {
      document.getElementById("dataContenedorAlumno").style.display = "block";
      document.getElementById("contenedorSaldos").style.display = "block";
      setSaldos(saldosData);
      AlumnoDatos();
    });

    GetFetch.saldosPlanesPagoAlumno(carnet, datos.token).then(
      (saldosDataPP) => {
        setSaldosPP(saldosDataPP);
      }
    );
  };

  let carnetSet = (e) => {
    setCarnet(e.target.value);
  };

  let AlumnoDatos = () => {
    let datosAlum = GetFetch.datosAlumno(carnet, datos.token);
    datosAlum.then((data) => {
      if (data[0]) {
        setAlumno(data[0]);
      }
    });
  };

  useEffect(() => {
    let currentCarnet = localStorage.getItem('currentCarnet');
    if (currentCarnet) {
        setCarnet(currentCarnet);
    }else{
        setCarnet('');
    }
    loadMenu();
    document.getElementById("textCarnet").focus();
  }, []);

  let totalSaldo = 0;
  let totalSaldoPP = 0;

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={datos.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={datos.carnet}
            nombre={datos.nombre}
            perfil={datos.perfil}
            carrera={datos.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <div className="container">
          <h1 className="mt-4 text-center"> Alumnos saldos </h1>
          <p className="text-center">Consulta de saldos pendientes</p>
          <form id="formUP" onSubmit={onSutmit}>
            <div class="form-group">
              <input
                id="textCarnet"
                type="text"
                placeholder="Carnet"
                className="form-control"
                onChange={carnetSet}
                value={carnet}
              ></input>
            </div>
            <div className="form-group">
              <input
                type="submit"
                className="btn btn-primary btn-block"
                value="Enviar"
              />
            </div>
          </form>
          <CarAlumnoDatos alumno={alumno} />
          <div id="contenedorSaldos" style={{ display: "none" }}>
            <hr></hr>
            {saldosPP.length > 0 ? (
              <p>
                <b>
                  {" "}
                  <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                  <br></br> Cuotas pendientes de planes de pago
                </b>
              </p>
            ) : null}
            {saldosPP.length > 0 ? (
              <>
                <table className="table table-striped table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Cuota</th>
                      <th scope="col">Monto</th>
                      <th scope="col">Vencimiento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {saldosPP.map((element) => {
                      totalSaldoPP += element.monto;
                      return (
                        <CardPlanesPago
                          cuota={element.cuota}
                          monto={element.monto}
                          vencimiento={element.vencimiento}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <h4 className="text-info text-center">
                  <b>${totalSaldoPP.toFixed(2)} </b>
                  <br></br>
                  <small> Total planes de pago </small>
                </h4>
                <hr></hr>
              </>
            ) : null}
            {saldos.length > 0 ? (
              <p>
                <b>
                  <i class="fa fa-money" aria-hidden="true"></i> <br></br>Meses
                  pendientes de pago
                </b>
              </p>
            ) : null}
            <table className="table">
              <tbody>
                {saldos.length > 0 ? (
                  saldos.map((element) => {
                    totalSaldo += element.saldo;
                    return (
                      <CardSaldos
                        factura={element.factura}
                        concepto={element.concepto}
                        saldo={element.saldo}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="3">
                      <b className="text-primary">
                        No tiene saldos mensuales activos
                      </b>{" "}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
            <h4 className="text-info text-center">
              <b>${totalSaldo} </b>
              <br></br>
              <small> Total de saldo pendiente </small>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlumnosSaldo;
