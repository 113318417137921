import React, { useEffect, useState, useContext } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
// import CarCovid19 from "../element/CarCovid19";
import Menu from "../componentes/Menu";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from "./Footer";

const ControlaccesoCovid19 = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [cicData, setCICData] = useState();
  const [cicDataSemana, setCICDataSemanasState] = useState();

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
    dataUser.email = cred.correo;
  } else {
    history.push("/");
  }

  useEffect(() => {
    GetFetch.cicovid19Permiso(dataUser.token, dataUser.carnet).then(
      (datosCIC) => {
        setCICData(datosCIC);
      }
    );
    GetFetch.cicovid19PermisoSemanas(dataUser.token, dataUser.carnet).then(
      (datosCICS) => {
        setCICDataSemanasState(datosCICS);
      }
    );
    loadMenu();
  }, [dataUser]);

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Control de ingreso</h2>
        <div id="content" className="row">
          <div className="container">
            
            <div className="card-deck mb-12 text-center">
              {cicData ? (
                cicData.map((element, key) => {
                  return element.activo === 1 ? (
                   
                      <div key={key} className="row col-12">
                        <div className="card text-white bg-success col-12">
                          <div className="card-header">
                          <i className="fa fa-calendar-check-o"   aria-hidden="true"></i>  ACCESO PERMITIDO 
                          </div>
                          <div className="card-body">
                            <p> SEMANA<br></br>
                            <b className="display-1">#{element.semana}</b>  
                              
                            </p>
                            <p className="card-text">
                              Recuerde usar siempre su mascarilla dentro del campus ESEN
                            </p>
                          </div>
                        </div>
                      </div>
                    
                  ) : (
                    
                      <div key={key} className="card text-white bg-danger col-12">
                        <div className="card-header">
                          LO SENTIMOS, NO SE ENCUENTRA EN LA LISTA DE ACCESO  PERMITIDOS PARA ESTA SEMANA
                        </div>
                        <div className="card-body">
                          <p className="display-1 card-title"><i className="fa fa-hand-paper-o" aria-hidden="true"></i></p>
                          <p className="card-text">
                            Para mayor información escribir al correo de Registro Académico <b>irodriguez@esen.edu.sv</b>
                          </p>
                        </div>
                      </div>
                   
                  );
                })
              ) : (
                null
              )}
              <div className="container">
                <hr></hr>
                <p>Las semanas que usted puede ingresar a ESEN son: </p>
              </div>
              {cicDataSemana
                ? cicDataSemana.map((element, index) => {
                  let f_inicio = new Date(element.fecha_inicio)
                  let f_fin = new Date(element.fecha_fin)
                    return (
                      
                      <div key={index} className="row col-12">
                        <div className="card text-white bg-dark col-12">
                          <div className="card-header">SEMANA</div>
                          <div className="card-body">
                            <p className="display-1 card-text">{element.semana}</p>
                            <p className="card-text">{f_inicio.toLocaleDateString()}  -  {f_fin.toLocaleDateString()}</p>
                          </div>
                        </div>
                      </div>
                      
                    );
                  })
                : 
                <p>Permisos no otorgados para ingresar a ESEN, para mayor información escribir al correo de Registro Académico <b>irodriguez@esen.edu.sv</b></p>
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlaccesoCovid19;
