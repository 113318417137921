import React from 'react';

const CarAdquisiciones = ({ item }) => {
    // const urlImg = 'http://biblioteca.esen.edu.sv/';
    return (
        <div>
            {
                item.map((elemento) => {
                    return (
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="p-3 mb-2 bg-dark text-white" style={{borderRadius: '15px'}}>
                                            <br></br>
                                            {/* <img className="rounded mx-auto d-block" alt={elemento.idt4} src={urlImg + elemento.portada} /> */}
                                            <h1 className="display-1 text-warning"><i class="fa fa-book" aria-hidden="true"></i>.</h1>
                                            <p className="mt-2">{elemento.titulos}</p>
                                            <p>{elemento.autores}</p>
                                            <br></br>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )
                })
            }
        </div >

    )
}

export default CarAdquisiciones;