const Horas = [];

Horas.horas = {
    '1': '08:00',
    '2': '08:30',
    '3': '09:00',
    '4': '09:30',
    '5': '10:00',
    '6': '10:30',
    '7': '11:00',
    '8': '11:30',
    '9': '12:00',
    '10': '12:30',
    '11': '13:00',
    '12': '13:30',
    '13': '14:00',
    '14': '14:30',
    '15': '15:00',
    '16': '15:30',
    '17': '16:00',
    '18': '16:30',
    '19': '17:00',
    '20': '17:30'
}

Horas.HorasLibres = (idSelect, objetoHoras, horaRun = '08:00') => {
    horaRun = horaRun + ':00';
    let indice = 1; //indice de arranque para horas
    let opcion = '<option value="default">Seleccionar hora</option>';
    for (const key in objetoHoras) {
        if (objetoHoras[key] >= horaRun) {
            indice = key - 1;
            break;
        }
    }

    for (const key in objetoHoras) {
        if (key >= +indice) {
            opcion += `<option value="${key}">${objetoHoras[key]}</option>`;
        }
    }
    document.getElementById(idSelect).innerHTML = opcion;
}

// Formato para base de datos
Horas.formatFecha = (formato, i = 0) => {
    let f = String(formato);
    let a = f.split('-');
    return a[2] + "-" + a[1] + "-" + a[0];
}

Horas.FechaActual = (i = 0) => {
    let fecha = new Date(Date.now());

    let dd = fecha.getDate() + i;
    let mm = fecha.getMonth() + 1;
    let yyyy = fecha.getFullYear();

    if (mm < 10) {
        mm = "0" + mm;
    }

    if (dd < 10) {
        dd = "0" + dd;
    }
    return yyyy + "-" + mm + "-" + dd;
}

Horas.calculoHorasIntermedias = (inicio, fin) => {

    let intermedios = [];
    intermedios.push(inicio);
    for (let index = inicio + 1; index < fin; index++) {
        intermedios.push(index);
    }
    intermedios.push(fin);
    return intermedios

}

Horas.resetFin = () => {
    document.getElementById("selectHoraFin").selectedIndex = 0;
}

export default Horas;