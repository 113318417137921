import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetchDTE from "../helper/GetFetchDTE";
import loadMenu from "../js/main";
import Footer from './Footer';
import DtesView from "../element/DtesViewAlumnos";


const DtesAlumnos = () => {
    const history = useHistory();
    const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');
    const [dtes, setDtes] = useState([]);
    if (datos) {
        let cred = JSON.parse(datos);
        dataUser.token = cred.token;
        dataUser.nombre = cred.nombre;
        dataUser.carnet = cred.carnet;
    } else {
        history.push("/");
    }

    useEffect(() => {
        loadMenu();
        const dormData = { "carnet": dataUser.carnet, "secret": "5ec4702eff406849a82818cb"}
        GetFetchDTE.alumnoHistoria(dormData).then(dataDTE => {
            if (dataDTE.hasOwnProperty('success') && dataDTE.success === 1) {
                setDtes(dataDTE.result);
            }
        })
    }, [datos, history]);

    return (
        <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
                <div className="custom-menu">
                    <button
                        type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary"
                    >
                        <i className="fa fa-bars"></i>
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4">
                    <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

                    <HeaderMenu
                        carnet={dataUser.carnet}
                        nombre={dataUser.nombre}
                        posicion="text-center"
                    />

                    <Menu />

                    <Footer />
                </div>
            </nav>


            <div id="content" className="p-4 p-md-5 pt-5">
                <div className="container">
                    <h1 className="mt-4 text-center"> Facturas - Cuotas y otros pagos</h1>
                    <p className="text-center">Consulta de pagos reaizados</p>

                    {
                        dtes.length > 0 ? <DtesView data={dtes} /> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default DtesAlumnos
