import React from "react";
import Foto from './Foto';

const CarAlumnoDatos = ({ alumno }) => {
  if (alumno) {
    const { usuario, nombres, apellidos, correo_electronico, carrera, grupo, telefono_casa, telefono_celular, direccion } = alumno;
    
    return (
      <div id="dataContenedorAlumno" style={{display:'none'}} className="container">
        <div className="row">
          <div className="col-12 col-sm-8">
            <p className="font-weight-bold">
            <span className="fa fa-id-card-o mr-3"></span> {usuario}<br></br>
            <span className="fa fa-user-circle-o mr-3"></span>  {nombres} {apellidos}<br></br>
            <i class="fa fa-phone mr-3" aria-hidden="true"></i> {telefono_casa}<br></br>
            <i class="fa fa-volume-control-phone mr-3" aria-hidden="true"></i> {telefono_celular}<br></br>
            <i class="fa fa-envelope mr-3" aria-hidden="true"></i> {correo_electronico}<br></br>
            <i class="fa fa-map-signs mr-3" aria-hidden="true"></i> {direccion}<br></br>
            <i class="fa fa-graduation-cap mr-3" aria-hidden="true"></i>  {carrera}<br></br>
            <i class="fa fa-user mr-3" aria-hidden="true"></i>  {grupo}</p>
          </div>
          <div className="col-12 col-sm-4">
            <Foto
              foto={usuario}
              alt='Alumno'
            />
          </div>
        </div>
      </div>
    );
  }
};

export default CarAlumnoDatos;
