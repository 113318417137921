import React, { useState, useEffect, useContext } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import CarNotificaciones from "../element/CarNotificaciones";
import Menu from "./Menu";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetchNotificaciones";
import GetFetchPush from "../helper/GetFetchPush";
import dataSuper from '../hooks/Conf';
import loadMenu from "../js/main";
import Footer from '../componentes/Footer';

const Notificaciones = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [notificaciones, setNotificaciones] = useState({});
  let [canalTelegram, setCanalTelegram] = useState();

  if (!datos) {
    history.push("/");
  } 

  let cred = JSON.parse(datos);
  dataUser.token = cred.token;
  dataUser.nombre = cred.nombre;
  dataUser.carnet = cred.carnet;
  dataUser.carrera = cred.carrera;
  dataUser.perfil = cred.perfil;

  let notificacionSet = localStorage.getItem("notificationSet");

  let notificacionSett = () => {
    localStorage.setItem("notificationSet", 1);
  };

  // let canalTelegram = null;
  let carreraAnioCanal = null;
  let preFijoFijo = "ESEN_";
  let btn_activar_noti = 'btn btn-sm btn-warning mt-0';
  let leyenda_btn = 'Reactivar  notificaciones';


  if (!notificacionSet) {
    btn_activar_noti = 'btn btn-sm btn-dark mt-0';
    leyenda_btn = 'Activar notificaciones';

  }
        switch (cred.perfil) {
          case "Administrativo":
            canalTelegram = "https://t.me/joinchat/kgQb98TB5I41NTQ5";
            break;
          case "Profesores":
          case "Decanos":
          case "Director General":
          case "Docente":
            canalTelegram = "https://t.me/joinchat/aNi01XwWCZ85YmFh";           
            break;
          case "Alumnos":
            switch (cred.carrera) {
              case "Ingeniería de Negocios":
                carreraAnioCanal =
                  preFijoFijo + "IDN" + cred.carnet.substring(0, 4);
                GetFetchPush.getCanalTelegram(carreraAnioCanal).then(
                  (dataCanal) => {
                    if (dataCanal.length > 0) {
                      setCanalTelegram(dataCanal[0].ruta);
                    } else {
                      //Si no encuentra canal para el año consultado asigna el del último año por default
                      canalTelegram =  GetFetchPush.getCanalTelegramUltimoAnio("IDN").then(
                        (dataCanal) => {
                          if (dataCanal.length > 0) {
                            setCanalTelegram(dataCanal[0].ruta);
                          }
                        }
                      );
                    }
                  }
                );
                console.log(canalTelegram); 
                break;
              case "Economía y Negocios":
                carreraAnioCanal =
                  preFijoFijo + "LEN" + cred.carnet.substring(0, 4);
                GetFetchPush.getCanalTelegram(carreraAnioCanal).then(
                  (dataCanal) => {
                    if (dataCanal.length > 0) {
                      setCanalTelegram(dataCanal[0].ruta);
                    } else {
                      //Si no encuentra canal para el año consultado asigna el del último año por default
                      GetFetchPush.getCanalTelegramUltimoAnio("LEN").then(
                        (dataCanal) => {
                          if (dataCanal.length > 0) {
                            setCanalTelegram(dataCanal[0].ruta);
                          }
                        }
                      );
                    }
                  }
                );
                break;
              case "Ciencias Jurídicas":
                carreraAnioCanal =
                  preFijoFijo + "LCJ" + cred.carnet.substring(0, 4);
                GetFetchPush.getCanalTelegram(carreraAnioCanal).then(
                  (dataCanal) => {
                    if (dataCanal.length > 0) {
                      setCanalTelegram(dataCanal[0].ruta);
                    } else {
                      //Si no encuentra canal para el año consultado asigna el del último año por default
                      GetFetchPush.getCanalTelegramUltimoAnio("LCJ").then(
                        (dataCanal) => {
                          if (dataCanal.length > 0) {
                            setCanalTelegram(dataCanal[0].ruta);
                          }
                        }
                      );
                    }
                  }
                );
                break;
                default:
                setCanalTelegram("");
              break;
            }
            break;
            default:
              setCanalTelegram("");
              break;
        }
  
  let alertaVisualNoti = (dataNoti)=>{
    let alertaNoti = localStorage.getItem('alertaNotiLocal');
    if (alertaNoti) {
        //Recorriendo el resultado de notificaciones de la nube
        let tempNoti = JSON.parse(alertaNoti);
        dataNoti.map((elemento)=>{
            //Recorrer para ver si existe ID de notificaciones guardadas
            tempNoti.map((ele) => {
                if (tempNoti.indexOf(elemento._id.trim()) === -1) {
                    tempNoti.push(elemento._id);
                }
                return null;
            })
            return null;
        })
        localStorage.setItem('alertaNotiLocal', JSON.stringify(tempNoti));
        
    }else{
        let tempNoti = [];
        dataNoti.map((elemento, index) => {
            tempNoti.push(elemento._id);
            return null;
        })
        localStorage.setItem('alertaNotiLocal', JSON.stringify(tempNoti));
    }
    return null;
  }


  useEffect(() => {
    //Llamando a las notificaciones
    if (dataSuper.indexOf(dataUser.carnet.trim()) === -1) {
      if (dataUser.perfil === 'Alumnos') {
        if (dataUser.carrera === 'Ingeniería de Negocios') {
          dataUser.perfil = 'IDN' + dataUser.carnet.substr(0,4);
        }
        if (dataUser.carrera === 'Ciencias Jurídicas') {
          dataUser.perfil = 'LCJ' + dataUser.carnet.substr(0,4);
        }
        if (dataUser.carrera === 'Economía y Negocios') {
          dataUser.perfil = 'LEN' + dataUser.carnet.substr(0,4);
        }
      }

      GetFetch.getNotificaciones(dataUser.token, dataUser.carrera, dataUser.perfil).then((dataNoti) => {
        setNotificaciones(dataNoti);
        alertaVisualNoti(dataNoti);
      });
      
    }else{
      GetFetch.getNotificacionesAll(dataUser.token).then((dataNoti) => {
        setNotificaciones(dataNoti);
        alertaVisualNoti(dataNoti);
      });
    }

    loadMenu();
  }, [dataUser, history]);


  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Notificaciones</h2>
        <div className="text-right">
          <a
            id="notificationActive"
            href={canalTelegram}
            target="_blank"
            rel="noopener noreferrer"
            className={btn_activar_noti}
            style={{color:"white"}}
            onClick={notificacionSett} 
          >
            {" "}
            <i class="fa fa-bell" aria-hidden="true"></i> {leyenda_btn}
          </a>
        </div>

        {notificaciones.length > 0
          ? notificaciones.map((elemento, key) => {
              return (
                <CarNotificaciones
                  cuerpoMensaje={elemento.notificacion}
                  urlImagen={elemento.imagen}
                  fecha = {elemento.creado}
                  toUser = {elemento.public_to} 
                  key = {key} 
                  unidad = {elemento.unidadEnvio}
                />
              );
            })
          : <>
            <hr></hr>
            <h1 className="display-1 text-primary text-center"><i class="fa fa-bullhorn" aria-hidden="true"></i> </h1>
            <p className="text-primary text-center">Sin notificaciones recientes.</p>
          </>}
      </div>
    </div>
  );
};

export default Notificaciones;
