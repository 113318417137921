import React from 'react';
import MenuAlumnos from './MenuAlumnos';
import MenuAdmin from './MenuAdmin';
import MenuSuperAdmin from './MenuSuperAdmin';
import MenuFinzas from './MenuFinzas';
import MenuDireccionEstudiantil from './MenuDireccionEstudiantil';
import Menu404 from './Menu404';
import dataSuper from '../hooks/Conf';
import dataFinzas from '../hooks/useFinzas';
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';

const Menu = ({ clasecss }) => {
    let datos = localStorage.getItem('dataUser');

    if (datos) {
        let cred = JSON.parse(datos);
        if (cred.perfil === 'Alumnos' || cred.perfil === 'Estudiante') {
            return (
                <MenuAlumnos />
            )
        } else if (dataSuper.indexOf(cred.carnet.trim()) !== -1) {
            return (
                <MenuSuperAdmin />
            )
        } else if (dataFinzas.indexOf(cred.carnet.trim()) !== -1) {
            return (
                <MenuFinzas />
            )
        }  else if (dataDireccionEstudiantil.indexOf(cred.carnet.trim()) !== -1) {
            return (
                <MenuDireccionEstudiantil />
            )
        } 
        else if (cred.perfil === 'Profesores' || cred.perfil === 'Decanos' || cred.perfil === 'Administrativo' || cred.perfil === 'Docente') {
            return (
                <MenuAdmin />
            )
        } else {
            return (
                <Menu404 />
            )
        }
    } else {
        return (
            <Menu404 />
        )
    }
}

export default Menu