import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
// Contexto
// import dataContext from "../context/User";

import GetFetchPush from "../helper/GetFetchPush";
import GetFetchNotificacion from "../helper/GetFetchNotificaciones";
// Helper
import loadMenu from "../js/main";
//Hook
import useNotificaciones from "../hooks/useNotificaciones";
import Footer from "../componentes/Footer";

import "../css/styleAdmin.css";
// import { data } from 'jquery';

const PushGeneraciones = () => {
  const history = useHistory();
  const { noti } = useNotificaciones;

  // const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  if (!datos) {
    history.push("/");
  }

  let cred = JSON.parse(datos);
  if (dataSuper.indexOf(cred.carnet.trim()) === -1) {
    if (dataDireccionEstudiantil.indexOf(cred.carnet.trim()) === -1) {
      history.push("/");
    }
  }

  datos = JSON.parse(datos);

  const [textMensage, setTextMensage] = useState(null);
  const [file, setFile] = useState(null);

  const [checkbox0, setCheckbox0] = useState(null);
  const [checkbox1, setCheckbox1] = useState(null);
  const [checkbox2, setCheckbox2] = useState(null);
  const [checkbox3, setCheckbox3] = useState(null);
  const [checkbox4, setCheckbox4] = useState(null);
  const [checkbox5, setCheckbox5] = useState(null);

  let cargarDatosMensaje = (e) => {
    setTextMensage(e.target.value);
  };

  let cargarDatosPhoto = (e) => {
    prePhoto(e);

    setFile(e.target.files[0]);
  };

  let checkox0 = (e) => {
    if (e.target.checked) {
      setCheckbox0(e.target.value);
    } else {
      setCheckbox0(null);
    }
  };

  let checkox1 = (e) => {
    if (e.target.checked) {
      setCheckbox1(e.target.value);
    } else {
      setCheckbox1(null);
    }
  };

  let checkox2 = (e) => {
    if (e.target.checked) {
      setCheckbox2(e.target.value);
    } else {
      setCheckbox2(null);
    }
  };

  let checkox3 = (e) => {
    if (e.target.checked) {
      setCheckbox3(e.target.value);
    } else {
      setCheckbox3(null);
    }
  };

  let checkox4 = (e) => {
    if (e.target.checked) {
      setCheckbox4(e.target.value);
    } else {
      setCheckbox4(null);
    }
  };

  let checkox5 = (e) => {
    if (e.target.checked) {
      setCheckbox5(e.target.value);
    } else {
      setCheckbox5(null);
    }
  };

  let onSutmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("message", textMensage);
    data.append("file", file);
    data.append('idColaborador', cred.carnet)

    //Añadiendo los años de estudio a los que se enviaran las notificaciones
    if (checkbox0) {
      data.append("cero", checkbox0);
      // noti(textMensage, "IDN", cred.carnet, 5);
      // noti(textMensage, "IDN", cred.carnet, 4);
      // noti(textMensage, "IDN", cred.carnet, 3);
      // noti(textMensage, "IDN", cred.carnet, 2);
      // noti(textMensage, "IDN", cred.carnet, 1);
    }
    if (checkbox1) {
      data.append("uno", checkbox1);
      // noti(textMensage, "IDN", cred.carnet, 1);
    }
    if (checkbox2) {
      data.append("dos", checkbox2);
      // noti(textMensage, "IDN", cred.carnet, 2);
    }
    if (checkbox3) {
      data.append("tres", checkbox3);
      // noti(textMensage, "IDN", cred.carnet, 3);
    }
    if (checkbox4) {
      data.append("cuatro", checkbox4);
      // noti(textMensage, "IDN", cred.carnet, 4);
    }
    if (checkbox5) {
      data.append("cinco", checkbox5);
      //Enviando notificaciones
      // noti(textMensage, "IDN", cred.carnet, 5);
    }

    btnEnviar(data);

    //Enviando la photo
    let pathImagen = {};
    pathImagen.data = "";
    // if (file) {
      // pathImagen = await loadImagenChange(data);
    // }

    //Guardar el registro en la historia de notificaciones
    if (checkbox0) {
      setAlerta(1, pathImagen); //Prime anio
      setAlerta(2, pathImagen); //segundo anio
      setAlerta(3, pathImagen); //Tercer anio
      setAlerta(4, pathImagen); //Cuarto anio
      setAlerta(5, pathImagen); //Quinto anio
    }
    if (checkbox1) {
      setAlerta(1, pathImagen); //Prime anio
    }
    if (checkbox2) {
      setAlerta(2, pathImagen); //Prime anio
    }
    if (checkbox3) {
      setAlerta(3, pathImagen); //Prime anio
    }
    if (checkbox4) {
      setAlerta(4, pathImagen); //Prime anio
    }
    if (checkbox5) {
      setAlerta(5, pathImagen); //Prime anio
    }

    // noti(textMensage, "Ingeniería", cred.carnet);
  };

  let setAlerta = async (perfil, pathImagen) => {
    console.log(perfil);
    await GetFetchNotificacion.setNotificacion(
      textMensage,
      pathImagen.data,
      perfil,
      cred.nombre,
      cred.token,
      "GENERACIONES",
      cred.carnet
    );
  };

  const btnEnviar = async (data) => {
    if (textMensage) {
      let message = textMensage;
      // console.log(message);
      let result = await GetFetchPush.sendMessageGeneraciones(data);
      if (+result.success === 1) {
        document.getElementById("texttareaMessage").value = "";
        document.getElementById(
          "pushAlert"
        ).innerHTML = `<div class="alert alert-warning alert-dismissible fade show" role="alert">
                <strong>Notificación enviado con exíto</strong> <br></br>
                ${message}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>`;
      }
      console.log(result.success);
    }
  };

  // let loadImagenChange = async (dataFile) => {
  //   if (dataFile) {
  //     return await GetFetchPush.sendPhotoIng(dataFile);
  //   }
  // };

  let prePhoto = (e) => {
    let imgDiv = document.getElementById("loadImagen"); //Elemento donde se carga la img vista previa
    let TmpPath = URL.createObjectURL(e.target.files[0]);
    imgDiv.innerHTML = `<img src="${TmpPath}" style='width: 45%' />`;
  };

  const anioActual = new Date().getFullYear();

  // console.log(anioActual);

  useEffect(() => {
    loadMenu();
  }, []);
  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={datos.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={datos.carnet}
            nombre={datos.nombre}
            perfil={datos.perfil}
            carrera={datos.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <div className="container text-center">
          <h1 className="mt-1 text-dark">
            {" "}
            <i class="fa fa-bullhorn" aria-hidden="true"></i> <br></br>
            ALUMNOS POR GENERACIONES
          </h1>
          <p className="text-success">
            <b>Envío de notificaciones</b>
          </p>
          <form id="formUP" onSubmit={onSutmit}>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">
                Dígite mensaje de la notificación
              </label>
              <textarea
                className="textTareaPush"
                style={{ height: "228px" }}
                id="texttareaMessage"
                name="message"
                rows="8"
                onChange={cargarDatosMensaje}
              ></textarea>
            </div>
            <hr></hr>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">
                Generación de estudio a enviar notificación
              </label>
              <br></br>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  name="1"
                  value="1"
                  onChange={checkox1}
                />
                <label class="form-check-label" for="inlineCheckbox1">
                  {anioActual}
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  name="2"
                  value="2"
                  onChange={checkox2}
                />
                <label class="form-check-label" for="inlineCheckbox2">
                  {anioActual -1}
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox3"
                  name="3"
                  value="3"
                  onChange={checkox3}
                />
                <label class="form-check-label" for="inlineCheckbox2">
                {anioActual - 2}
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox4"
                  name="4"
                  value="4"
                  onChange={checkox4}
                />
                <label class="form-check-label" for="inlineCheckbox2">
                  {anioActual - 3}
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox5"
                  name="5"
                  value="5"
                  onChange={checkox5}
                />
                <label class="form-check-label" for="inlineCheckbox2">
                  {anioActual - 4}
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox6"
                  name="0"
                  value="0"
                  onChange={checkox0}
                />
                <label class="form-check-label" for="inlineCheckboxTodas">
                  {" "}
                  Todos
                </label>
              </div>
            </div>
            <hr></hr>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">
                Imagen para notificación
              </label>
              <br></br>
              <input
                type="file"
                name="photo"
                id="fileIMGE"
                onChange={cargarDatosPhoto}
              />
              <div id="loadImagen"></div>
            </div>
            <div className="form-group">
              <input
                type="submit"
                className="btn btn-success btn-block"
                value="Enviar"
              />
            </div>
          </form>
          <hr></hr>
          <div id="pushAlert"></div>
        </div>
        <Link to="/alertmenu" class="btn btn-dark btn-block">
          {" "}
          <i class="fa fa-reply" aria-hidden="true"></i> Menú de alertas
        </Link>
      </div>
    </div>
  );
};

export default PushGeneraciones;
