import React from "react";

const CardFechasVencimiento = ({ factura, concepto, saldo }) => {
    let newConcepto = concepto.split(' ');
    return (

        <tr>
            <table className="table">
                <tr>
                    <td className="col-12">Concepto: <br></br>{newConcepto[0] + ' ' + newConcepto[1]}  <br></br>  {factura} <br></br> <b>${saldo}</b></td>
                </tr>
            </table>
        </tr>

    )
};

export default CardFechasVencimiento;
