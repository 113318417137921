import React, { useEffect, useState, useContext } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import $ from "jquery";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
// import socket from '../componentes/Socket';
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDE from "../hooks/useDireccionEstudiantil";

// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";

const PasantiasApply = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [titulo, setTitulo] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [empresaContacto, setEmpresaContacto] = useState("");
  const [area, setArea] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [carrera, setCarrera] = useState("");
  const [anio, setAnio] = useState("");
  // const [urlOldIMG, setUrlOldIMG] = useState("");
  const [modalidad, setModalidad] = useState("");
  const [tiempo, setTiempo] = useState("");
  const [remuneracion, setRemuneracion] = useState("");
  const [comentarios, setComentarios] = useState("");
  const [aplicaciones, setAplicaciones] = useState("");

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [msj, seMSJ] = useState("");

  const [nombreAlumnoCorreo, setNombreAlumnoCorreo] = useState("");
  const [correoAlumnoCorreo, setCorreoAlumnoCorreo] = useState("");
  const [msjAlumnoCorreo, setMSJAlumnoCorreo] = useState("");


  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
    dataUser.email = cred.correo;

    if (dataSuper.indexOf(cred.carnet.trim()) === -1) {
      if (dataDE.indexOf(cred.carnet.trim()) === -1) {
        history.push("/");
      }
    }
  } else {
    history.push("/");
  }

  let formatFecha = (fecha) => {
    return fecha.substr(0, 10);
    // let preFecha = new Date(fecha);
    // let fechaPre = preFecha.toLocaleDateString().substr(0, 10).split("/");
    // const es = navigator.userAgent.indexOf("Edg/") > -1;
    // if (es) {
    //   // Si el navegado es Edge
    //   return fechaPre[2].substr(0, 4) + "-" + fechaPre[1] + "-" + fechaPre[0];
    // } else {
    //   if (fechaPre[1] < 10) {
    //     fechaPre[1] = "0" + fechaPre[1];
    //   }
    //   if (fechaPre[0] < 10) {
    //     fechaPre[0] = "0" + fechaPre[0];
    //   }
    //   return (
    //     fechaPre[2].substr(0, 4) +
    //     "-" +
    //     fechaPre[1].substr(-0, 2) +
    //     "-" +
    //     fechaPre[0].substr(-0, 2)
    //   );
    // }
  };

  const [rutaCV, setRutaCV] = useState("wait");

  useEffect(() => {
    let dataUpdate = () => {
      let ryequ = new URLSearchParams(window.location.search);
      if (ryequ.get("id_pasantia")) {
        GetFetch.getPasantiaID(ryequ.get("id_pasantia"), dataUser.token).then(
          (pasantiaData) => {
            setTitulo(pasantiaData[0].titulo);
            setEmpresa(pasantiaData[0].empresa);
            setEmpresaContacto(pasantiaData[0].correo_empresa);
            setArea(pasantiaData[0].area);
            setDescripcion(pasantiaData[0].descripcion);
            setFechaInicio(formatFecha(pasantiaData[0].fecha_inicio));
            setFechaFin(formatFecha(pasantiaData[0].fecha_fin));
            prePhotoupdate(pasantiaData[0].imagen);
            // setUrlOldIMG(pasantiaData[0].imagen);
            setCarrera(pasantiaData[0].carrera);
            setAnio(pasantiaData[0].anio);
            setModalidad(pasantiaData[0].modalidad);
            setTiempo(pasantiaData[0].tiempo);
            setRemuneracion(pasantiaData[0].remuneracion);
            setComentarios(pasantiaData[0].comentarios);
            setAplicaciones(pasantiaData[0].aplicaciones);
          }
        );
      }
    };

    dataUpdate();
    loadMenu();
  }, [dataUser]);

  let prePhotoupdate = (url) => {
    let imgDiv = document.getElementById("loadImagen"); //Elemento donde se carga la img vista previa
    let TmpPath = url;
    imgDiv.innerHTML = `<img src="${TmpPath}" style='width: 45%' />`;
  };

  const pasantiaBody = (descripcion) => {
    return { __html: descripcion };
  };

  const modalCV = (e) => {
    $("#modalCV").modal("show");
    // setRutaCV(`http://localhost:4000/cvs/${e.target.dataset.carnet}.pdf`);
    setRutaCV(`https://api.esen.edu.sv/cvs/${e.target.dataset.carnet}.pdf`);
    setNombreAlumnoCorreo(e.target.dataset.nombre);
    setCorreoAlumnoCorreo(e.target.dataset.correo);
  };

  const setCheck = (e) => {
    let ryequ = new URLSearchParams(window.location.search);
    if (ryequ.get("id_pasantia")) {
      const data = new FormData();
      data.append("secret", "5ec4702eff406849a82818cb");
      data.append("_id", ryequ.get("id_pasantia"));
      data.append("carnet", e.target.dataset.carnet);
      if (+e.target.dataset.stado === 0) {
        e.target.setAttribute('data-stado', 1);
        data.append("stado", 1);
      } else {
        e.target.setAttribute('data-stado', 0);
        data.append("stado", 0);
      }

      GetFetch.updatePasantiaStatus(data, dataUser.token).then((datosSet) => {
        if (datosSet.success) {
          return datosSet.success;
        } else {
          return 0;
        }
      });
    }
  };

  const setSeleccionado = (e) => {
    // console.log(e.target.dataset);
    // console.log(e.target.dataset.seleccionado);
    let ryequ = new URLSearchParams(window.location.search);
    if (ryequ.get("id_pasantia")) {
      const data = new FormData();
      data.append("secret", "5ec4702eff406849a82818cb");
      data.append("_id", ryequ.get("id_pasantia"));
      data.append("carnet", e.target.dataset.carnet);
      if (+e.target.dataset.seleccionado === 0) {
        e.target.setAttribute('data-seleccionado', 1);
        data.append("seleccionado", 1);
      } else {
        e.target.setAttribute('data-seleccionado', 0);
        data.append("seleccionado", 0);
      }

      GetFetch.updatePasantiaStatusSelect(data, dataUser.token).then((datosSet) => {
        if (datosSet.success) {
          return datosSet.success;
        } else {
          return 0;
        }
      });
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  let msjSet = (e) => {
    seMSJ(e);
  };

  let msjalumnoCorreoSet = (e)=>{
    setMSJAlumnoCorreo(e.target.value);
  }

  let sendEmailContactoEmpresa = async (e) => {
    e.preventDefault();
    const query = new URLSearchParams(window.location.search);
    const data = new FormData();
    data.append("secret", "5ec4702eff406849a82818cb");
    data.append("msj", msj);
    data.append("_id", query.get("id_pasantia"));
    data.append("email", empresaContacto);
    if (query.get("id_pasantia")) {
      //Insertar registro
      document.getElementById("btnSendEmail").classList.remove('btn-dark');
      document.getElementById("btnSendEmail").classList.add('btn-warning');
      document.getElementById("btnSendEmail").innerText = 'Enviando y adjuntando CVs indicados por correo. Por favor espere ...'
      await GetFetch.sendPasantiaCorreoEmpresaContacto(
        data,
        dataUser.token
      ).then((datosSet) => {
        if (datosSet.success) {
          if (+datosSet.success === 1) {
            document.getElementById("btnSendEmail").style.display = "none";
          }
          return datosSet.success;
        } else {
          return 0;
        }
      });
    } else {
      return 0;
    }
  };

  let sendEmailAlumno = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("secret", "5ec4702eff406849a82818cb");
    data.append("msj", msjAlumnoCorreo);
    data.append("email", correoAlumnoCorreo);

    await GetFetch.sendPasantiaCorreoAlumnoComentarioCV(
      data,
      dataUser.token
    ).then((datosSet) => {
      if (datosSet.success) {
        if (+datosSet.success === 1) {
           document.getElementById('msjAlumnoComenatarioCV').innerHTML = `<div class="alert alert-warning alert-dismissible fade show" role="alert">
                                                                            <strong>Correo enviado!</strong>. Pasantias.
                                                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                                              <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                          </div>`;
          setMSJAlumnoCorreo("");
          setTimeout(() => {
            document.getElementById('msjAlumnoComenatarioCV').innerHTML = '';
          }, 10000);
        }
        return datosSet.success;
      } else {
        return 0;
      }
    });
  };

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <div className="footer">
            <p>
              Copyright &copy;
              {/* <script>
                                document.write(new Date().getFullYear());
                            </script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a> */}
            </p>
          </div>
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4">
          {" "}
          Pasantía: <br></br>
          <small className="text-primary">{titulo}</small>
        </h2>

        <div className="row">
          <div className="col-12 mb-3">
            <div className="card border-primary">
              <div className="card-header">Aplicaciones de alumnos</div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Carnet</th>
                      <th>Alumno</th>
                      <th>Fecha</th>
                      <th className="text-center">CV</th>
                      <th className="text-center">Seleccionados</th>
                      {/* <th>Email</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {aplicaciones.length > 0
                      ? aplicaciones.map((alemet, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{alemet.carnet} </td>
                              <td> {alemet.nombre}</td>
                              <td>
                                {" "}
                                {new Date(alemet.fecha).toLocaleString()}
                              </td>
                              <td className="text-center">
                                {" "}
                                <i
                                  className="fa fa-file-pdf-o text-danger mr-4"
                                  aria-hidden="true"
                                  onClick={modalCV}
                                  data-carnet={alemet.carnet}                                  
                                  data-stado={alemet.stado}
                                  data-nombre={alemet.nombre}
                                  data-correo={alemet.correo}
                                ></i>
                                 <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{width:14,height:17}}
                                  data-carnet={alemet.carnet}
                                  data-stado={alemet.stado}
                                  data-nombre={alemet.nombre}
                                  data-correo={alemet.correo}
                                  onChange={setCheck}
                                  defaultChecked={
                                    alemet.stado === 1 ? true : alemet.stado === 2 ? true : false
                                  } 
                                  disabled = {alemet.stado === 2 ? true : false}
                                />
                              </td>
                              <td className="text-center">
                              <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{width:14,height:17}}
                                  data-carnet={alemet.carnet}
                                  data-seleccionado={alemet.seleccionado}
                                  data-nombre={alemet.nombre}
                                  data-correo={alemet.correo}
                                  onChange={setSeleccionado}
                                  defaultChecked={
                                    +alemet.seleccionado === 1 ? true : false
                                  } 
                                />
                              </td>
                              {/* <td> <button type="button" class="btn btn-dark btn-sm"> Enviar</button> </td> */}
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
                <hr></hr>
                <div className="form-group">
                  <label htmlFor="text-eventonoticia">
                    {" "}
                    Mensaje para contacto de la empresa{" "}
                  </label>
                  {
                    comentarios && (<p>{comentarios}</p>)
                  }
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      msjSet(data);
                      // console.log( { event, editor, data } );
                    }}
                  />
                </div>
                <button
                  id="btnSendEmail"
                  type="button"
                  className="btn btn-dark text-center"
                  onClick={sendEmailContactoEmpresa}
                >
                  {" "}
                  Enviar correo a contacto de empresa: {empresa} (
                  {empresaContacto})
                </button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card border-secondary">
              <div className="card-header">{titulo}</div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="text-titulo">Empresa: {empresa}</label>
                </div>
                <div className="form-group">
                  <label htmlFor="text-titulo">
                    Contacto: {empresaContacto}
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="text-area">Área: {area} </label>
                </div>
                <div className="form-group">
                  <label htmlFor="text-area">Modalidad: {modalidad} </label>
                </div>
                <div className="form-group">
                  <label htmlFor="text-area">
                    Tiempo o duración de la pasantia: {tiempo}{" "}
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor="text-area">
                    Remuneración: {remuneracion}{" "}
                  </label>
                </div>
                <div className="form-group">
                  <p
                    className="card-text"
                    dangerouslySetInnerHTML={pasantiaBody(descripcion)}
                  ></p>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="text-area">
                      Carrera para quien va dirigida la pasantia
                    </label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="economia"
                        value="economia"
                        defaultChecked={
                          carrera ? (carrera.economia ? true : null) : null
                        }
                      />
                      Economía
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="ingenieria"
                        value="ingenieria"
                        defaultChecked={
                          carrera ? (carrera.ingenieria ? true : null) : null
                        }
                      />
                      Ingeniería
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="derecho"
                        value="derecho"
                        defaultChecked={
                          carrera ? (carrera.derecho ? true : null) : null
                        }
                      />
                      Jurídica
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="todas"
                        value="todas"
                        defaultChecked={
                          carrera ? (carrera.todas ? true : false) : false
                        }
                      />
                      Todas
                    </div>
                  </div>
                  <div className="col-6">
                    <label htmlFor="text-area">
                      Año de carrera para quien va dirigida la pasantia
                    </label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="tercero"
                        value="tercero"
                        defaultChecked={
                          anio ? (anio.tercero ? true : null) : null
                        }
                      />
                      3°
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="cuarto"
                        value="cuarto"
                        defaultChecked={
                          anio ? (anio.cuarto ? true : null) : null
                        }
                      />
                      4°
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="quinto"
                        value="quinto"
                        defaultChecked={
                          anio ? (anio.quinto ? true : null) : null
                        }
                      />
                      5°
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="todos"
                        value="todos"
                        defaultChecked={
                          anio ? (anio.todos ? true : null) : null
                        }
                      />
                      Todos
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="text-fechaInicio">
                    {" "}
                    Publicar el día: {fechaInicio}
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="text-fechaFin">
                    {" "}
                    Deshabilitar pasantia el día: {fechaFin}
                  </label>
                </div>

                <div className="form-group mt-5">
                  <label htmlFor="text-fechaFin"> {comentarios}</label>
                </div>

                <div id="loadImagen" className="mt-1 text-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="modalCV"
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <Document
              renderMode="svg"
              file={rutaCV}
              onLoadSuccess={onDocumentLoadSuccess}
              error="CV pendiente de subir"
              className="col-12 text-center"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
            <hr></hr>
            <div className="card">
              <div className="card-body" page={pageNumber}>
                <form onSubmit={sendEmailAlumno}>
                  <div className="form-group">
                    <label >
                      Comenarios al CV para el alumno: 
                    </label><br></br>
                    <label >
                    <i className="fa fa-user mr-3 text-warning" aria-hidden="true"></i>  <b> {nombreAlumnoCorreo} </b>
                    </label><br></br>
                    <label >
                    <i className="fa fa-envelope mr-2 text-warning" aria-hidden="true"></i>  <b>{correoAlumnoCorreo}</b>
                    </label>
                    <textarea
                      rows="4"
                      style={{width:"100%"}}
                      value={msjAlumnoCorreo}
                      onChange={msjalumnoCorreoSet}
                    ></textarea>
                    <div id="msjAlumnoComenatarioCV">

                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      className="btn btn-success btn-block"
                      value="Enviar correo"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasantiasApply;
