import React from "react";

const CarToefl = ({ datos }) => {
  const {valor,estructura  } = datos;
 
  return (
            <table className="table table-striped">
              <tbody>
                    <tr className="table-info">
                      <td className="text-center" style={{width:'20%'}}>{estructura} </td>
                      <td  className="text-right" style={{width:'80%'}}><b>{valor}</b></td>
                    </tr>
              </tbody>
              </table> 
  );
};

export default CarToefl;