import React from "react";
import "../css/styleEspaciosDeportivos.css";

const CarCanchasReservas = ({ reservasData, fecha, horas, espacio, btnAprobar, idPasantia, stado, libres, reservadas, btnCancelarReserva, hide}) => {
  // console.log(reservasData);
  let correlativo = 0;
  return reservasData.map((reservas, key) => {
    correlativo++;
    return (
      +reservas.stado === +stado
      ?
      <div className="mb-5" key={key}>
        <h3 className="text-success">{espacio} 
        {
          reservas.espacio
          ?
          <small> ({reservas.espacio})</small>
          :
          null
        }
        </h3>
        <p className="lead">
          <span className="text-info">{reservas.nombre}   ({reservas.responsable})</span> (Responsable)
        </p>
        <table key={"tableReserva" + correlativo} className="table table-striped table-dark text-center">
          <thead>
            <tr>
              <th scope="col">Fecha</th>
              <th scope="col">Espacio</th>
              <th scope="col">Horas</th>
              <th scope="col">OK</th>
              {
                +hide === 1 ? null :  <th scope="col">Cancelar</th>
              }
             
            </tr>
          </thead>
          <tbody>
            <tr key={correlativo}>
              <td>{fecha}</td>
              <td>{espacio}
              {
                reservas.espacio 
                ?
               <span className="text-success"><b> ({reservas.espacio})</b></span>
                :
                null
              }
              </td>
              <td>
                {horas.horas[reservas.hora_inicio]} /{" "}
                 {horas.horas[reservas.hora_fin]}{" "}
                {/* {reservas.hora_inicio} / {reservas.hora_fin}{" "} */}
              </td>
              <td>
                {+reservas.stado === 1 ? (
                  <i
                    key={"list" + correlativo}
                    className="fa fa-thumbs-up text-success reserva fa-2x"
                    aria-hidden="true" 
                    data-carnet={reservas.responsable} 
                    data-correo={reservas.correo} 
                    data-horas = {horas.horas[reservas.hora_inicio] + ' / ' + horas.horas[reservas.hora_fin]}
                    data-fecha = {fecha}
                    data-nombre = {reservas.nombre}
                    onClick={btnAprobar}
                    data-id = {idPasantia}
                    data-stado = '0' 
                    id={'apro'+ reservas.responsable + idPasantia}
                    data-iid={'apro'+ reservas.responsable + idPasantia}
                  ></i>
                ) 
                : 
                +reservas.stado === 2 ? (
                  <i
                    key={"list" + correlativo}
                    className="fa fa-hand-paper-o text-info reserva fa-2x"
                    aria-hidden="true" 
                    data-carnet={reservas.responsable} 
                    data-correo={reservas.correo} 
                    data-horas = {horas.horas[reservas.hora_inicio] + ' / ' + horas.horas[reservas.hora_fin]}
                    data-fecha = {fecha}
                    data-nombre = {reservas.nombre}
                    onClick={btnAprobar}
                    data-id = {idPasantia}
                    data-stado = '0' 
                    id={'apro'+ reservas.responsable + idPasantia}
                    data-iid={'apro'+ reservas.responsable + idPasantia}
                  ></i>
                ) 
                : 
                (
                  <i
                    key={"list" + correlativo}
                    className="fa fa-clock-o text-warning reserva fa-2x"
                    aria-hidden="true"
                    data-carnet={reservas.responsable} 
                    data-correo={reservas.correo} 
                    data-horas = {horas.horas[reservas.hora_inicio] + ' / ' + horas.horas[reservas.hora_fin]}
                    data-fecha = {fecha}
                    data-nombre = {reservas.nombre}
                    onClick={btnAprobar}
                    data-id = {idPasantia} 
                    data-stado = '1'
                    id={'apro'+ reservas.responsable + idPasantia}
                    data-iid={'apro'+ reservas.responsable + idPasantia }
                  ></i>
                )}
              </td>
              {
                +hide === 1 
                ?
                null:
                <td> 
                <i
                  key={"list" + correlativo}
                  className="fa fa-thumbs-down text-danger reserva fa-2x"
                  aria-hidden="true"
                  data-carnet={reservas.responsable} 
                  data-correo={reservas.correo}
                  data-horas = {horas.horas[reservas.hora_inicio] + ' / ' + horas.horas[reservas.hora_fin]}
                  data-fecha = {fecha} 
                  data-nombre = {reservas.nombre}
                  onClick={btnCancelarReserva}
                  data-id = {idPasantia} 
                  data-stado = '3'
                  data-inicio = {reservas.hora_inicio} 
                  data-fin = {reservas.hora_fin} 
                  id={reservas.responsable + idPasantia + reservas.hora_inicio + reservas.hora_fin}
                  data-iid = {reservas.responsable + idPasantia + reservas.hora_inicio + reservas.hora_fin}
                  data-libres = {libres}
                  data-reservados = {reservadas} 
                ></i>
            </td>
              }

            </tr>
          </tbody>
        </table>
        <hr></hr>
        {
          reservas.participantes
          ?
          <>
              <p>
          <b>Participantes / Integrantes</b>
        </p>
        {
          reservas.participantes.map((ele, key) => {
            return (
              <p key={key} className="card-text">
                <span className="mr-3">{key + 1}</span>
                <span className="mr-3">{ele.carnet}</span> {ele.nombre}
              </p>
            );
          })        
        }
          </>
          :
          null
        }
    
        <hr></hr>
      </div>
      :
      null
    );
  });
};

export default CarCanchasReservas;
