import React, { useEffect, useState } from 'react';

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from '../componentes/Menu';
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
import dataFinanzas from '../hooks/useFinzas';
import CarAlumnoDatos from '../element/CarAlumnoDatos';
import CarToefl from '../element/CarToefl';
// Contexto
// import dataContext from "../context/User";
// Helper 
import GetFetch from '../helper/GetFetch';
import loadMenu from '../js/main';
import Footer from '../componentes/Footer';

import '../css/styleAdmin.css';
// import { data } from 'jquery';

const AlumnosToefl = () => {

    const history = useHistory();

    const [carnet, setCarnet] = useState({});
    const [toefl, setToefl] = useState({});
    const [aprobado, setAprobado] = useState();
    const [fechaExamen, setFechaExamen] = useState();
    const [tipoToefl, setTipoToefl] = useState();
    const [alumno, setAlumno] = useState({});
    const [solvencia, setSolvencia] = useState();
    

    // const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');

    if (!datos) {
        history.push("/");
    }

    datos = JSON.parse(datos);

    if (dataSuper.indexOf(datos.carnet.trim()) === -1) {
        if (dataDireccionEstudiantil.indexOf(datos.carnet.trim()) === -1  && dataFinanzas.indexOf(datos.carnet.trim()) === -1) {
          history.push("/");
        }
      }  

    let carnetSet = (e) => {
        setCarnet(e.target.value)
    }

    let onSutmit = (e) => {
        e.preventDefault();
        localStorage.setItem('currentCarnet', carnet);
        let toeflAlumno = GetFetch.toefl(carnet, datos.token);
        toeflAlumno.then((dataToefl) => {
            if (!dataToefl.length <= 0) {
                document.getElementById('dataContenedorAlumno').style.display = 'block';
                setToefl(dataToefl);                
                AlumnoDatos();               
            }else{
                document.getElementById('dataContenedorAlumno').style.display = 'block';
                setToefl(0);
                AlumnoDatos();
                if(localStorage.getItem('solvencia')){
                    setSolvencia('INSOLVENTE')
                    setTimeout(() => {
                      localStorage.removeItem('solvencia')
                    }, 5000);
                    setAprobado(null)
                    setFechaExamen(null)
                    setTipoToefl(null);
                }else{
                    setSolvencia(null)
                    setAprobado(null)
                    setFechaExamen(null)
                    setTipoToefl(null);
                }
            }
        });
    }

    let AlumnoDatos = () => {
        let datosAlum = GetFetch.datosAlumno(carnet, datos.token);
        datosAlum.then((data) => {
            if (data[0]) {
                setAlumno(data[0]);                
            }
        });
    }

    useEffect(() => {
        loadMenu();
        let currentCarnet = localStorage.getItem('currentCarnet');
        if (currentCarnet) {
            setCarnet(currentCarnet);
        }else{
            setCarnet('');
        }
        document.getElementById('textCarnet').focus();
    }, []);

    return (
        <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
                <div className="custom-menu">
                    <button
                        type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary"
                    >
                        <i className="fa fa-bars"></i>
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4">
                    <Foto
                        foto={datos.carnet}
                        alt="Foto alumno"
                        tamanio="rounded" />

                    <HeaderMenu
                        carnet={datos.carnet}
                        nombre={datos.nombre}
                        perfil={datos.perfil}
                        carrera={datos.carrera}
                        posicion="text-center"
                    />

                    <Menu />

                    <Footer />
                </div>
            </nav>

            <div id="content" className="p-4 p-md-5 pt-5">
                <div className="container">
                    <h1 className="mt-4 text-center"> Toefl</h1>
                    <form id='formUP' onSubmit={onSutmit} >
                        <div class="form-group">
                            <input id="textCarnet" type="text" placeholder="Carnet" className="form-control" onChange={carnetSet} autofocus value={carnet}></input>
                        </div>
                        <div className='form-group'>
                            <input type="submit" className="btn btn-primary btn-block" value="Enviar" />
                        </div>
                    </form>
                    
                    <CarAlumnoDatos
                        alumno={alumno}
                    />

                    <p className="display-4">{aprobado}</p> 
                    <p className=""><b>{tipoToefl}</b><br></br>{fechaExamen}</p> 
                    {
                        toefl.length > 0
                        ?
                        toefl.map((element) => {      
                            if (!aprobado) {
                              setAprobado('APROBADO')
                              let fecha = new Date(element.toefl_fecha);
                              let formatFecha = fecha.toLocaleString().split(',');
                              setFechaExamen(formatFecha[0])
                              setTipoToefl(element.tipotoefl)
                            }     
                            return (
                              <>       
                              <CarToefl
                                datos={element} 
                                key = {element.estructura}
                                />
                              </>
                            )
                        })
                        :
                        solvencia ? <><p className="text-danger display-4">{solvencia}</p></> :
                        null
                    }
                </div>
            </div>
        </div>
    )

}

export default AlumnosToefl;