import React from "react";

const CarDetalle = ({ diasemana, hora, seccion }) => {
  return (
      <tr>
        <td>{diasemana}</td>
        <td>{hora}</td>
        <td>{seccion}</td>
      </tr>
  );
};

export default CarDetalle;
