import React, { useEffect, useState, useContext } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
// import socket from '../componentes/Socket';
import Menu from "../componentes/Menu";

// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";

const PasantiasCV = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [file, setFile] = useState();
  const [fileEXT, setFileEXT] = useState(0);
  // const [upload, setUpload] = useState(0);
  
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
    dataUser.email = cred.correo;
  } else {
    history.push("/");
  }

  const [rutaCV, setRutaCV] = useState('wait');

  useEffect(() => {
    loadMenu();
    setRutaCV(`https://api.esen.edu.sv/cvs/${dataUser.carnet}.pdf`);
  }, [dataUser]);


  let cargarCV = (e) => {
    setFile(e.target.files[0]);
    if (e.target.files[0].type !== "application/pdf") {
      setFileEXT(1);
    } else {
      setFileEXT(0);
    }
    setRutaCV('wait');
  };

  const sendCV = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("secret", "5ec4702eff406849a82818cb");
    data.append("carnet", dataUser.carnet);
    data.append("file", file);
    await GetFetch.setSendCV(data, dataUser.token).then(
      (datosSet) => {
        if (datosSet.success) {
          setTimeout(() => {            
            setRutaCV(`https://api.esen.edu.sv/cvs/${dataUser.carnet}.pdf`);       
          }, 3000);
          return datosSet.success;
        } else {
          return 0;
        }
      }
    );
  };

  // let rutaCVCV = `http://localhost:4000/cvs/${dataUser.carnet}.pdf`;

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <div className="footer">
            <p>
              Copyright &copy;
              {/* <script>
                                document.write(new Date().getFullYear());
                            </script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a> */}
            </p>
          </div>
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Curriculum vitae</h2>
        <h1 className=" text-warning text-center display-1">
          <i className="fa fa-address-card-o" aria-hidden="true"></i>
        </h1>
        <div className="form-group custom-file mt-3">
          <input
            type="file"
            className="custom-file-input"
            id="customFile"
            name="file"
            onChange={cargarCV}
          />
          <label className="custom-file-label" htmlFor="customFile" pageNumber={pageNumber}>
            {" "}
            Subir curriculum vitae en PDF
          </label>
        </div>
        {fileEXT === 1 ? (
          <div class="alert alert-danger mt-2" role="alert">
            <i class="fa fa-bell-o" aria-hidden="true"></i> El documento no es
            un PDF. Por favor validar que su CV sea PDF para subirlo.
          </div>
        ) : (
          <div className="form-group mt-5">
            <input
              type="button"
              className="btn btn-success btn-block"
              value="Subir CV"
              onClick={sendCV}
            />
          </div>
        )}

        {/* {upload === 1 ? (
          <div class="alert alert-info mt-2 text-center" role="alert">
            <i
              class="fa fa-file-pdf-o display-1 text-danger"
              aria-hidden="true"
            ></i>{" "}
            <br></br>¡Documento enviado con exito!
          </div>
        ) : null} */}

          <Document renderMode='canvas' file={rutaCV} onLoadSuccess={onDocumentLoadSuccess}  error="CV pendiente de subir"  className='col-12 text-center'>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        
      </div>
    </div>
  );
};

export default PasantiasCV;
