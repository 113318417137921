import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { Link, useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from "./Footer";

const Cum = () => {
  const history = useHistory();

  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [cum, setCum] = useState(0);
  const [solvencia, setSolvencia] = useState();

  useEffect(() => {
    if (datos) {
      let cred = JSON.parse(datos);
      dataUser.token = cred.token;
      dataUser.nombre = cred.nombre;
      dataUser.carnet = cred.carnet;
      dataUser.perfil = cred.perfil;
      dataUser.carrera = cred.carrera;
  
      let cumAlumno = GetFetch.cum(cred.carnet, cred.token);
      console.log(cumAlumno);
      cumAlumno.then((dataCum) => {
        setCum(dataCum[0].cum.toFixed(1))
        if(localStorage.getItem('solvencia')){
          setSolvencia('INSOLVENTE')
          setTimeout(() => {
            localStorage.removeItem('solvencia')
          }, 5000);
        }
      });
    } else {
      history.push("/");
    }
    loadMenu();
  }, [datos, dataUser, history]);

  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            perfil={dataUser.perfil}
            carrera={dataUser.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4 homeLogin"> CUM</h2>
          {
            solvencia ? <><p className="text-danger display-4">{solvencia}</p></> :
            <h1 className="display-1 text-center">{cum}</h1>     
          }
          <hr></hr>
          
        <Link className="btn btn-primary  btn-block" to="/home">
          <span className="fa fa-cubes ml-3 mr-1"></span> Horario de clases
        </Link>


        <Link className="btn btn-primary  btn-block" to="/horassociales">
          <span className="fa fa-hourglass-half ml-3 mr-1"></span> Horas sociales
        </Link>
        
        <Link className="btn btn-primary  btn-block" to="/notas">
            <span className="fa fa-sticky-note ml-3 mr-1"></span> Notas
        </Link>
  
      </div>
    </div>
  );
};

export default Cum;
