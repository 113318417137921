import React from "react";

const CardSalasColectivas = ({ sala, stado, salida }) => {

    stado === 'DISPONIBLE' ? salida = ' --- ' : salida = new Date(salida);

    let colorState = '';
    stado === 'DISPONIBLE' ? colorState = 'text-success' : colorState = 'text-danger';

    return (
        <tr>
            <td className="text-center"><b>{sala}</b> </td>
            <td className="text-center"><span className={colorState}>{stado}</span></td>
            <td className="text-center"><b>{stado==='DISPONIBLE' ? '---' : salida.toLocaleString()}</b></td>
        </tr>
        // <tr><td><b>{sala}</b> </td><td><small className="text-danger"><b>Fuera de uso temporalmente por medidas de bioseguridad</b></small></td><td> --- </td></tr>
    )


};

export default CardSalasColectivas;
