import React, { useEffect, useState, useContext } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory, Link } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
// import socket from '../componentes/Socket';
import Menu from "../componentes/Menu";

// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";

const PasantiasDetalle = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [titulo, setTitulo] = useState();
  const [empresa, setEmpresa] = useState();
  const [area, setArea] = useState();
  const [descripcion, setDescripcion] = useState({});
  // const [fechaInicio, setFechaInicio] = useState({});
  // const [fechaFin, setFechaFin] = useState({});
  // const [carrera, setCarrera] = useState({});
  // const [anio, setAnio] = useState({});
  // const [urlOldIMG, setUrlOldIMG] = useState({});

  const [modalidad, setModalidad] = useState("");
  const [tiempo, setTiempo] = useState("");
  const [remuneracion, setRemuneracion] = useState("");
  const [aplicaciones, setAplicaciones] = useState(0);
  const [seleccionado, setSeleccionado] = useState(0);

  // const [file, setFile] = useState({});

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
    dataUser.email = cred.correo;
  } else {
    history.push("/");
  }

  // let formatFecha = (fecha) => {
  //   let preFecha = new Date(fecha);
  //   let fechaPre = preFecha.toLocaleString().substr(0, 10).split("/");
  //   const es = navigator.userAgent.indexOf("Edg/") > -1;
  //   if (es) {
  //     // Si el navegado es Edge
  //     return fechaPre[2].substr(0, 4) + "-" + fechaPre[1] + "-" + fechaPre[0];
  //   } else {
  //     if (fechaPre[1] < 10) {
  //       fechaPre[1] = "0" + fechaPre[1];
  //     }
  //     if (fechaPre[0] < 10) {
  //       fechaPre[0] = "0" + fechaPre[0];
  //     }
  //     return (
  //       fechaPre[2].substr(0, 4) +
  //       "-" +
  //       fechaPre[1].substr(-0, 2) +
  //       "-" +
  //       fechaPre[0].substr(-0, 2)
  //     );
  //   }
  // };

  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    let dataUpdate = () => {
      let ryequ = new URLSearchParams(window.location.search);
      if (ryequ.get("id_pasantia")) {
        GetFetch.getPasantiaID(ryequ.get("id_pasantia"), dataUser.token).then(
          (pasantiaData) => {
            setTitulo(pasantiaData[0].titulo);
            setEmpresa(pasantiaData[0].empresa);
            setArea(pasantiaData[0].area);
            setDescripcion(pasantiaData[0].descripcion);
            // setFechaInicio(formatFecha(pasantiaData[0].fecha_inicio));
            // setFechaFin(formatFecha(pasantiaData[0].fecha_fin));
            prePhotoupdate(pasantiaData[0].imagen);
            // setUrlOldIMG(pasantiaData[0].imagen);
            // setCarrera(pasantiaData[0].carrera);
            // setAnio(pasantiaData[0].anio);
            setModalidad(pasantiaData[0].modalidad);
            setTiempo(pasantiaData[0].tiempo);
            setRemuneracion(pasantiaData[0].remuneracion);
            //Buscar si alumno ha aplicado a pasantia
            let dataPasantiaApply = pasantiaData[0].aplicaciones;
            // console.log(dataPasantiaApply);
            dataPasantiaApply.map(elemt => {
             elemt.carnet === dataUser.carnet && setAplicaciones(1);
             return null;
            });
            dataPasantiaApply.map(elemt => {
              if(elemt.carnet === dataUser.carnet){
                 +elemt.seleccionado === 1 ? setSeleccionado(1) : setSeleccionado(0);
              }
              return 0;
            });
            // setAplicaciones();
          }
        );
      }
    };

    dataUpdate();
    loadMenu();
  }, [dataUser]);

  // let prePhoto = (e) => {
  //   let imgDiv = document.getElementById("loadImagen"); //Elemento donde se carga la img vista previa
  //   let TmpPath = URL.createObjectURL(e.target.files[0]);
  //   imgDiv.innerHTML = `<img src="${TmpPath}" style='width: 45%' />`;
  // };

  let prePhotoupdate = (url) => {
    let imgDiv = document.getElementById("loadImagen"); //Elemento donde se carga la img vista previa
    let TmpPath = url;
    imgDiv.innerHTML = `<img src="${TmpPath}" style='width: 45%' />`;
  };

  // let onSutmit = async (e) => {
  //   e.preventDefault();
  // };

  const pasantiaBody = (descripcion) => {
    return { __html: descripcion };
  };

  const fechaHoraActual = () => {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    return hoy.toISOString();
  };

  let applyPasantia = async (e) => {
    e.preventDefault();
    let dataApply = {
      carnet: dataUser.carnet,
      nombre: dataUser.nombre,
      correo: dataUser.email,
      fecha: fechaHoraActual(),
      seleccionado: 0,
      stado: 0
    };
    const data = new FormData();
    data.append("secret", "5ec4702eff406849a82818cb");
    data.append("aplicaciones", JSON.stringify(dataApply));
    data.append("_id", query.get("id_pasantia"));
    //Registrando aplicación de pasantia
    let setRegis = await GetFetch.setApplyPasantia(data, dataUser.token).then(
      (datosSet) => {
        if (datosSet.success) {
          return datosSet.success;
        } else {
          return 0;
        }
      }
    );
    if (+setRegis === 1) {
      setAplicaciones(1);
    }
  };

  let removeApplyPasantia = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("secret", "5ec4702eff406849a82818cb");
    data.append("carnet", dataUser.carnet);
    data.append("_id", query.get("id_pasantia"));
    //Eliminando aplicación de pasantia
    let setRegis = await GetFetch.removeApplyPasantia(data, dataUser.token).then(
      (datosSet) => {
        if (datosSet.success) {
          return datosSet.success;
        } else {
          return 0;
        }
      }
    );
    if (+setRegis === 1) {
      setAplicaciones(0);
    }
  };

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <div className="footer">
            <p>
              Copyright &copy;
              {/* <script>
                                document.write(new Date().getFullYear());
                            </script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a> */}
            </p>
          </div>
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Pasantia detalle</h2>
        <div className="card border-primary mb-3">
          <h5 className="card-header text-center">{titulo}</h5>
          <div className="card-body">
            <p
              className="card-text"
              dangerouslySetInnerHTML={pasantiaBody(descripcion)}
            ></p>
            <p className="">
              <i className="fa fa-check-circle-o" aria-hidden="true"></i> Área:{" "}
              <ins className="ml-5">{area}</ins>
            </p>
            <p className="">
              <i className="fa fa-check-circle-o" aria-hidden="true"></i> Empresa:{" "}
              <ins className="ml-4">{empresa}</ins>
            </p>
            <p className="">
              <i className="fa fa-check-circle-o" aria-hidden="true"></i> Modalidad:{" "}
              <ins className="ml-3">{modalidad}</ins>
            </p>
            <p className="">
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>{" "}
              Remuneración: <ins className="ml-1">${remuneracion}</ins>
            </p>
            <p className="">
              <i className="fa fa-check-circle-o" aria-hidden="true"></i> Tiempo:{" "}
              <ins className="ml-5">{tiempo}</ins>
            </p>
            {/* <p>Empresa:<br></br> <b>{empresa}</b></p> */}
            <div id="loadImagen" className="mt-1 text-center"></div>
            {/* <p>Fecha de despublicación de pasantia: {fechaFin}</p> */}

            {+seleccionado === 1 ?               
               <p className="text-success text-center"><b><i class="fa fa-thumbs-o-up display-4 mr-2" aria-hidden="true"></i> <i className="fa fa-smile-o display-4 text-center" aria-hidden="true"></i></b><br></br> <b>PASANTIA OK!</b></p>
            : 
            aplicaciones === 0
              ?
                <div className="form-group mt-5">
                    <input
                      type="submit"
                      className="btn btn-success btn-block"
                      value="Aplicar a pasantia"
                      onClick={applyPasantia}
                    />
                </div>
              :
                <div className="form-group mt-5">
                    <input
                      type="submit"
                      className="btn btn-warning btn-block"
                      value="Cancelar aplicación"
                      onClick={removeApplyPasantia}
                    />
                </div>
            }

          </div>
        </div>
        <Link
          to={"pasantiasalumnos"}
          className="btn btn-primary btn-sm mb-3 text-right"
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i> Volver a pasantias
        </Link>
      </div>
    </div>
  );
};

export default PasantiasDetalle;
