import React from "react";

const CarHorasSociales = ({ datos }) => {
  // const horas = datos || [];
  const {empresa, actividad, tipo_hora_social, fecha_inicio, fecha_fin, horas} = datos;
  let desde = new Date(fecha_inicio);
  let hasta = new Date(fecha_fin);
  return (
    <div id="contenedorHorasSociales" className="card">
        <div className="card-body">
            <table className="table table-striped">
              <tbody>
                    <tr>
                      <td className="table-primary" style={{width:'20%'}}>Empresa: </td>
                      <td style={{width:'80%'}}>{empresa}</td>
                    </tr>
                    <tr>
                      <td className="table-primary" style={{width:'20%'}}>Actividad: </td>
                      <td style={{width:'80%'}}>{actividad}</td>
                    </tr>
                    <tr>
                      <td className="table-primary" style={{width:'20%'}}>Tipo hora: </td>
                      <td style={{width:'80%'}}>{tipo_hora_social}</td>
                    </tr>
                    <tr>
                      <td className="table-primary" style={{width:'20%'}}>Desde: </td>
                      <td style={{width:'80%'}}>{desde.toLocaleString().substr(0,10)}</td>
                    </tr>
                    <tr>
                      <td className="table-primary" style={{width:'20%'}}>Hasta: </td>
                      <td style={{width:'80%'}}>{hasta.toLocaleString().substr(0,10)}</td>
                    </tr>
                    <tr>
                      <td className="table-primary" style={{width:'20%'}}>Horas: </td>
                      <td className="table-success" style={{width:'80%'}}><b>{horas}</b></td>
                    </tr>
              </tbody>
              </table>            
        </div>
    </div>
  );
};

export default CarHorasSociales;