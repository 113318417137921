import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import CarNotas from "../element/CarNotas";
import Menu from './Menu';
// Contexto
import dataContext from "../context/User";
// Helper 
import GetFetch from '../helper/GetFetch';
import ShortData from '../helper/ShortData';
import loadMenu from '../js/main';
import Footer from './Footer';

const Notas = () => {

  const history = useHistory();

  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem('dataUser');

  const [notas, setNotas] = useState({});
  const [solvencia, setSolvencia] = useState();

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;

  } else {
    history.push("/");
  }

  useEffect(() => {

    if (datos) {
      let cred = JSON.parse(datos);
      dataUser.token = cred.token;
      dataUser.nombre = cred.nombre;
      dataUser.carnet = cred.carnet;

      let notasMaterias = GetFetch.notas(dataUser.carnet, dataUser.token);
      notasMaterias.then((dataNotas) => {
        setNotas(ShortData.notasMaterias(dataNotas));
        if(localStorage.getItem('solvencia')){
          setSolvencia('INSOLVENTE')
          setTimeout(() => {
            localStorage.removeItem('solvencia')
          }, 5000);
        }
      });

      loadMenu();
    } else {
      history.push("/");
    }
  }, [datos, history, dataUser]);


  return (

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto
            foto={dataUser.carnet}
            alt="Foto alumno"
            tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4">Materias y notas por ciclos</h2>
        {
          notas.length > 0
            ?
            notas.map((element) => {
              return (
                <CarNotas
                  ciclo={element.ciclo}
                  datos={element.data} />
              )
            })
            :
            solvencia ? <><p className="text-danger display-4">{solvencia}</p></> :
            notas.length
        }
      </div>
    </div>
  );
};

export default Notas;
