import React, { useState, useEffect, useContext } from 'react';
import dataContext from '../context/User';
import Logo from './Logo';
import FormLogin from './FormLogin';
import { useHistory } from 'react-router-dom';

import Home from '../home/Home';
import Head from '../home/Head';
import Seccion from '../home/Seccion';
import Footer from '../home/Footer';
import dataSuper from '../hooks/Conf';
import dataDE from '../hooks/useDireccionEstudiantil';
import apiURL from '../hooks/useAPI';

const Login = () => {

    const history = useHistory();
    const { fetchAPI } = apiURL;
    const dataUser = useContext(dataContext);

    const [data, setData] = useState({ loginSource: '0', carnet: '' });
    const [token, setToken] = useState([0]);

    useEffect(() => {
        let datos = localStorage.getItem('dataUser');
        if (datos) {
            let cred = JSON.parse(datos);
            dataUser.token = cred.token;
            dataUser.nombre = cred.nombre;
            dataUser.carnet = cred.carnet;
            dataUser.perfil = cred.perfil;
            if (+cred.success === 1 && (cred.perfil === 'Alumnos' || cred.perfil === 'Estudiante')) {
                history.push("/erecursos");
            } else if (+cred.success === 1 && (dataSuper.indexOf(cred.carnet.trim()) !== -1)) {
                // history.push("/admineventoall");
                history.push("/erecursos");
            } else if (+cred.success === 1 && (dataDE.indexOf(cred.carnet.trim()) !== -1)) {
                // history.push("/admineventoall");
                history.push("/erecursos");
            } 
            else if (+cred.success === 1 && (cred.perfil === 'Profesores' || cred.perfil === 'Decanos' || cred.perfil === 'Administrativo' || cred.perfil === 'Docente')) {
                history.push("/erecursos");
            } else {
                history.push("/");
            }
        }
    }, [dataUser, history]);

    const hendleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let datosLogin = await loginFetch()

        setToken(datosLogin.token);
        //Llenando contexto
        dataUser.token = datosLogin.token;
        dataUser.nombre = datosLogin.nombre;
        dataUser.carnet = datosLogin.carnet;

        localStorage.setItem('dataUser', JSON.stringify(datosLogin));

        if (+datosLogin.success === 1 && (datosLogin.perfil === 'Alumnos' || datosLogin.perfil === 'Estudiante')) {
            history.push("/erecursos");
        } else if (+datosLogin.success === 1 && (dataSuper.indexOf(datosLogin.carnet.trim()) !== -1)) {
            // history.push("/admineventoall");
            history.push("/erecursos");
        } else if (+datosLogin.success === 1 && (dataDE.indexOf(datosLogin.carnet.trim()) !== -1)) {
            // history.push("/admineventoall");
            history.push("/erecursos");
        }
        else if (+datosLogin.success === 1 && (datosLogin.perfil === 'Profesores' || datosLogin.perfil === 'Decanos' || datosLogin.perfil === 'Administrativo' || datosLogin.perfil === 'Docente')) {
            history.push("/erecursos");
        } else {
            history.push("/");
        }

    }

    let loginFetch = async () => {
        try {
            data.secret = '5ec4702eff406849a82818cb';
            data.carnet === '80100004' ? data.carnet = '8010004' : data.carnet = data.carnet;
            if (data.carnet.length < 8) {
                data.loginSource = 1; //CCESEN
            } else {
                data.loginSource = 0; //Registro
            }
            if (+data.loginSource === 0) { //Registro academico
                const entryPointLogin = `${fetchAPI}registro/login`;
                const optionFetch = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                let res = await fetch(entryPointLogin, optionFetch);
                let datos = res.json();
                return datos;
            }

            if (+data.loginSource === 1) { //CCESEN
                data.carnet === '8010004' ? data.carnet = '80100004' : data.carnet = data.carnet;
                const entryPointLogin = `${fetchAPI}biblio/login`;
                const optionFetch = {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                let res = await fetch(entryPointLogin, optionFetch);
                let datos = res.json();
                return datos;
            }

        } catch (error) {
            console.log(error);
        }
    }


    return (

        <div id="homeHome">
            {/* <Head />
            <Seccion />
            <Home /> */}

            {
                token ?
                    <div className="container">
                        <div id="loginSeccion" className="row">
                            {/* <div className="col-12 col-sm-4 text-center" style={{ backgroundColor: '#144780' }}> */}
                            <div className="col-12 col-sm-4 text-center">
                                <Logo
                                    alt='Logo ESEN'
                                    width='80%' />
                            </div>
                            <div className="col-12 col-sm-8 mt-5">
                                {/* <h1 className="text-center" style={{ fontFamily: 'letradecarta' }}>Login</h1> */}
                                <div className="clearfix"></div>
                                <FormLogin
                                    onChange={hendleChange}
                                    onSubmit={handleSubmit}
                                    data={dataUser.data} />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="container">
                        <div id="loginSeccion" className="row">
                            {/* <div className="col-12 col-sm-4 text-center" style={{ backgroundColor: '#144780' }}> */}
                            <div className="col-12 col-sm-4 text-center">
                                <Logo
                                    alt='Logo ESEN'
                                    width='80%' />
                            </div>
                            <div className="col-12 col-sm-8">
                                {/* <h1 className="text-center" style={{ fontFamily: 'letradecarta' }}>Login</h1> */}
                                <div className="clearfix"></div>
                                <FormLogin
                                    onChange={hendleChange}
                                    onSubmit={handleSubmit}
                                    data={dataUser.data} />
                            </div>
                        </div>

                        <div class="alert alert-warning alert-dismissible fade show mt-5" role="alert">
                            <strong><h1>Acceso denegado</h1></strong> Credenciales de acceso son las mismas que utilizas en Registro Académico o en el CCESEN
                        </div>
                    </div>
            }
            <br></br>
            <hr class="featurette-divider"></hr>
            <Footer />
        </div>
    );

}

export default Login