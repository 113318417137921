import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory}  from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import CarHorarios from "../element/CarHorarios";
import Menu from './Menu';
// Contexto
import dataContext from "../context/User";
// Helper 
import GetFetch from '../helper/GetFetch';
import ShortData from '../helper/ShortData';
import loadMenu from '../js/main';
import Footer from './Footer';

const Home = () => {

  const history = useHistory();

  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem('dataUser');
 
  const [horarios, setHorarios] = useState({});

  useEffect(() => {

    if (datos) {
      let cred = JSON.parse(datos);
       dataUser.token = cred.token;
       dataUser.nombre = cred.nombre;
       dataUser.carnet = cred.carnet;
       dataUser.perfil = cred.perfil;
       dataUser.carrera = cred.carrera;

      let horariosMaterias = GetFetch.horarios(cred.carnet, cred.token);
      console.log(horariosMaterias);
  
      horariosMaterias.then((dataMateHora) => {
        setHorarios(ShortData.MateriaHoraios(dataMateHora));
        console.log(ShortData.MateriaHoraios(dataMateHora));
      });

      loadMenu();
    }else{
      history.push("/"); 
    }
  },[datos, history, dataUser]);

  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto
            foto={dataUser.carnet}
            alt="Foto alumno"
            tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            perfil={dataUser.perfil}
            carrera={dataUser.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4 homeLogin"> Horario por materias</h2>

        {
          horarios.length > 0
            ?
            horarios.map((element) => {
              return (
                <CarHorarios
                  materia={element.materia}
                  datos={element.data} />
              )
            })
            :
            horarios.length
        }
      </div>
    </div>
  );
};

export default Home;
