import React, { useEffect, useState, useContext } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory, Link } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
// import socket from '../componentes/Socket';
import Menu from "../componentes/Menu";

// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";

const PasantiasAlumnosApply = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [pasantias, setPasantias] = useState();

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
    dataUser.email = cred.correo;
  } else {
    history.push("/");
  }

  useEffect(() => {

    let dataPasantias = () => {
      const data = new FormData();
      data.append("secret", "5ec4702eff406849a82818cb");
      data.append("carnet", dataUser.carnet);
      GetFetch.getApplyAlumno(data, dataUser.token).then((pasantiaData) => {
        setPasantias(pasantiaData.result);
        alertaVisualNotiPasantias(pasantiaData.result);   
      });    
    };

    dataPasantias();

    let alertaVisualNotiPasantias = (dataPasantias)=>{
      let alertaNoti = localStorage.getItem('alertaPasantiasLocal');
      if (alertaNoti) {
          //Recorriendo el resultado de notificaciones de la nube
          let tempNoti = JSON.parse(alertaNoti);
         if(dataPasantias){
          dataPasantias.map((elemento)=>{
              //Recorrer para ver si existe ID de notificaciones guardadas
              tempNoti.map((ele) => {
                  if (tempNoti.indexOf(elemento._id.trim()) === -1) {
                      tempNoti.push(elemento._id);
                  }
                  return null;
              })
              return null;
          })
          localStorage.setItem('alertaPasantiasLocal', JSON.stringify(tempNoti));
        }
      }else{
          let tempNoti = [];
          dataPasantias.map((elemento, index) => {
              tempNoti.push(elemento._id);
              return null;
          })
          localStorage.setItem('alertaPasantiasLocal', JSON.stringify(tempNoti));
      }
      return null;
    }

    loadMenu();
  }, [dataUser]);

  // const pasantiaBody = (descripcion) => {
  //   return { __html: descripcion };
  // };

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <div className="footer">
            <p>
              Copyright &copy;
              {/* <script>
                                document.write(new Date().getFullYear());
                            </script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a> */}
            </p>
          </div>
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Aplicaciones <small>pasantías</small> </h2>
        {pasantias
          ? pasantias.map((data,key) => {
              let dataPasantiaApply = data.aplicaciones;
              let aplicacionPasantia = 0;
              let seleccionadoPasantia = 0;
              dataPasantiaApply.map((elemt) => {
                return elemt.carnet === dataUser.carnet ? (aplicacionPasantia =  1): null;
              });
              dataPasantiaApply.map((elemt) => {
                if(elemt.carnet === dataUser.carnet){
                 return +elemt.seleccionado === 1 ? seleccionadoPasantia =  1 : 0;
                }
                return 0;
              });
              return (
                <div key={key} className="card border-primary mb-3">
                  <h5 className="card-header">{ aplicacionPasantia === 1 ? <i className="fa fa-check-circle-o mr-1 text-warning" aria-hidden="true"></i>: null}{data.titulo}</h5>
                  {
                    +seleccionadoPasantia === 1                     
                    ?
                    <h5 className="card-header text-success"><b><i class="fa fa-smile-o" aria-hidden="true"></i></b>  Felicidades!! Harás la pasantia</h5>
                    :
                    null
                  }
                  <div className="card-body">
                    {/* <p
                      className="card-text"
                      dangerouslySetInnerHTML={pasantiaBody(data.descripcion)}
                    ></p> */}
                    <h5 className="card-title">{data.empresa}</h5>
                    <Link
                      to={"pasantiadetalle?id_pasantia=" + data._id}
                      className="btn btn-primary btn-sm"
                    >
                      Más información
                    </Link>
                    {+aplicacionPasantia === 1 ? <hr></hr> : null}                    
                    {seleccionadoPasantia === 1 ? <p className="text-success text-center"><b><i class="fa fa-thumbs-o-up mr-2" aria-hidden="true"></i> <i className="fa fa-smile-o text-center" aria-hidden="true"></i></b><br></br> <b>PASANTIA OK!</b></p> : aplicacionPasantia === 1 ? <p className="text-warning text-center"><i className="fa fa-check-circle-o display-4  text-center" aria-hidden="true"></i><br></br> Aplicación a pasantia realizada</p> : null}
                  </div>
                </div>
              );
            })
          : <p>Buscando pasantías ... o sin aplicar a la fecha a ninguna pasantía </p>}
      </div>
    </div>
  );
};

export default PasantiasAlumnosApply;
