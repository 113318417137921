import React, { useEffect, useState, useContext } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
// import socket from '../componentes/Socket';
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDE from "../hooks/useDireccionEstudiantil";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";

const Pasantias = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [titulo, setTitulo] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [empresaContacto, setEmpresaContacto] = useState("");
  const [area, setArea] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [carrera, setCarrera] = useState("");
  const [anio, setAnio] = useState("");
  const [urlOldIMG, setUrlOldIMG] = useState("");
  const [modalidad, setModalidad] = useState("");
  const [tiempo, setTiempo] = useState("");
  const [remuneracion, setRemuneracion] = useState("");
  const [comentarios, setComentarios] = useState("");

  const [file, setFile] = useState({});

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
    dataUser.email = cred.correo;

    if (dataSuper.indexOf(cred.carnet.trim()) === -1) {
      if (dataDE.indexOf(cred.carnet.trim()) === -1) {
        history.push("/");
      }
    }
  } else {
    history.push("/");
  }

  let formatFecha = (fecha) => {
    let preFecha = new Date(fecha);
    let fechaPre = preFecha.toLocaleString().substr(0, 10).split("/");
    const es = navigator.userAgent.indexOf("Edg/") > -1;
    if (es) {
      // Si el navegado es Edge
      return fechaPre[2].substr(0, 4) + "-" + fechaPre[1] + "-" + fechaPre[0];
    } else {
      if (fechaPre[1] < 10) {
        fechaPre[1] = "0" + fechaPre[1];
      }
      if (fechaPre[0] < 10) {
        fechaPre[0] = "0" + fechaPre[0];
      }
      return (
        fechaPre[2].substr(0, 4) +
        "-" +
        fechaPre[1].substr(-0, 2) +
        "-" +
        fechaPre[0].substr(-0, 2)
      );
    }
  };

  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    let dataUpdate = () => {
      let ryequ = new URLSearchParams(window.location.search);
      if (ryequ.get("id_pasantia")) {
        console.log("Variable id pasantia 11");
        GetFetch.getPasantiaID(ryequ.get("id_pasantia"), dataUser.token).then(
          (pasantiaData) => {
            setTitulo(pasantiaData[0].titulo);
            setEmpresa(pasantiaData[0].empresa);
            setEmpresaContacto(pasantiaData[0].correo_empresa);
            setArea(pasantiaData[0].area);
            setDescripcion(pasantiaData[0].descripcion);
            setFechaInicio(formatFecha(pasantiaData[0].fecha_inicio));
            setFechaFin(formatFecha(pasantiaData[0].fecha_fin));
            prePhotoupdate(pasantiaData[0].imagen);
            setUrlOldIMG(pasantiaData[0].imagen);
            setCarrera(pasantiaData[0].carrera);
            setAnio(pasantiaData[0].anio);
            setModalidad(pasantiaData[0].modalidad);
            setTiempo(pasantiaData[0].tiempo);
            setRemuneracion(pasantiaData[0].remuneracion);
            setComentarios(pasantiaData[0].comentatios);
          }
        );
      }
    };

    dataUpdate();
    loadMenu();
  }, [dataUser]);

  let tituloSet = (e) => {
    setTitulo(e.target.value);
  };
  let empresaSet = (e) => {
    setEmpresa(e.target.value);
  };
  let empresaContactoSet = (e) => {
    setEmpresaContacto(e.target.value);
  };
  let comentariosSet = (e) => {
    setComentarios(e.target.value);
  };
  let areaSet = (e) => {
    setArea(e.target.value);
  };
  let descripcionSet = (e) => {
    setDescripcion(e);
  };
  let modalidadSet = (e) => {
    setModalidad(e.target.value);
  };
  let tiempoSet = (e) => {
    setTiempo(e.target.value);
  };
  let remuneracionSet = (e) => {
    setRemuneracion(e.target.value);
  };
  let fechaInicioSet = (e) => {
    setFechaInicio(e.target.value);
  };

  let fechaFinSet = (e) => {
    setFechaFin(e.target.value);
  };

  let carreraSet = (e) => {
    if (e.target.checked) {
      setCarrera({
        ...carrera,
        [e.target.name]: e.target.value,
      });
    } else {
      let a = { ...carrera };
      delete a[e.target.value];
      setCarrera(a);
    }
  };

  let anioSet = (e) => {
    if (e.target.checked) {
      setAnio({
        ...anio,
        [e.target.name]: e.target.value,
      });
    } else {
      let a = { ...anio };
      delete a[e.target.value];
      setAnio(a);
    }
  };

  let cargarDatosPhoto = (e) => {
    prePhoto(e);
    setFile(e.target.files[0]);
  };

  let prePhoto = (e) => {
    let imgDiv = document.getElementById("loadImagen"); //Elemento donde se carga la img vista previa
    let TmpPath = URL.createObjectURL(e.target.files[0]);
    imgDiv.innerHTML = `<img src="${TmpPath}" style='width: 45%' />`;
  };

  let prePhotoupdate = (url) => {
    let imgDiv = document.getElementById("loadImagen"); //Elemento donde se carga la img vista previa
    let TmpPath = url;
    imgDiv.innerHTML = `<img src="${TmpPath}" style='width: 45%' />`;
  };

  let eliminarPasantia = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("secret", "5ec4702eff406849a82818cb");
    data.append("user_publico", dataUser.email);
    data.append("id", query.get("id_pasantia"));
    let setRegis = await GetFetch.eliminarPasantia(data, dataUser.token).then(
      (datosSet) => {
        if (datosSet.success) {
          return datosSet.success;
        } else {
          return 0;
        }
      }
    );
    if (+setRegis === 1) {
      history.push("/adminpasantiasall");
    }
  };

  let onSutmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("secret", "5ec4702eff406849a82818cb");
    data.append("user_publico", dataUser.email);
    data.append("file", file);
    data.append("titulo", titulo);
    data.append("empresa", empresa);
    data.append("correo_empresa", empresaContacto);
    data.append("area", area);
    data.append("descripcion", descripcion);
    data.append("modalidad", modalidad);
    data.append("tiempo", tiempo);
    data.append("remuneracion", remuneracion);
    data.append("fecha_inicio", fechaInicio);
    data.append("fecha_fin", fechaFin + "T23:59:50.000+00:00");
    data.append("carrera", JSON.stringify(carrera));
    data.append("anio", JSON.stringify(anio));
    data.append("comentarios", comentarios);
    data.append('idColaborador', dataUser.carnet)
    if (!query.get("id_pasantia")) {
      //Insertar registro
      let setRegis = await GetFetch.setPasantia(data, dataUser.token).then(
        (datosSet) => {
          if (datosSet.success) {
            return datosSet.success;
          } else {
            return 0;
          }
        }
      );
      if (+setRegis === 1) {
        history.push("/adminpasantiasall");
      }
    } else {
      //Actualizar registo
      data.append("_id", query.get("id_pasantia"));
      data.append("imagen", urlOldIMG);
      let setRegis = await GetFetch.updatePasantia(data, dataUser.token).then(
        (datosSet) => {
          if (datosSet.success) {
            return datosSet.success;
          } else {
            return 0;
          }
        }
      );
      if (+setRegis === 1) {
        history.push("/adminpasantiasall");
      }
    }
  };

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <div className="footer">
            <p>
              Copyright &copy;
              {/* <script>
                                document.write(new Date().getFullYear());
                            </script> All rights reserved | This template is made with <i className="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib.com</a> */}
            </p>
          </div>
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        {query.get("id_pasantia") ? (
          <h2 className="mb-4"> Editar Pasantía</h2>
        ) : (
          <h2 className="mb-4"> Crear Pasantía</h2>
        )}

        <form className="" onSubmit={onSutmit}>
          <div className="form-group">
            <label htmlFor="text-titulo">Título</label>
            <input
              id="text-titulo"
              type="text"
              className="form-control"
              placeholder="Título"
              name="titulo"
              value={titulo}
              onChange={tituloSet}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="text-titulo">Empresa</label>
            <input
              type="text"
              className="form-control"
              id="text-empresa"
              placeholder="Empresa"
              name="empresa"
              value={empresa}
              onChange={empresaSet}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="text-titulo">Contacto de la empresa</label>
            <input
              type="email"
              className="form-control"
              id="text-empresa-contacto"
              placeholder="Correo de contacto de la empresa, donde se enviarán los CVs"
              name="correo_empresa"
              value={empresaContacto}
              onChange={empresaContactoSet}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="text-area">Área </label>
            <input
              type="text"
              className="form-control"
              id="text-area"
              placeholder="Mercadeo, Gerencia, Contabilidad, etc"
              name="area"
              value={area}
              onChange={areaSet}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="text-area">Modalidad </label>
            <input
              type="text"
              className="form-control"
              id="text-modalidad"
              placeholder="Virtual, Presencial o Hibridad"
              name="modalidad"
              value={modalidad}
              onChange={modalidadSet}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="text-area">Tiempo o duración de la pasantia </label>
            <input
              type="text"
              className="form-control"
              id="text-tiempo"
              placeholder="Tiempo que durará la pasantia"
              name="tiempo"
              value={tiempo}
              onChange={tiempoSet}
            />
          </div>
          <div className="form-group">
            <label htmlFor="text-area">Remuneración </label>
            <input
              type="text"
              className="form-control"
              id="text-remuneración"
              placeholder="Remuneración"
              name="tiempo"
              value={remuneracion}
              onChange={remuneracionSet}
            />
          </div>
          <div className="form-group">
            <label htmlFor="text-eventonoticia"> Descripción de puesto </label>
            <CKEditor
              editor={ClassicEditor}
              data={descripcion}
              onChange={(event, editor) => {
                const data = editor.getData();
                descripcionSet(data);
                // console.log( { event, editor, data } );
              }}
            />
          </div>
          <div className="row">
            <div className="col-6">
              <label htmlFor="text-area">
                Carrera para quien va dirigida la pasantia
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="economia"
                  value="economia"
                  onChange={carreraSet}
                  checked={carrera ? (carrera.economia ? true : null) : null}
                />
                Economía
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="ingenieria"
                  value="ingenieria"
                  onChange={carreraSet}
                  checked={carrera ? (carrera.ingenieria ? true : null) : null}
                />
                Ingeniería
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="derecho"
                  value="derecho"
                  onChange={carreraSet}
                  checked={carrera ? (carrera.derecho ? true : null) : null}
                />
                Jurídica
              </div>
              {/* <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="todas"
                  value="todas"
                  onChange={carreraSet}
                  checked={carrera ? (carrera.todas ? true : false) : false}
                />
                Todas
              </div> */}
            </div>
            <div className="col-6">
              <label htmlFor="text-area">
                Año de carrera para quien va dirigida la pasantia
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="tercero"
                  value="tercero"
                  onChange={anioSet}
                  checked={anio ? (anio.tercero ? true : null) : null}
                />
                3°
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="cuarto"
                  value="cuarto"
                  onChange={anioSet}
                  checked={anio ? (anio.cuarto ? true : null) : null}
                />
                4°
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="quinto"
                  value="quinto"
                  onChange={anioSet}
                  checked={anio ? (anio.quinto ? true : null) : null}
                />
                5°
              </div>
              {/* <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="todos"
                  value="todos"
                  onChange={anioSet}
                  checked={anio ? (anio.todos ? true : null) : null}
                />
                Todos
              </div> */}
            </div>
          </div>
          <div className="form-group mt-3">
            <label htmlFor="text-fechaInicio"> Publicar el día</label>
            <input
              type="date"
              className="form-control"
              id="text-fechaInicio"
              name="fecha_inicio"
              onChange={fechaInicioSet}
              value={fechaInicio}
            />
          </div>

          <div className="form-group">
            <label htmlFor="text-fechaFin"> Deshabilitar pasantia el día</label>
            <input
              type="date"
              className="form-control"
              id="text-fechaFin"
              name="fecha_fin"
              onChange={fechaFinSet}
              value={fechaFin}
            />
          </div>

          <div className="form-group custom-file mt-3">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              name="file"
              onChange={cargarDatosPhoto}
            />
            <label className="custom-file-label" htmlFor="customFile">
              {" "}
              Subir imagen de referencia
            </label>
          </div>

          <div className="form-group mt-5">
            <label htmlFor="text-fechaFin"> Comentarios</label>
            <input
              type="text"
              className="form-control"
              id="text-comentarios"
              placeholder="Comentarios finales, para referencia de administradores"
              name="comentarios"
              value={comentarios}
              onChange={comentariosSet}
            />
          </div>

          <div id="loadImagen" className="mt-1 text-center"></div>

          <div className="form-group mt-5">
            <input
              type="submit"
              className="btn btn-success btn-block"
              value="Guardar"
            />
            {titulo ? (
              <input
                type="button"
                className="btn btn-danger btn-block"
                value="Eliminar"
                onClick={eliminarPasantia}
              />
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Pasantias;
