import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
import CarDirectorioDetalle from "../element/CarDirectorioDetalle";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetchContentfulCMS from "../helper/GetFetchContentfulCMS";
import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";
import Footer from "./Footer";

const Directorio = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  const [colaboradores, setColaboradores] = useState({});

  const buscarColaboNombre = async (e) =>{
    if (e.target.value.length >= 4) {
      let contenido = GetFetchContentfulCMS.buscarColaboradorNombre(e.target.value);
      await contenido.then((datosC) => {
        setColaboradores(datosC.items);
        return datosC;
      });            
    }else{
      let contenido = GetFetchContentfulCMS.allContenidoColaboradores();
      await contenido.then((datosC) => {
        setColaboradores(datosC.items);
        return datosC;
      });
    }
  }

  useEffect(() => {
    if (datos) {
      let cred = JSON.parse(datos);
      dataUser.token = cred.token;
      dataUser.nombre = cred.nombre;
      dataUser.carnet = cred.carnet;

      let getContenido = async () => {
        let contenido = GetFetchContentfulCMS.allContenidoColaboradores();
        await contenido.then((datosC) => {
          setColaboradores(datosC.items);
          return datosC;
        });
      };

      SetBitacora.set(cred.carnet, 'Directorio_ESEN', cred.token);
      getContenido();

      loadMenu();
    } else {
      history.push("/");
    }
  }, [datos, history, dataUser]);
  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Directorio ESEN</h2>
        <form>
          <div class="form-row">
            <div className="col-2 col-sm-6 text-right">
              <h4><b><i className="fa fa-search text-primary" aria-hidden="true"></i></b></h4>
            </div>
            <div className="col-10 col-sm-6">
              <input
                type="text"
                class="form-control"
                id="txtBuscarColabotador"
                placeholder="Buscar ... "
                onChange = {buscarColaboNombre}
              />
            </div>
          </div>
        </form>
        <hr></hr>
        <div id="content" className="row">
          {colaboradores.length > 0 ? (
            <CarDirectorioDetalle data={colaboradores} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Directorio;
