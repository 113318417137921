import React from "react";

const CarPracticasProfesionales = ({ datos }) => {
  const {empresa, inicio, fin, reporte_empresa, reporte_alumno} = datos;
  let empresaOk = 'No';
  reporte_empresa === 1 ? empresaOk = 'Si' : empresaOk = 'No';
  let alumnoOk = 'No';
  reporte_alumno === 1 ? alumnoOk = 'Si' : alumnoOk = 'No';
  return (
    <div id="contenedorHorasSociales" className="card">
        <div className="card-body">
            <table className="table table-striped">
              <tbody>
                    <tr>
                      <td className="table-info" style={{width:'20%'}}>Empresa: </td>
                      <td style={{width:'80%'}}>{empresa}</td>
                    </tr>
                    <tr>
                      <td className="table-info" style={{width:'20%'}}>Desde: </td>
                      <td style={{width:'80%'}}>{inicio}</td>
                    </tr>
                    <tr>
                      <td className="table-info" style={{width:'20%'}}>Hasta: </td>
                      <td style={{width:'80%'}}>{fin}</td>
                    </tr>
                    <tr>
                      <td className="table-info" style={{width:'20%'}}>Reporte alumno: </td>
                      <td style={{width:'80%'}}>{empresaOk}</td>
                    </tr>
                    <tr>
                      <td className="table-info" style={{width:'20%'}}>Reporte empresa: </td>
                      <td style={{width:'80%'}}>{alumnoOk}</td>
                    </tr>
              </tbody>
              </table>            
        </div>
    </div>
  );
};

export default CarPracticasProfesionales;