import React from "react";

const CarNotasDetalle = ({ materia, nota, matricula }) => {
  return (
      <tr>
        <td>{materia}</td>
        <td>{nota.toFixed(1)}</td>
        <td>{matricula}</td>
      </tr>
  );
};

export default CarNotasDetalle;
