import React from 'react';

const Footer = () => {

    let upGo = () => {
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
    }

    return (
        <footer class="container">
            <p class="float-right letradecarta"> <button className="btn btn-default" onClick={upGo}> <i class="fa fa-arrow-up" aria-hidden="true"></i></button></p>
            <p>© 2021- ESEN ·<a href="/"> Tel. 2234-9243</a></p>
        </footer>
    )
}

export default Footer;