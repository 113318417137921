import React from "react";
import CarNotasDetalle from './CarNotasDetalle';

const CarNotas = ({ ciclo, datos }) => {
  const notas = datos || [];
  return (
    <div class="card">
        <div class="card-body">
            <h4 className="text-success text-center">{ciclo} </h4>
            <table class="table">
              <thead>
                <tr>                  
                  <th scope="col">Materia</th>
                  <th scope="col">Nota</th>
                  <th scope="col">Matrícula</th>
                </tr>
              </thead>
              <tbody>
                    {       
                    notas.map((x)=>{
                      return (
                        <CarNotasDetalle
                        materia = {x.materia} 
                        nota = {x.nota} 
                        matricula = {x.matricula} />
                      )  
                    })                             
                  }
              </tbody>
              </table>            
        </div>
    </div>
  );
};

export default CarNotas;