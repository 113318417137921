import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
import CardSaldos from "../element/CardSaldos";
import CardPlanesPago from "../element/CardPlanesPago";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from './Footer';

const Saldos = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");
  const [saldos, setSaldos] = useState({});
  const [saldosPP, setSaldosPP] = useState({});

  useEffect(() => {
    if (datos) {
      let cred = JSON.parse(datos);
      dataUser.token = cred.token;
      dataUser.nombre = cred.nombre;
      dataUser.carnet = cred.carnet;

      GetFetch.saldosAlumno(dataUser.carnet, dataUser.token).then(
        (saldosData) => {
          setSaldos(saldosData);
        }
      );

      GetFetch.saldosPlanesPagoAlumno(dataUser.carnet, dataUser.token).then(
        (saldosDataPP) => {
          setSaldosPP(saldosDataPP);
        }
      );

      loadMenu();
    } else {
      history.push("/");
    }
  }, [datos, history, dataUser]);

  let totalSaldo = 0;
  let totalSaldoPP = 0;
  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Saldos pendientes</h2>
        <div className="card">
          <div className="card-body">
            {saldosPP.length > 0 ? (
              <p>
                <b> <i class="fa fa-credit-card-alt" aria-hidden="true"></i><br></br>  Cuotas pendientes de planes de pago</b>
              </p>
            ) : null}
            {saldosPP.length > 0
              ?
              <>
              <table className="table table-striped table-dark">
              <thead>
                <tr>
                  <th scope="col">Cuota</th>
                  <th scope="col">Monto</th>
                  <th scope="col">Vencimiento</th>
                </tr>
              </thead>
              <tbody>
              {
                saldosPP.map((element) => {
                  totalSaldoPP += element.monto;
                  return <CardPlanesPago 
                  cuota = {element.cuota} 
                  monto = {element.monto} 
                  vencimiento = {element.vencimiento}
                  />;
                })
              }
              </tbody>
              </table>
              <h4 className="text-info text-center">
              <b>${totalSaldoPP.toFixed(2)} </b>
              <br></br>
              <small> Total planes de pago  </small>
            </h4>
              <hr></hr>
              </>
              
              
              : null}
            {saldos.length > 0 ? (
              <p>
                <b><i class="fa fa-money" aria-hidden="true"></i> <br></br>Meses pendientes de pago</b>
              </p>
            ) : null}
            <table className="table table-striped">
              <tbody>
                {saldos.length > 0 ? (
                  saldos.map((element) => {
                    totalSaldo += element.saldo;
                    return (
                      <CardSaldos
                        factura={element.factura}
                        concepto={element.concepto}
                        saldo={element.saldo}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="3">
                      <b className="text-primary">
                        No tiene saldos mensuales pendientes
                      </b>{" "}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
            <h4 className="text-info text-center">
              <b>${totalSaldo.toFixed(2)} </b>
              <br></br>
              <small> Total cuotas mensuales pendiente </small>
            </h4>
            {saldos.length > 0 ? (
              <a
                href="https://esen.edu.sv/finanzas/"
                target="_blanK"
                rel="noopener noreferrer"
                className="btn btn-primary mt-3"
              >
                {" "}
                Pagar
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Saldos;
