const GetFetchContentfulCMS = {};

const SPACE_ID = '1jypm8d9ychn';
const TOKEN = 'dNSXC25UzrdueWmeZDEsM7qzHtJ2tvr5pReqqqu7-UA';
// const urlAPI = `https://cdn.contentful.com/spaces/${SPACE_ID}/entries?access_token=${TOKEN}`;
const urlAPI = `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/master/entries?access_token=${TOKEN}&content_type=erecursos`;
const urlAPIGuiaTematica = `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/master/entries?access_token=${TOKEN}&content_type=guasTemticas`;
const urlAPIColaboradores = `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/master/entries?access_token=${TOKEN}&content_type=colaboradores`;
const urlAPIColaboradoresNombreBuscar = `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/master/entries?access_token=${TOKEN}&content_type=colaboradores&fields.nombre[match]=`;
const urlAPIPasantiasPF = `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/master/entries?access_token=${TOKEN}&content_type=pasantias&fields.tipo[all]=Pregunta frecuente`;
const urlAPIPasantiasTips = `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/master/entries?access_token=${TOKEN}&content_type=pasantias&fields.tipo[all]=Tips`;

const requestData = async(url) => {

    try {
        const entryPointLogin = url;
        return await fetch(entryPointLogin)
            .then(result => result.json())
            .then((data) => {
                return data;
            })
    } catch (error) {
        console.log(error);
        return error;
    }

}

GetFetchContentfulCMS.allContenido = () => {
    return requestData(urlAPI);
}

GetFetchContentfulCMS.allContenidoGuiasTematicas = () => {
    return requestData(urlAPIGuiaTematica);
}

GetFetchContentfulCMS.allContenidoColaboradores = () => {
    return requestData(urlAPIColaboradores);
}

GetFetchContentfulCMS.allContenidoPasantiasPF = () => {
    return requestData(urlAPIPasantiasPF);
}

GetFetchContentfulCMS.allContenidoPasantiasTips = () => {
    return requestData(urlAPIPasantiasTips);
}

GetFetchContentfulCMS.buscarColaboradorNombre = (dato) => {
    return requestData(urlAPIColaboradoresNombreBuscar + dato)
}

export default GetFetchContentfulCMS