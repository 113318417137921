const menuControl = [];

menuControl.registroMenu = () => {
    let registroControl = document.querySelectorAll('.registro');
    [].map.call(registroControl, function(obj) {
        if (obj.classList.contains('registro-menu')) {
            obj.classList.remove("registro-menu");
            console.log('REMOVE REGISTRO');
        } else {
            obj.classList.add("registro-menu");
            console.log('ADD REGISTRO');
        }
    });
}

menuControl.finanzasMenu = () => {
    let registroControl = document.querySelectorAll('.finanzas');
    [].map.call(registroControl, function(obj) {
        if (obj.classList.contains('finanzas-menu')) {
            obj.classList.remove("finanzas-menu");
        } else {
            obj.classList.add("finanzas-menu");
        }
    });
}

menuControl.direccionEstudiantilsMenu = () => {
    let registroControl = document.querySelectorAll('.direccionestudiantil');
    [].map.call(registroControl, function(obj) {
        if (obj.classList.contains('direccionestudiantil-menu')) {
            obj.classList.remove("direccionestudiantil-menu");
        } else {
            obj.classList.add("direccionestudiantil-menu");
        }
    });
}

menuControl.ccMenu = () => {
    let registroControl = document.querySelectorAll('.ccesen');
    [].map.call(registroControl, function(obj) {
        if (obj.classList.contains('ccesen-menu')) {
            obj.classList.remove("ccesen-menu");
        } else {
            obj.classList.add("ccesen-menu");
        }
    });
}

menuControl.notificacionMenu = () => {
    let registroControl = document.querySelectorAll('.noti');
    [].map.call(registroControl, function(obj) {
        if (obj.classList.contains('noti-menu')) {
            obj.classList.remove("noti-menu");
        } else {
            obj.classList.add("noti-menu");
        }
    });
}

menuControl.menuLoadDefault = () => {
    let defaultMenu = window.location.pathname;
    switch (defaultMenu) {
        case '/home':
        case '/notas':
        case '/alumnosnotas':
        case '/horassociales':
        case '/cum':
        case '/adminhorassociales':
        case '/admincum':
        case '/adminalumnimateriasinscritas':
        case '/practicasprofesionales':
        case '/toefl':
        case '/adminpracticas':
        case '/admintoefl':
            menuControl.registroMenu();
            break;
        case '/talonario':
        case '/solvencia':
        case '/fechaspago':
        case '/saldo':
        case '/alumnossolvencia':
        case '/alumnossaldo':
        case '/finanbibliocargomoras':
        case '/admincargomora':
        case '/admintalonario':
        case '/dtefinanzas':
        case '/dtealumnos':
            menuControl.finanzasMenu();
            break;
        case '/pasantias':
        case '/adminpasantiasall':
        case '/pasantiasalumnos':
        case '/pasantiadetalle':
        case '/adminpasantiasapply':
        case '/pasantiascv':
        case '/adminpasantiasalloff':
        case '/pasantiaspf':
        case '/pasantiastips':
        case '/pasantiasapplyalumno':
        case '/pasantiasalumnosb':
            menuControl.direccionEstudiantilsMenu();
            break;
        case '/inicio':
        case '/catalogo':
        case '/erecursos':
        case '/tematicas':
        case '/salas':
        case '/prestamos':
        case '/bibliocargomoras':
            menuControl.ccMenu();
            break;
        case '/alertmenu':
        case '/notificaciones':
        case '/adminpush':
        case '/pusheconomia':
        case '/pushderecho':
        case '/pushing':
        case '/pushadminesen':
        case '/pushadmin':
        case '/pushprofesores':
            menuControl.notificacionMenu();
            break;

        default:
            break;
    }
}

export default menuControl;