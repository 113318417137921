import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
import CardSalasColectivas from "../element/CardSalasColectivas";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from "./Footer";

const SalasColectivas = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");
  const [salas, setSalas] = useState([]);

  useEffect(() => {
    if (datos) {
      let cred = JSON.parse(datos);
      dataUser.token = cred.token;
      dataUser.nombre = cred.nombre;
      dataUser.carnet = cred.carnet;

      GetFetch.salasColectivas(dataUser.carnet, dataUser.token).then(
        (salasCC) => {
          setSalas(salasCC);
        }
      );

      loadMenu();
    } else {
      history.push("/");
    }
  }, [datos, history, dataUser]);

  useEffect(() => {
    const updateSalas = () => {
      let datos = localStorage.getItem("dataUser");
      let cred = JSON.parse(datos);
      GetFetch.salasColectivas(cred.carnet, cred.token).then((salasCC) => {
        setSalas(salasCC);
      });
    };

    setInterval(() => {
      updateSalas();
    }, 300000);
  }, []);

  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Salas Colectivas CC</h2>
        <div className="card">
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">Sala</th>
                  <th className="text-center">Estado</th>
                  <th className="text-center">Salida / Liberación</th>
                </tr>
              </thead>
              <tbody>
                {salas.length > 0
                  ? salas.map((element) => {
                      return (
                        <CardSalasColectivas
                          sala={element.nombre}
                          stado={element.estado}
                          salida={element.output}
                        />
                      );
                    })
                  : salas.length}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalasColectivas;
