import React, { useEffect, useContext, useState } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory, Link } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from "../hooks/useDireccionEstudiantil";
import dataFinanzas from "../hooks/useFinzas";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import Horas from "../hooks/HorasEspaciosDeportivos";
// import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";
import Footer from "../componentes/Footer";

const CanchaFootballSalaAdmin = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;
    dataUser.correo = cred.correo;

    // SetBitacora.set(cred.carnet, 'Canchas', cred.token);
  } else {
    history.push("/");
  }

  if (dataSuper.indexOf(dataUser.carnet.trim()) === -1) {
    if (
      dataDireccionEstudiantil.indexOf(dataUser.carnet.trim()) === -1 &&
      dataFinanzas.indexOf(dataUser.carnet.trim()) === -1
    ) {
      history.push("/");
    }
  }

  const [fechaHoy, setFechaHoy] = useState(0);
  const [fechaDate, setFechaDate] = useState(0);
  const [horaInico, setHoraInicio] = useState();
  const [horaFin, setHoraFin] = useState();
  const [horaInicoIndice, setHoraInicioIndice] = useState(0);
  const [horaFinIndice, setHoraFinIndice] = useState(0);
  const [alertValidacion, setAlertValidacion] = useState(0);
  const [horaActual, setHoraActual] = useState(0);
  const [pasantiaID, setPasantiaID] = useState();
  const [reservados, setReservados] = useState({0:0});
  const [libres, setLibres] = useState({0:0});
  const [alertValidacionReservas, setAlertValidacionReservas] = useState(0);

  const horaActualPresente = (horaMinimaSeleccionar) => {
    let fechaHoraActual = new Date(Date.now());
    let hms = +horaMinimaSeleccionar.substr(0, 2);
    let minutosSeleccionado = horaMinimaSeleccionar.substr(3, 2);
    let horaSistemaPresente = fechaHoraActual.getHours();
    let minutosSistemaPresente = fechaHoraActual.getMinutes();
    if (+comparandoFechas()) {
      if (hms === horaSistemaPresente) {
        if (+minutosSeleccionado === 0) {
          if (minutosSistemaPresente < 30) {
            setHoraActual(0);
          } else {
            setHoraActual(1);
          }
        } else {
          setHoraActual(0);
        }
      } else if (hms > horaSistemaPresente) {
        setHoraActual(0);
      } else {
        setHoraActual(1);
      }
    } else {
      setHoraActual(0);
    }
  };

  const comparandoFechas = () => {
    let fechaActual = Horas.FechaActual();
    let presente = new Date(fechaActual);
    presente.setDate(presente.getDate() + 1);
    let fin = new Date(fechaDate);
    fin.setDate(fin.getDate() + 1);

    if (presente < fin) {
      return 0;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    let fechaHoy = Horas.formatFecha(new Date(Date.now()));
    // fechaHoy = fechaHoy.replace(/\//g, "-"); //Quitando las plecas y poniendo guiones
    GetFetch.getDeportiosEspacios(fechaHoy, "Cancha multideporte").then(
      (data) => {
        let fechaHoraActual = new Date(Date.now());
        let horaSistemaPresente = fechaHoraActual.getHours();
        if (data.length > 0) {
          //Existen registro con la fecha presente
          //Leer horas disponibles
          Horas.HorasLibres(
            "selectHoraInicio",
            data[0].spacios_libres,
            horaSistemaPresente
          );
          Horas.HorasLibres(
            "selectHoraFin",
            data[0].spacios_libres,
            horaSistemaPresente
          );
          setPasantiaID(data[0]._id);
          setReservados(data[0].spacios_reservados);
          setLibres(data[0].spacios_libres);
        } else {
          //Poner todas las horas disponibles no hay ristro con la fecha presente
          Horas.HorasLibres(
            "selectHoraInicio",
            Horas.horas,
            horaSistemaPresente
          );
          Horas.HorasLibres("selectHoraFin", Horas.horas, horaSistemaPresente);
        }
      }
    );
    setFechaHoy(Horas.formatFecha(Horas.FechaActual()));
    setFechaDate(Horas.FechaActual());
    loadMenu();
  }, []);

  let fechaMin = Horas.FechaActual();

  const fechaDateSet = (e) => {
    setFechaDate(e.target.value);
    setFechaHoy(Horas.formatFecha(e.target.value, 1));
    updateSelectHorasChangeDate(Horas.formatFecha(e.target.value, 1));
    setAlertValidacion(1);
  };

  const horaInicioSet = (e) => {
    setHoraInicio(Horas.horas[+e.target.value]);
    setHoraInicioIndice(+e.target.value);
    horaActualPresente(Horas.horas[+e.target.value]);
    Horas.resetFin();
  };


  const horaFinSet = (e) => {
    setHoraFin(Horas.horas[+e.target.value]);
    setHoraFinIndice(+e.target.value);
    validacionHoras(+e.target.value);
    validacionReservas(Horas.calculoHorasIntermedias(horaInicoIndice,+e.target.value));
  };

  let validacionReservas = (horasElegidas)=>{
    let horasNoDisponibles = [];
    horasElegidas.map((element)=>{
      if (reservados[element] !== undefined) {
        horasNoDisponibles.push(reservados[element]);        
      }
      return null;
    })

    if (horasNoDisponibles.length > 0) {
      setAlertValidacionReservas(horasNoDisponibles);
    }else{
      setAlertValidacionReservas(0);
    }
  }

  let validacionHoras = (fin) => {
    if (horaInicoIndice < fin) {
      setAlertValidacion(0);
    } else {
      setAlertValidacion(1);
    }
  };

  const updateSelectHorasChangeDate = (fecha) => {
    let fechaHoraActual = new Date(Date.now());
    let horaSistemaPresente = fechaHoraActual.getHours();
    let diaSistema = fechaHoraActual.getDate();
    let diaSeleccionado = fecha.substr(0, 2);
    if (+diaSeleccionado !== +diaSistema) {
      horaSistemaPresente = "08:00";
    }

    GetFetch.getDeportiosEspacios(fecha, "Cancha multideporte").then((data) => {
      if (data.length > 0) {
        //Existen registro con la fecha presente
        //Leer horas disponibles
        Horas.HorasLibres(
          "selectHoraInicio",
          data[0].spacios_libres,
          horaSistemaPresente
        );
        Horas.HorasLibres(
          "selectHoraFin",
          data[0].spacios_libres,
          horaSistemaPresente
        );
        setPasantiaID(data[0]._id);
        setReservados(data[0].spacios_reservados);
        setLibres(data[0].spacios_libres);
      } else {
        //Poner todas las horas disponibles no hay ristro con la fecha presente
        Horas.HorasLibres("selectHoraInicio", Horas.horas);
        Horas.HorasLibres("selectHoraFin", Horas.horas);
        setPasantiaID();
        setReservados({0:0});
        setLibres(Horas.horas);
      }
    });
  };

  const btnReservar = async (e) => {
    e.preventDefault();
    const data = new FormData();
    if (setPasantiaID) {
      data.append("_id", pasantiaID);
    }
    data.append("secret", "5ec4702eff406849a82818cb");
    data.append("espacio", "Cancha multideporte");
    data.append("fecha", fechaHoy);
    data.append("date", fechaDate);
    data.append("spacios_libres", JSON.stringify(libres));
    data.append("spacios_reservados", JSON.stringify(reservados));
    data.append("spacios_ocupados", JSON.stringify({ 0: 0 }));
    let reservas = [
      {
        responsable: dataUser.carnet,
        nombre: dataUser.nombre,
        correo: dataUser.correo,
        hora_inicio: horaInicoIndice,
        hora_fin: horaFinIndice,
        stado:"0",
        fecha_reserva: fechaHoy,
        espacio: 'football sala'
      },
    ];
    data.append("reservas", JSON.stringify(reservas));

    await GetFetch.setDeportiosEspaciosReserva(data, dataUser.token).then(
      (datosSet) => {
        if (datosSet.success) {
          history.push("/canchasadmin");
        } else {
          return 0;
        }
      }
    );
  };

  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Cancha de football sala</h2>
        <div className="content text-center">
          <span>Reservar para el:</span>
          <br></br>
          <span className="display-4"> {fechaHoy} </span>
          <br></br>
          <span> {horaInico} </span> |<span> {horaFin} </span>
          <div className="form-group mt-5">
            <span>Cambiar fecha</span>
            <input
              type="date"
              className="form-control"
              min={fechaMin}
              value={fechaDate}
              onChange={fechaDateSet}
            />
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Hora de inicio
                </label>
                <select
                  id="selectHoraInicio"
                  className="form-control"
                  onChange={horaInicioSet}
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Hora de fin</label>
                <select
                  className="form-control"
                  id="selectHoraFin"
                  onChange={horaFinSet}
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
            {horaActual === 0 ? null : (
              <div className="alert alert-danger col-12" role="alert">
                <i className="fa fa-bell-o mr-2 fa-spin" aria-hidden="true"></i>{" "}
                <b>Hora de inicio</b> seleccionda fuera de reserva.
              </div>
            )}
            {alertValidacion === 0 ? null : (
              <div className="alert alert-danger col-12" role="alert">
                <i className="fa fa-bell-o mr-2 fa-spin" aria-hidden="true"></i>{" "}
                <b>Hora de finalización</b> debe ser mayor a la hora de inicio
              </div>
            )}
            {alertValidacionReservas === 0 ? null : (
              <div className="alert alert-danger col-12" role="alert">
                <i className="fa fa-bell-o mr-2 fa-spin" aria-hidden="true"></i>{" "}
                Las horas de inicio y fin seleccionadas cubren horas que ya se encuentran reservadas. Las horas que estan afectando su reserva son: &nbsp;&nbsp;&nbsp;
                {
                      alertValidacionReservas.map((el, l) => {
                        return (
                          <b key={l}>{el} /</b>
                        )
                      })
                }
              </div>
            )}

            {alertValidacion === 0  && horaInicoIndice > 0 && horaFinIndice > 0 ? (
              <div className="col-12 mt-4">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={btnReservar}
                >
                  {" "}
                  Reservar
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <hr></hr>
        <Link className="btn btn-dark mt-5" to="/canchasreservasadmin">
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </Link>
      </div>
    </div>
  );
};

export default CanchaFootballSalaAdmin;
