import React from "react";
import CarErecursos from "./CarErecursos";

const GuiasTematicasCollapse = ({ guias, guiasTitulo, carnet }) => {

    // console.log(guias);

    return (
        <div class="accordion" id="accordionExample">

            {
                guiasTitulo.map((data, key) => {
                    let idItem = '#collapse' + key;
                    let itemId = 'collapse' + key;
                    let itemHead = 'heading' + key;
                    return (
                        <>
                            <div class="card">
                                <div class="card-header" id={itemHead}>
                                    <h2 class="mb-0">
                                        <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target={idItem} aria-expanded="true" aria-controls={itemId}>
                                         <b><i class="fa fa-map-signs text-info" aria-hidden="true"></i> </b>  {data} 
                                        </button>
                                    </h2>
                                </div>

                                <div id={itemId} class="collapse" aria-labelledby={itemHead} data-parent="#accordionExample">
                                    <div class="card-body text-center">
                                        {
                                            guias
                                                ?
                                                guias[key].map((recurso)=>{
                                                    recurso.guiasTematicas = [];
                                                    return (
                                                        <CarErecursos
                                                            recurso={recurso}
                                                            carnet= {carnet}
                                                        />
                                                    )
                                                })
                                                    
                                                
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
            }

            {/* <div class="card">
                <div class="card-header" id="headingOne">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Collapsible Group Item #1
                </button>
                    </h2>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        Some placeholder content for the first accordion panel. This panel is shown by default, thanks to the <code>.show</code> class.
            </div>
                </div>
            </div> */}
            {/* <div class="card">
                <div class="card-header" id="headingTwo">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Collapsible Group Item #2
                </button>
                    </h2>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div class="card-body">
                        Some placeholder content for the second accordion panel. This panel is hidden by default.
            </div>
                </div>
            </div> */}
            {/* <div class="card">
                <div class="card-header" id="headingThree">
                    <h2 class="mb-0">
                        <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Collapsible Group Item #3
                </button>
                    </h2>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div class="card-body">
                        And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
            </div>
                </div>
            </div> */}
        </div>
    )
};

export default GuiasTematicasCollapse;