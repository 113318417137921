import React, { useEffect, useState, useContext } from "react";
import { Link } from 'react-router-dom';

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
import { Timeline } from 'react-twitter-widgets'
import OneSignal from 'react-onesignal';

// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import CarEventos from "../element/CarEventos"
// import CarNotificaciones from "../element/CarNotificaciones";
import Menu from "../componentes/Menu";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import GetFetchNotificaciones from "../helper/GetFetchNotificaciones";
import loadMenu from "../js/main";
import dataSuper from '../hooks/Conf';
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
import Footer from '../componentes/Footer';

const Eventos = () => {

    const history = useHistory();
    const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');

    const [eventos, setEventos] = useState({});
    const [notificaciones, setNotificaciones] = useState({});
    const [alerta, setAlerta] = useState(false);

    if (datos) {
        let cred = JSON.parse(datos);
        dataUser.token = cred.token;
        dataUser.nombre = cred.nombre;
        dataUser.carnet = cred.carnet;
        dataUser.correo = cred.correo;

        if (dataSuper.indexOf(cred.carnet.trim()) === -1) {
            if (dataDireccionEstudiantil.indexOf(cred.carnet.trim()) === -1) {
                history.push("/");
            }
        }
    } else {
        history.push("/");
    }

    let alertaVisualNoti = (dataNoti)=>{
        let alertaNoti = localStorage.getItem('alertaNotiLocal');
        if (alertaNoti) {
            let tempNoti = JSON.parse(alertaNoti);
            //Recorriendo el resultado de notificaciones de la nube
            dataNoti.map((elemento)=>{
                //Ver si existe ID de notificaciones guardadas
                if (tempNoti.indexOf(elemento._id.trim()) === -1) {
                    setAlerta(true);
                    // sonarAudio();
                }
               
                return null;
            })
            
        }else{
            setAlerta(true);
            // sonarAudio();
        }
        return null;
    }

    // let sonarAudio = ()=>{
    //     var audio = document.getElementById("audio");
    //     audio.play();

    //     setTimeout(() => {
    //         audio.play();
    //     }, 5000);

    //     setTimeout(() => {
    //         audio.play();
    //     }, 10000);
    // }

    useEffect(() => {
        GetFetch.getEventos(dataUser.token)
            .then((eventosData) => {
                if (eventosData.length > 0) {
                    setEventos(eventosData.reverse());
                } 
            });

        //Llamando a las notificaciones
        if (dataSuper.indexOf(dataUser.carnet.trim()) === -1) {
            if (dataUser.perfil === 'Alumnos') {
            if (dataUser.carrera === 'Ingeniería de Negocios') {
                dataUser.perfil = 'IDN' + dataUser.carnet.substr(0,4);
            }
            if (dataUser.carrera === 'Ciencias Jurídicas') {
                dataUser.perfil = 'LCJ' + dataUser.carnet.substr(0,4);
            }
            if (dataUser.carrera === 'Economía y Negocios') {
                dataUser.perfil = 'LEN' + dataUser.carnet.substr(0,4);
            }
            }
    
            GetFetchNotificaciones.getNotificaciones(dataUser.token, dataUser.carrera, dataUser.perfil).then((dataNoti) => {
             setNotificaciones(dataNoti);
             alertaVisualNoti(dataNoti); 
            });
            
        }else{
            GetFetchNotificaciones.getNotificacionesAll(dataUser.token).then((dataNoti) => {
                setNotificaciones(dataNoti);    
                alertaVisualNoti(dataNoti);        
            });
        }

      loadMenu();
    }, [dataUser]);

    useEffect(() => {
        OneSignal.init({
          appId: "7604e2b7-4287-4a83-86e7-05430366115a",
          path: 'https://api.esen.edu.sv/notificationpush/OneSignalSDKWorker.js'
        });
      }, []);

    return (

        <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
                <div className="custom-menu">
                    <button
                        type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary"
                    >
                        <i className="fa fa-bars"></i>
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4">
                    <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

                    <HeaderMenu
                        carnet={dataUser.carnet}
                        nombre={dataUser.nombre}
                        posicion="text-center"
                    />

                    <Menu />

                    <Footer />
                </div>
            </nav>

            <div id="content" className="p-4 p-md-5 pt-5">
                {/* <h2 className="mb-4"> Eventos y noticias</h2> */}
                <div className="container text-right">   
                    {
                        alerta 
                        ?
                        <Link className="btn btn-danger mr-1 mb-2" to="/notificaciones">
                            <i class="fa fa-bullhorn" aria-hidden="true"></i>
                            <span className="spinner-grow spinner-grow-sm" style={{'margin-left':'-5px', 'margin-bottom':'4px'}} role="status" aria-hidden="true"></span>
                        </Link>
                        :
                        null
                    } 
                    <Link to="/adminevento" className="btn btn-success mb-2"><b><i class="fa fa-calendar" aria-hidden="true"></i></b> Nuevo evento</Link>                    
                <br></br>
                </div>
               
                {
                    eventos.length > 0
                        ?
                        eventos.map((element) => {
                            return (
                                <CarEventos
                                    elemento={element} 
                                    tardie = "1"
                                    autor = {dataUser.correo}
                                />
                            )
                        })
                        :
                        null
                }
                <Timeline
                dataSource={{
                    sourceType: 'profile',
                    screenName: 'esenelsalvador'
                }}
                options={{
                    height: '1000',
                    chrome: "noheader, nofooter"
                }}
                />
            </div>
        </div>
    );
}

export default Eventos;