import React from "react";
import { Link } from "react-router-dom";

const CarPasantiaAplicaciones= ({ datos , _id }) => {
 let cvEnviados = 0;
 datos.length > 0 && (
    datos.map(ele =>{
        +ele.stado === 2 && (
            cvEnviados++
        )
        return null;
    })
 )
  return (
    <Link to={'adminpasantiasapply?id_pasantia='+ _id} className="btn btn-success btn-sm">
        <i class="fa fa-paper-plane" aria-hidden="true"></i>  CVs enviados:  <span><b>{cvEnviados}</b></span>
    </Link>
  );
};

export default CarPasantiaAplicaciones;