import React from "react";
import CarDetalle from './CarDetalle';

const CarHorarios = ({ materia, datos }) => {
  const horarios = datos || [];
  return (
    <div class="card">
        <div class="card-body text-center homeLogin">
            <h4 className="text-success text-center homeLogin">{materia} </h4>
            <table class="table">
              <thead>
                <tr>                  
                  <th scope="col">Día</th>
                  <th scope="col">Hora</th>
                  <th scope="col">Sección</th>
                </tr>
              </thead>
              <tbody>
                    {       
                    horarios.map((x)=>{
                      return (
                        <CarDetalle 
                        diasemana = {x.diasemana} 
                        hora = {x.hora_inicio} 
                        seccion = {x.seccion} />
                      )  
                    })                             
                  }
              </tbody>
              </table>            
        </div>
    </div>
  );
};

export default CarHorarios;