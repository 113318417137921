import React from "react";
import Logo from './Logo';

let Footer = () => (
  <div className="footer text-center">
    <Logo width="50%" />
    <p className="mt-3">
      <small> Escuela Superior de Economía y Negocios</small>
      <br></br>
      <small>Todos los derechos reservados</small> <br></br>
      <small> Copyright &copy;</small>
      <small> 2021</small>
      <br></br>
    </p>
  </div>
);

export default Footer;
