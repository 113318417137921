import $ from "jquery";
// (function($) {

//     var fullHeight = function() {

//         $('.js-fullheight').css('height', $(window).height());
//         $(window).resize(function() {
//             $('.js-fullheight').css('height', $(window).height());
//         });

//     };
//     fullHeight();

//     $('#sidebarCollapse').on('click', function() {
//         $('#sidebar').toggleClass('active');
//     });

// })($);

const fullHeight = () => {
    $(".js-fullheight").css("height", $(window).height());
    $(window).resize(function() {
        $(".js-fullheight").css("height", $(window).height());
    });

    $("#sidebarCollapse").on("click", function() {
        $("#sidebar").toggleClass("active");
    });
}

export default fullHeight