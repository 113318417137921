import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import menuControl from "../hooks/useMenuControl";

const MenuAlumnos = ({
  clasecssRegistro = "registro registro-menu",
  clasecssFinanzas = "finanzas finanzas-menu",
  clasecssCCESEN = "ccesen ccesen-menu",
  clasecssDireccionEstudiantil = "direccionestudiantil direccionestudiantil-menu"
}) => {
  const history = useHistory();
  const { registroMenu, finanzasMenu, ccMenu, menuLoadDefault, direccionEstudiantilsMenu } = menuControl;

  let cerrarSession = (e) => {
    e.preventDefault();
    localStorage.removeItem("dataUser");
    history.push("/");
  };

  useEffect(() => {
    menuLoadDefault();
  }, [menuLoadDefault]);

  return (
    <ul className="list-unstyled components mb-5 homeLogin">
      <li className="control-ccesen-menu" onClick={ccMenu}>
        <Link to="#">
          <span className="fa fa-plus mr-3"></span> CCESEN
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/catalogo">
          <span className="fa fa-briefcase mr-1 ml-3"></span> Catálogo CCESEN
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/bibliocargomoras">
          <span className="fa fa-money mr-1 ml-3"></span> Cargos y moras
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/erecursos">
          <span className="fa fa-book mr-1 ml-3"></span> E-RECURSOS
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/tematicas">
          <span className="fa fa-map-signs mr-1 ml-3"></span> Guías temáticas
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/inicio">
          <span className="fa fa-home mr-1 ml-3"></span> Libros nuevos
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/prestamos">
          <span className="fa fa-sticky-note mr-1 ml-3"></span> Préstamos
          activos
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssCCESEN}>
        <Link to="/salas">
          <span className="fa fa-paper-plane mr-1 ml-3"></span> Salas colectivas
          CC
        </Link>
      </li>
      {/* <li className="admin-general-menu">
        <Link to="/cic">
          <span className="fa fa-thermometer mr-3"></span> Covid-19
        </Link>
      </li> */}
      <li className="admin-general-menu">
        <Link to="/correo">
          <span className="fa fa-envelope-o mr-3"></span> Correo electrónico
        </Link>
      </li>
      <li className="control-direccionestudiantil-menu" onClick={direccionEstudiantilsMenu}>
        <Link to="#">
          <span className="fa fa-plus mr-3"></span> Dirección estudiantil
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssDireccionEstudiantil}>
        <Link to="/pasantiascv">
          <span className="fa fa-address-book ml-3 mr-1"></span> Curriculum vitae
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssDireccionEstudiantil}>
        <Link to="/pasantiasalumnos">
          <span className="fa fa-briefcase ml-3 mr-1"></span> Pasantías
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssDireccionEstudiantil}>
        <Link to="/pasantiaspf">
          <span className="fa fa-question-circle ml-3 mr-1"></span> Preguntas frecuentes
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssDireccionEstudiantil}>
        <Link to="/pasantiastips">
          <span className="fa fa-black-tie ml-3 mr-1"></span> Tips sobre pasantías
        </Link>
      </li>
      <li className="admin-general-menu">
        <Link to="/directorio">
          <span className="fa fa-address-book mr-3"></span> Directorio ESEN
        </Link>
      </li>
      <li className="active admin-general-menu">
        <Link to="/eventos">
          <span className="fa fa-calendar mr-3"></span> Eventos
        </Link>
      </li>
      <li className="admin-general-menu">
        <Link to="/canchas">
          <span className="fa fa-connectdevelop mr-3"></span>Espacios deportivos
        </Link>
      </li>
      <li className="control-finanzas-menu" onClick={finanzasMenu}>
        <Link to="#">
          <span className="fa fa-plus mr-3 "></span> Finanzas
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/finanbibliocargomoras">
          <span className="fa fa-money mr-1 ml-3"></span> Cargos y moras CC
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/dtealumnos">
          <span className="fa fa-money ml-3 mr-1"></span> Facturas 
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/fechaspago">
          <span className="fa fa-suitcase mr-1 ml-3"></span> Fechas de pago
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/talonario">
          <span className="fa fa-briefcase mr-1 ml-3"></span> Talonarios
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/solvencia">
          <span className="fa fa-sticky-note mr-1 ml-3"></span> Solvencia
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssFinanzas}>
        <Link to="/saldo">
          <span className="fa fa-cogs mr-1 ml-3"></span> Saldos
        </Link>
      </li>
      <li className="admin-general-menu">
        <Link to="/manualestilo">
          <span className="fa fa-stack-overflow mr-3"></span> Manual de estilo
        </Link>
      </li>
      <li className="admin-general-menu">
        <Link to="/notificaciones">
          <span className="fa fa-bullhorn mr-3"></span> Notificaciones
        </Link>
      </li>
      <li className="control-registro-menu" onClick={registroMenu}>
        <Link to="#">
          <span className="fa fa-plus mr-3"></span> Registro Académico
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/cum">
          <span className="fa fa-diamond ml-3 mr-1"></span> CUM
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/home">
          <span className="fa fa-cubes ml-3 mr-1"></span> Horario de clases
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/horassociales">
          <span className="fa fa-hourglass-half ml-3 mr-1"></span> Horas sociales
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/notas">
          <span className="fa fa-sticky-note ml-3 mr-1"></span> Notas
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/practicasprofesionales">
          <span className="fa fa-briefcase ml-3 mr-1"></span> Prácticas profesionales
        </Link>
      </li>
      <li className={'fondoBalck ' + clasecssRegistro}>
        <Link to="/toefl">
          <span className="fa fa-globe ml-3 mr-1"></span> Toefl
        </Link>
      </li>
      <li>
        <Link to="/socialmedia">
          <span className="fa fa-camera-retro mr-3"></span> Redes sociales
        </Link>
      </li> 
      <li>
        <Link to="/" onClick={cerrarSession}>
          <span className="fa fa-sign-out mr-3"></span> Cerrar sesión
        </Link>

        <div>
          <audio id="audio" className="audio-element">
            <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source>
          </audio>
        </div>
      </li>
    </ul>
  );
};

export default MenuAlumnos;
