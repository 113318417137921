import React from "react";
import "../css/styleEspaciosDeportivos.css";

const CarCanchas = ({ reservasData, fecha, espacio, Horas, spacios_libres, spacios_reservados, _id, btnCancelarReserva }) => {
  return  reservasData.map((reservas, key) => {
    return (
      <div key={'eerr' + key} className="card-body">
        <table key={"tableReserva" + key} className="table table-striped table-dark text-center">
          <thead>
            <tr>
              <th scope="col">Fecha</th>
              <th scope="col">Espacio</th>
              <th scope="col">Horas</th>
              <th scope="col">OK</th>
            </tr>
          </thead>
          <tbody>
            <tr key={key}>
              <td>{fecha}</td>
              <td>{espacio}
              {
                reservas.espacio 
                ?
               <span className="text-success"><b> ({reservas.espacio})</b></span>
                :
                null
              }
              </td>
              <td>
                {Horas.horas[reservas.hora_inicio]} /{" "}
                {Horas.horas[reservas.hora_fin]}{" "}
              </td>
              <th>
              {+reservas.stado === 1 ? (
                                  <i
                                    key={"list" + key}
                                    className="fa fa-thumbs-up text-success fa-2x"
                                    data-inicio = {reservas.hora_inicio} 
                                    data-fin = {reservas.hora_inicio} 
                                    data-carnet = {reservas.responsable} 
                                    data-libres = {JSON.stringify(spacios_libres)}
                                    data-reservados = {JSON.stringify(spacios_reservados)}
                                    data-id = {_id}
                                    aria-hidden="true"
                                  ></i>
                                ) : 
                                +reservas.stado === 2
                                ?
                                <i
                                    key={"list" + key}
                                    className="fa fa-hand-paper-o text-info fa-2x"
                                    aria-hidden="true"
                                  ></i>
                                  :
                                  +reservas.stado === 3
                                  ?
                                  <i
                                      key={"list" + key}
                                      className="fa fa-thumbs-down text-danger fa-2x"
                                      aria-hidden="true"
                                    ></i>
                                    :
                                  (
                                    <i
                                      key={"list" + key}
                                      className="fa fa-clock-o text-warning fa-2x"
                                      aria-hidden="true"
                                    ></i>
                                  )
                                }
              </th>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        {
          reservas.participantes
          ?
            <>
                <p>
                   <b>Participantes / Integrantes</b>
                </p>
                  <table className="table table-sm">
                    <tbody>
                  {reservas.participantes.map((ele, key) => {
                    return (
                      <tr key={'ttaas'+key}>
                        <td><span className="mr-3">{key + 1}</span></td>
                        <td><span className="mr-3">{ele.carnet}</span> {ele.nombre}</td>
                      </tr>
                    );
                  })}
                  </tbody>
                  </table>
            </>
          :
          null
        }

        {
          (+reservas.stado === 2 || +reservas.stado === 3)
          ?
          <h3 className="text-warning text-center">RESERVA CANCELADA</h3>
          :
          <div className="col-12 mt-4">
            <button
              type="button"
              className="btn btn-danger btn-block" 
              data-inicio = {reservas.hora_inicio} 
              data-fin = {reservas.hora_fin} 
              data-carnet = {reservas.responsable} 
              data-libres = {JSON.stringify(spacios_libres)}
              data-reservados = {JSON.stringify(spacios_reservados)}
              data-id = {_id}
              data-iid = {reservas.responsable + _id + reservas.hora_inicio + reservas.hora_fin}
              onClick={btnCancelarReserva} 
              id={reservas.responsable + _id + reservas.hora_inicio + reservas.hora_fin}
            >
              <i className="fa fa-trash-o mr-2" aria-hidden="true"></i>  
            Cancelar reserva
            </button>
          </div>
        }

      </div>
    )
  })
  
};

export default CarCanchas;
