import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";

// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import FormCatalogoBiblio from "../element/FormCatalogoBiblio";
import FotoBiblio from "../element/FotoBiblio";
import CardCatalogoBiblio from "../element/CardCatalogoBiblio";
import Footer from './Footer';

const BiblioCatalogo = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem('dataUser');
  //State del formulario
  const [catalogo, setCatalogo] = useState({
    tipo: 1,
    txtbuscar: "",
  });
  const [resultado, setResultados] = useState({});
  const [consultar, setConsultar] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  let [alert, setalert] = useState('text-warning catalogoResult');

  const { tipo, txtbuscar } = catalogo;
  const numRow = 5;

  useEffect(() => {

    // loadMenu();

    if (datos) {
      let cred = JSON.parse(datos);
      dataUser.token = cred.token;
      dataUser.nombre = cred.nombre;
      dataUser.carnet = cred.carnet;

      if (consultar) {
        const conultarAPI = async () => {
          await GetFetch.catalogo(
            tipo,
            txtbuscar,
            dataUser.token,
            dataUser.carnet,
            pagina,
            numRow
          ).then((dataCatalogo) => {
            let totalEncontrados = dataCatalogo.pop().totalRegistros;
            // setTotalRow(totalEncontrados);
            setResultados(dataCatalogo);
            setTotalPaginas(Math.ceil(+totalEncontrados / numRow));
          });
        };
        const carbody = document.querySelector('.card-body');
        carbody.scrollIntoView({ behavior: 'smooth' });
        conultarAPI();
        setConsultar(false);
        if(resultado.length === undefined){
          setalert('text-warning');
        }
      }

    } else {
      history.push("/");
    }

    loadMenu();
  }, [datos, history, dataUser, consultar, tipo, txtbuscar, pagina, resultado]);

  const paginaAnterior = () => {
    const paginaActual = pagina - 1;
    if (paginaActual === 0) return;
    setPagina(paginaActual);
    setConsultar(true);
    loadMenu();
  };

  const paginaSiguiente = () => {
    const paginaActual = pagina + 1;
    if (paginaActual > totalPaginas) return;
    setPagina(paginaActual);
    setConsultar(true);
    loadMenu();
  };


  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Catálogo CCESEN</h2>
        <div className="card">
          <div className="card-body">
            <FormCatalogoBiblio
              catalogo={catalogo}
              setCatalogo={setCatalogo}
              setConsultar={setConsultar}
              setPagina={setPagina}
            />
            {resultado.length > 0
              ? resultado.map((elem) => {
                return (
                  <div className="row">
                    <div className="col-12 col-sm-3">
                      <FotoBiblio
                        foto={elem.portada}
                        alt="Libro de biblioteca CCESEN"
                        tamanio="rounded mx-auto d-block"
                      />
                    </div>
                    <div className="col-12 col-sm-9">
                      <CardCatalogoBiblio
                        titulo={elem.titulos}
                        resumen={elem.datos}
                      />
                    </div>
                  </div>
                );
              })
              : null}

            {resultado.length > 0
              ?
              (
                <div className="text-center">
                  {pagina === 1 ? null : (
                    <button
                      type="button"
                      className="btn btn-info mr-1"
                      onClick={paginaAnterior}
                    >
                      &laquo; Anterior
                    </button>
                  )}

                  {pagina === totalPaginas ? null : (
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={paginaSiguiente}
                    >
                      Siguiente &raquo;
                    </button>
                  )}
                </div>
              ) :

              (
                <h4 className={alert}>
                  No se encontrarón resultados
                </h4>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiblioCatalogo;
