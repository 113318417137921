import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
import CardFechasVencimiento from "../element/CardFechasVencimiento";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from './Footer';

const FechasVencimiento = () => {

  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem('dataUser');
  const [fechas, setFechas] = useState([]);

  useEffect(() => {

    if (datos) {
      let cred = JSON.parse(datos);
      dataUser.token = cred.token;
      dataUser.nombre = cred.nombre;
      dataUser.carnet = cred.carnet;

      GetFetch.fechasVencimiento(dataUser.token)
        .then((fechasMateriasIT) => {
          setFechas(
            fechasMateriasIT
          );
        });

      loadMenu();
    } else {
      history.push("/");
    }
  }, [datos, history, dataUser]);

  let nuevo_ingreso = 0;
  let ingreso_temprano = 0;
  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Fechas de vencimiento de pagos</h2>
        <div className="card">
          <div className="card-body" id='m9877'>
            <p className="text-primary"> Antiguo ingreso</p>
            <table className="table">
              <tbody>
                {fechas.length > 0
                  ?
                  fechas.map((element) => {
                    if (+element.tipo === 1) {
                      nuevo_ingreso += 1;
                      return (
                        <CardFechasVencimiento
                          mes={element.mes}
                          vencimiento={element.vencimiento}
                          key = {element.id}
                        />
                      );                      
                    }else{
                      return null;
                    }
                  })
                  
                  : fechas.length}
                  {
                    nuevo_ingreso === 0 ? <p className="text-danger">Fechas de pago pendientes por definir para <b>ANTIGUO INGRESO</b>, le invitamos a estar pendiente de esta información</p>
                    : null
                  }
              </tbody>
            </table>
          </div>
          <div className="card-body" id="m1233">
            <p className="text-primary"> Ingreso temprano</p>
            <table className="table">
              <tbody>
                {fechas.length > 0
                  ?
                  fechas.map((element) => {
                    if (+element.tipo === 2) {
                      ingreso_temprano += 1;
                      return (
                        <CardFechasVencimiento
                          mes={element.mes}
                          vencimiento={element.vencimiento}
                          key = {element.id}
                        />
                      );                      
                    }else{
                      return null;
                    }
                  })                  
                  : fechas.length}
                  {
                     ingreso_temprano === 0 
                     ? 
                      <p className="text-danger">Fechas de pago pendientes por definir para <b>NUEVO INGRESO (Ingreso en julio)</b>, le invitamos a estar pendiente de esta información</p>
                     : null
                  }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FechasVencimiento
