import React, { useEffect, useState } from "react";

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
import dataFinanzas from '../hooks/useFinzas';
// Contexto
// import dataContext from "../context/User";

// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from '../componentes/Footer';

import "../css/styleAdmin.css";

const Stadistica = () => {
  const history = useHistory();

  // const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  if (!datos) {
    history.push("/");
  }

  datos = JSON.parse(datos);

  if (dataSuper.indexOf(datos.carnet.trim()) === -1) {
    if (dataDireccionEstudiantil.indexOf(datos.carnet.trim()) === -1 && dataFinanzas.indexOf(datos.carnet.trim()) === -1 ) {
      history.push("/");
    }
  }  

  const [total, setTotal] = useState(0);
  const [totalConsulta, setTotalConsulta] = useState();
  const [last, setLast] = useState();
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');

  useEffect(() => {

    const fechaActual = (i = 0) => {
      let fecha = new Date(Date.now());
  
      let dd = fecha.getDate() + i;
      let mm = fecha.getMonth() + 1;
      let yyyy = fecha.getFullYear();
  
      if (mm < 10) {
          mm = "0" + mm;
      }
  
      if (dd < 10) {
          dd = "0" + dd;
      }
      return yyyy + "-" + mm + "-" + dd;
  }
    let start = fechaActual();
    let end  = fechaActual();

    setDateStart(start);
    setDateEnd(end);
    
    Promise.all([
      GetFetch.statisTotal(start,end, datos.token).then((datoss) => {
        setTotal(datoss.result[0].consulta);
      }),
      GetFetch.statisTotalConsulta(start,end, datos.token).then((datoss) => {
        setTotalConsulta(datoss.result);
      }),
      GetFetch.statisLast(start,end, datos.token).then((datoss) => {
        setLast(datoss.result);
      }),
    ])
    loadMenu();
  }, [datos.token]);

  const dataStartSet = (e)=>{
    setDateStart(e.target.value);
  }

  const dataEndSet = (e)=>{
    setDateEnd(e.target.value);
  }

  const btnStatic = ()=>{
    Promise.all([
      GetFetch.statisTotal(dateStart,dateEnd, datos.token).then((datoss) => {
        setTotal(datoss.result[0].consulta);
      }),
      GetFetch.statisTotalConsulta(dateStart,dateEnd, datos.token).then((datoss) => {
        setTotalConsulta(datoss.result);
      }),
      GetFetch.statisLast(dateStart,dateEnd, datos.token).then((datoss) => {
        setLast(datoss.result);
      }),
    ])
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={datos.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={datos.carnet}
            nombre={datos.nombre}
            perfil={datos.perfil}
            carrera={datos.carrera}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <div className="container">
          <h1 className="mt-4 text-center"> Estadística </h1>
        
          <div className="form-group mt-5">
            <span>Start</span>
            <input
              type="date"
              className="form-control"
              name="startDate"
              onChange={dataStartSet} 
              value={dateStart}
            />
          </div>
          <div className="form-group mt-5">
            <span>End</span>
            <input
              type="date"
              className="form-control"
              name="endDate" 
              onChange={dataEndSet}
              value={dateEnd}
            />
          </div>

          <div className="col-12 mt-4">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={btnStatic}
                >
                  {" "}
                  Estadística
                </button>
          </div>

          <hr></hr>

          <p>Fechas de consulta: {dateStart}  /  {dateEnd}</p>
          <strong>Total: </strong><span className="display-4">{total}</span>

          <table className="table mt-5">
            <thead>
              <tr>
                <th>Consulta</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                totalConsulta 
                ?
                totalConsulta.map((elemtto, key) =>{
                  return  <tr key={key}>
                            <td>{elemtto._id}</td>
                            <td>{elemtto.count}</td>
                          </tr>
                  
                })
                :
                null
              }
            </tbody>
          </table>
          <br></br>
          <p>DETALLE</p>
          <table className="table mt-5">
            <tbody>
              {
                last 
                ?
                last.map((elemtto, key) =>{
                  return  <tr key={key}>
                            <td>
                              {elemtto.creado} <br></br>
                              {elemtto.alumno} <br></br>
                              {elemtto.consulta}
                            </td>
                          </tr>
                  
                })
                :
                null
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Stadistica;
