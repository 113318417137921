import React from 'react';
import logo from '../img/logo_app.jpg';

const Logo = (prop) => {
    return (
        <img 
        key="logo" 
        alt={prop.width}  
        width={prop.width} 
        className="img-fluid"
        src={logo}></img>
    );
}

export default Logo