import React, { useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory, Link } from "react-router-dom";
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "../componentes/Menu";
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from "../hooks/useDireccionEstudiantil";
import dataFinanzas from "../hooks/useFinzas";
// Contexto
import dataContext from "../context/User";
// Helper
// import SetBitacora from "../helper/SetBitacora";
import loadMenu from "../js/main";
import Footer from "../componentes/Footer";

const CanchasReservas = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem("dataUser");

  if (datos) {
    let cred = JSON.parse(datos);
    dataUser.token = cred.token;
    dataUser.nombre = cred.nombre;
    dataUser.carnet = cred.carnet;

    // SetBitacora.set(cred.carnet, 'Canchas', cred.token);
  } else {
    history.push("/");
  }

  if (dataSuper.indexOf(dataUser.carnet.trim()) === -1) {
    if (
      dataDireccionEstudiantil.indexOf(dataUser.carnet.trim()) === -1 &&
      dataFinanzas.indexOf(dataUser.carnet.trim()) === -1
    ) {
      history.push("/");
    }
  }


  useEffect(() => {
    loadMenu();
  }, [dataUser.carnet]);


  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Espacios deportivos</h2>
        <Link className="btn btn-primary btn-block mt-1" to="/canchafootballadmin">
           FOOTBALL
        </Link>
        <Link className="btn btn-primary btn-block mt-1" to="/canchabasketballadmin">
           BASKETBALL
        </Link>
        <Link className="btn btn-primary btn-block mt-1" to="/canchafootballsalaadmin">
           FOOTBALL SALA
        </Link>
        <Link className="btn btn-primary btn-block mt-1" to="/canchavoleiboladmin">
           VOLEIBOL
        </Link>
        {/* <Link className="btn btn-primary btn-block mt-1" to="/canchapp1">
           TENIS DE MESA 1
        </Link>
        <Link className="btn btn-primary btn-block mt-1" to="/canchapp2">
           TENIS DE MESA 2
        </Link> */}
      </div>
    </div>
  );
};

export default CanchasReservas;
