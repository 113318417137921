import React, { useState, useEffect } from 'react';

import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from '../componentes/Menu';
import dataSuper from "../hooks/Conf";
import dataDireccionEstudiantil from '../hooks/useDireccionEstudiantil';
import dataFinanzas from '../hooks/useFinzas';
import CarAlumnoDatos from '../element/CarAlumnoDatos';
import CarSolvencia from "../element/CarSolvencia";
// Contexto
// import dataContext from "../context/User";

// Helper 
import GetFetch from "../helper/GetFetch";
import loadMenu from '../js/main';
import Footer from '../componentes/Footer';

import '../css/styleAdmin.css';
// import { data } from 'jquery';

const AlumnosSolvencias = () => {

    const history = useHistory();

    // const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');

    if (!datos) {
        history.push("/");
    }

    datos = JSON.parse(datos);

    if (dataSuper.indexOf(datos.carnet.trim()) === -1) {
        if (dataDireccionEstudiantil.indexOf(datos.carnet.trim()) === -1 && dataFinanzas.indexOf(datos.carnet.trim()) === -1 ) {
          history.push("/");
        }
      }  

    const [carnet, setCarnet] = useState({});
    const [solvencia, setSolvencia] = useState({});
    const [alumno, setAlumno] = useState({});

    let carnetSet = (e) => {
        setCarnet(e.target.value)
    }


    let onSutmit = (e) => {
        e.preventDefault();
        localStorage.setItem('currentCarnet', carnet);
        let solvencia = GetFetch.solvencia(carnet, datos.token);
        solvencia.then((dataSolvencia) => {
            document.getElementById('dataContenedorAlumno').style.display = 'block';
            document.getElementById('tableSolvencia').style.display = 'block';
            setSolvencia(dataSolvencia);
            AlumnoDatos();
        });
    }

    let AlumnoDatos = () => {
       
            let datosAlum = GetFetch.datosAlumno(carnet, datos.token);
            datosAlum.then((data) => {
            if (data[0]) {
                setAlumno(data[0]);
            }
        });
        
        
    }

    useEffect(() => {
        let currentCarnet = localStorage.getItem('currentCarnet');
        if (currentCarnet) {
            setCarnet(currentCarnet);
        }else{
            setCarnet('');
        }
        loadMenu();
        document.getElementById('textCarnet').focus();
    }, []);

    return (
        <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
                <div className="custom-menu">
                    <button
                        type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary"
                    >
                        <i className="fa fa-bars"></i>
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4">
                    <Foto
                        foto={datos.carnet}
                        alt="Foto alumno"
                        tamanio="rounded" />

                    <HeaderMenu
                        carnet={datos.carnet}
                        nombre={datos.nombre}
                        perfil={datos.perfil}
                        carrera={datos.carrera}
                        posicion="text-center"
                    />

                    <Menu />

                    <Footer />
                </div>
            </nav>

            <div id="content" className="p-4 p-md-5 pt-5">
                <div className="container">
                    <h1 className="mt-4 text-center"> Alumnos solvencia</h1>
                    <p className="text-center">Consulta de solvencia financiera</p>
                    <form id='formUP' onSubmit={onSutmit} >
                        <div class="form-group">
                            <input id="textCarnet" type="text" placeholder="Carnet" className="form-control" onChange={carnetSet} value={carnet}></input>
                        </div>
                        <div className='form-group'>
                            <input type="submit" className="btn btn-primary btn-block" value="Enviar" />
                        </div>
                    </form>
                    <CarAlumnoDatos
                        alumno={alumno}
                    />
                    <div id="tableSolvencia" style={{ display: 'none' }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <h4 className="text-success">
                                            Componente administrativo
                                     </h4>
                                    </th>
                                    <th>
                                        <h4 className="text-success">
                                            Estado
                                     </h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {solvencia.length > 0
                                    ? solvencia.map((element) => {
                                        return (
                                            <CarSolvencia
                                                componente={element.componente}
                                                solvencia={element.solvencia}
                                            />
                                        );
                                    })
                                    : solvencia.length}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AlumnosSolvencias;