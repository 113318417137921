import React, { useEffect, useContext, useState } from 'react';
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from './Menu';
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from '../js/main';
import CarAdquisiciones from '../element/CarAdquisiciones';
import Footer from './Footer';

const Inicio = () => {

    const history = useHistory();

    const dataUser = useContext(dataContext);
    let datos = localStorage.getItem('dataUser');
    const [itemAdqui, setItemAdqui] = useState([]);

    useEffect(() => {

        if (datos) {
            let cred = JSON.parse(datos);
            dataUser.token = cred.token;
            dataUser.nombre = cred.nombre;
            dataUser.carnet = cred.carnet;

            GetFetch.adquisiciones(dataUser.token)
            .then((item) => {
                setItemAdqui(item);
              }
            );

            loadMenu();
        } else {
            history.push("/");
        }
    }, [dataUser, datos, history]);

    return (

        <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar">
                <div className="custom-menu">
                    <button
                        type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary"
                    >
                        <i className="fa fa-bars"></i>
                        <span className="sr-only">Toggle Menu</span>
                    </button>
                </div>
                <div className="p-4">
                    <Foto
                        foto={dataUser.carnet}
                        alt="Foto alumno"
                        tamanio="rounded" />

                    <HeaderMenu
                        carnet={dataUser.carnet}
                        nombre={dataUser.nombre}
                        posicion="text-center"
                    />

                    <Menu />

                    <Footer />
                </div>
            </nav>

            <div id="content" className="p-4 p-md-5 pt-5 letradecarta">
                <h1 className="mb-4 display-5 homeLogin"> Libros nuevos</h1>
                {
                    itemAdqui 
                    ?
                    <CarAdquisiciones 
                    item = {itemAdqui}/>
                    :
                    null
                }
            </div>
        </div>

    )
}

export default Inicio;