import React, { useState, useEffect, useContext } from "react";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import { useHistory } from 'react-router-dom';
// Componentes
import Foto from "../element/Foto";
import HeaderMenu from "../element/HeaderMenu";
import Menu from "./Menu";
// import FotoBiblio from "../element/FotoBiblio";
// Contexto
import dataContext from "../context/User";
// Helper
import GetFetch from "../helper/GetFetch";
import loadMenu from "../js/main";
import Footer from './Footer';

const BiblioCargosMoras = () => {
  const history = useHistory();
  const dataUser = useContext(dataContext);
  let datos = localStorage.getItem('dataUser');
  const [cargos, setCargos] = useState({});
  const [moras, setMoras] = useState(0);
  const [morasTitulos, setMorasTitulos] = useState();

  useEffect(() => {

    if (datos) {
      let cred = JSON.parse(datos);
      dataUser.token = cred.token;
      dataUser.nombre = cred.nombre;
      dataUser.carnet = cred.carnet;

      let cargo = GetFetch.getCargosMorasBiblio(cred.carnet, cred.token)
      let mora = GetFetch.getMorasBiblio(cred.carnet, cred.token)
      let mt = GetFetch.getMorasTitulosBiblio(cred.carnet, cred.token)

      Promise.all([cargo, mora, mt])
      .then(values => {
        setCargos(values[0])
        setMoras(values[1][0].valor)
        setMorasTitulos(values[2])
      })
      
      loadMenu();
    } else {
      history.push("/");
    }
  }, [datos, history, dataUser]);

  let totalCargo = 0;
  return (
    // <div>

    <div className="wrapper d-flex align-items-stretch">
      <nav id="sidebar">
        <div className="custom-menu">
          <button
            type="button"
            id="sidebarCollapse"
            className="btn btn-primary"
          >
            <i className="fa fa-bars"></i>
            <span className="sr-only">Toggle Menu</span>
          </button>
        </div>
        <div className="p-4">
          <Foto foto={dataUser.carnet} alt="Foto alumno" tamanio="rounded" />

          <HeaderMenu
            carnet={dataUser.carnet}
            nombre={dataUser.nombre}
            posicion="text-center"
          />

          <Menu />

          <Footer />
        </div>
      </nav>

      <div id="content" className="p-4 p-md-5 pt-5">
        <h2 className="mb-4"> Cargos y moras <br></br> <small>CCESEN</small></h2>
        <div className="card">
          <div className="card-body">
            {
              moras ? <p className="lead">Mora: ${moras}</p> : null
            }
            {             
              morasTitulos
              ? 
              <ul className="list-group">
                {
                  morasTitulos.map(element => {
                    let prestamoFecha = new Date(element.dateadd);
                    let devolucionFecha = new Date(element.datedev);
                    let realDevolucionFecha = new Date(element.fechadevolucion);
                    return(
                      <li key={element.dateadd} className="list-group-item"> 
                      {element.titulos} <br></br>
                      <span className="text-info"><i class="fa fa-calendar" aria-hidden="true"></i>  Fecha del préstamo:<br></br> {prestamoFecha.toLocaleString()}</span><br></br>
                      <span className="text-default"><i class="fa fa-calendar" aria-hidden="true"></i>  Fecha de la devolución:<br></br> {devolucionFecha.toLocaleString()}</span><br></br>
                      <span className="text-warning"><i class="fa fa-calendar" aria-hidden="true"></i>  Fecha real de devolución:<br></br> {realDevolucionFecha.toLocaleString()}</span> 
                      </li>
                    )
                  })
                }
              </ul>
              : 
              null
            }
            
            {             
              cargos.length > 0
              ? 
              <>   
              {
                cargos.map(el =>{
                  totalCargo += el.cargo_costo
                  return null
                })
              }             
                <p id="co" className="lead">  Cargos:  ${totalCargo}</p>
                <ul className="list-group">
                  {
                    cargos.map(element => {
                      let fecha = new Date(element.cargo_fecha);
                      return(
                        <li key={element.cargo_id} className="list-group-item"> 
                        {element.cargo_desc} <br></br>
                        <span className="text-info"><i className="fa fa-calendar" aria-hidden="true"></i>  Fecha del cargo:<br></br> {fecha.toLocaleString()}</span><br></br>
                        Cargo: ${element.cargo_costo}<br></br>
                        <span className="text-success">{element.cargos_tipo_desc}</span>
                        </li>
                      )
                    })                                     
                  }
                </ul>
              </>
              : 
              null
            } 
            <div className="row">
              <p id="totalCargoMora" className="lead text-right text-primary mt-4 ml-4">  Total de mora + cargos:  <span className="display-4"> ${+totalCargo + +moras}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiblioCargosMoras;
