import React from "react";
import ErecursosTematicas from './ErecursosTematicas';
import "../css/styleErecursos.css";

const CarErecursos = ({ recurso, carnet }) => {
    const { descripcion, urlIMGVIDEO, urlLogo, accesoRemoto, prefijoid, guiasTematicas } = recurso;

    let enlace = `http://centrodeconocimiento.esen.edu.sv/sites/all/script__pruebas/accesoremoto/general.php?carne=${carnet}&db=${prefijoid}`;

    let url = "http:" + urlIMGVIDEO;
    
    let textAccesoRemoto = null;
    if (accesoRemoto) {
        textAccesoRemoto = 'Acceso remoto';
    } else {
        textAccesoRemoto = 'Acceso campus';
    }
 

    const goUp = document.getElementById('content');
 
    let upGo = () => {
        goUp.scrollIntoView({ behavior: 'smooth' });
    }

    let lookDescripcion = () => {
        let elemy = document.querySelectorAll('.erecursosDescripcion');
        elemy.forEach((item) => {
            item.classList.toggle('erecursosDescripcionLook');
        })
    }

    // console.log(guiasTematicas);

    return (
        <table className="table">
            <tbody>
                <tr className="text-center">
                    <td colspan="2"><img className="erecursosLogos" src={urlLogo} alt={prefijoid} /></td>
                </tr>
                <tr>
                    <td>
                        <button className="btn btn-outline-info mr-1 mb-1" href="#" > <i class="fa fa-wifi" aria-hidden="true"></i> {textAccesoRemoto} </button>
                        <a className="btn btn-outline-primary mr-1 mb-1" href={url} target="_blank" rel="noopener noreferrer"> <i className="fa fa-video-camera" aria-hidden="true"></i> Video</a>
                        <button className="btn btn-outline-info mr-1 mb-1 verDescripcion" href="#" onClick={lookDescripcion}> <i class="fa fa-file-text" aria-hidden="true"></i> </button>
                        <button className="btn btn-outline-info mb-1 goUP" href="#" onClick={upGo}> <i class="fa fa-arrow-up" aria-hidden="true"></i> </button>
                    </td>
                </tr>
                <tr className="text-center">
                    <td colspan="2" className="">
                        <ErecursosTematicas
                            tematicas={guiasTematicas}
                        />
                    </td>
                </tr>
                <tr className="text-justify">
                    <td colspan="2" className="erecursosDescripcion">{descripcion}</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <a className="btn btn-primary btn-block" href={enlace} target="_blank" rel="noopener noreferrer"> <b><i className="fa fa-paper-plane" aria-hidden="true"></i> </b> Ingresar </a>
                    </td>
                </tr>
            </tbody>
        </table>
    )
};

export default CarErecursos;