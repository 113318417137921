import React from "react";
import { useState } from "react";
// import { useContext } from "react";


const FormCatalogoBibilo = ({catalogo, setCatalogo, setConsultar, setPagina}) => {

  const {txtbuscar} = catalogo;
  const [error, setError] = useState(false);
  

    const handleCahnge = (e)=>{
      //guardando el state
      setCatalogo({
        ...catalogo,
        [e.target.name] : e.target.value
      });
      setPagina(1);
    }

    const handleSubmit = e => {
      e.preventDefault();
      
      //validar
      if (txtbuscar.trim() === '') {
        setError(true);
        return;
      }
      setError(false);

      //pasar al componente principal
      setConsultar(true);
    }

  return (
    <form className="mb-3"
    onSubmit = {handleSubmit}
    >
      {error ? <p className="text-danger text-center"><b>Todos los campos son obligatorios</b></p> : null}
      <div className="form-group">
        <label htmlFor="">Buscar por: </label>
        <select 
        class="form-control" 
        name="tipo"
        onChange = {handleCahnge}
        >
          <option value="1"> Título</option>
          <option value="2"> Resumen</option>
          <option value="3"> Ambos</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="">Digité términos a buscar</label>
        <input 
        type="text" 
        className="form-control" 
        name="txtbuscar" 
        onChange = {handleCahnge}
        />
      </div>

      <button type="submit" className="btn btn-block btn-primary">
        Enviar
      </button>
      <small id="emailHelp" className="form-text text-muted text-right">
        * Catálogo de recursos bibliográficos del CCESEN
      </small>
    </form>
  );
};

export default FormCatalogoBibilo;
