import React from 'react'

const DtesViewAlumnos = ({ data }) => {
  // console.log(data);
  let totalMonto = 0;


  function downloadPDF(pdf, codigo) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = codigo + ".pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////   CONTROL DE AUTIENTICANIÓN Y CONSUMO DE API    ///////////////////////////////////////////////////////////////

  //Vencimiento de token validación
  const vencimientoToken = () => {

    if (localStorage.getItem('tokenExpires')) {
      const now = new Date();
      const dateExperite = new Date(localStorage.getItem('tokenExpires'));

      if (dateExperite > now) {
        return 0; //Valido
      } else {
        return 1; //No valido
      }
    }

    return 1; //No existe token o no valido

  }

  //Autenticación con FACTURES
  const autenticacionFactured = async () => {
    // const user = 'Melvin.ESEN';
    // const pass = '191519';
    // const url = 'https://test.factured.sv/ApiFEL/api/v1/signin/';
    let token = '';

    const user = 'daf_prd';
    const pass = '340345333348332';
    const url = 'https://api.factured.sv/api/v1/signin/';

    const dataAuth = await fetch(url, {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        User: user,
        Password: pass
      })
    })

    const auth = await dataAuth.json();

    if (auth.ExpiresToken) {
      localStorage.setItem('tokenExpires', auth.ExpiresToken)
      localStorage.setItem('token', auth.AuthToken)
    }

  }

  //Enviando dte a FACTURED
  const sendtDataPost = async (dataForm, token, codigoGeneracion) => {
    const url = 'https://api.factured.sv/api/v1/dte/consult';
    if (url) {
      try {
        const entryPointLogin = url;
        return await fetch(entryPointLogin, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
          body: dataForm
        })
          .then(result => result.json())
          .then((data) => {
            if (data.DescripcionEstatus === 'Habilitado') {
              downloadPDF(data.pdfDTE, codigoGeneracion);
            } else {
              alert('FACTURA NO DISPONIBLE');
            }
            return data;
          })
      } catch (error) {
        console.log(error);
        return error;
      }
    } else {
      let res;
      return res.json({
        'message': 'EndPoint no definido'
      });
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  const detalleFactura = async (fecha, codigoGeneracion, tipoDte) => {

    const data = JSON.stringify({
      'CodigoGeneracion': codigoGeneracion,
      'FechaEmision': fecha,
      'TipoDte': tipoDte
    })

    //Validando vencimiento del token
    if (+vencimientoToken() === 1) {

      //Autenticando
      await autenticacionFactured();

      //Mandar DTE token nuevo token
      sendtDataPost(data, localStorage.getItem('token'), codigoGeneracion);
    } else {

      //Mandar DTE token todabía valido o no vencido
      sendtDataPost(data, localStorage.getItem('token'), codigoGeneracion);
    }


  }


  const getPDF = (e) => {
    const codGeneracion = e.target.dataset.codigogeneracion
    const fecha = e.target.dataset.fecha
    // const transaccion = e.target.dataset.transaccion
    const tipoDte = '01';

    detalleFactura(fecha.substr(0, 10), codGeneracion, tipoDte)

  }

  return (
    <>
      <h2 className='mt-4'>Historia de pagos</h2>
      <div>
        {
          data.map((el, key) => {
            const f = new Date(el.fechageneracion);
            totalMonto = 0;
            return (
              <div key={key} className='row mt-4'>
                {/* <p><b>{key + 1}</b></p> */}
                <div className='card p-3 col-sm mr-1' key={key}>
                  {/* <p><b>Carnet:</b><br></br>  <h3>{el.carnet}</h3></p>
                  <p><b>Nombre:</b><br></br> <h3> {el.nombre}</h3></p> */}
                  <table className='table table-sm'>
                    <thead>
                      <tr>
                        <th>#</th>
                        {/* <th className='text-center'>Cantidad</th> */}
                        <th>Descripción</th>
                        <th>Monto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        el.detalles.map((elemento, k) => {
                          totalMonto += +elemento.preciounitario
                          return (
                            <tr key={k}>
                              <td>{k + 1}</td>
                              {/* <td className='text-center'>{elemento.cantidad}</td> */}
                              <td>{elemento.descripcion}</td>
                              <td>${elemento.preciounitario}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        {/* <td></td> */}
                        <td className='text-center'><b>Total</b></td>
                        <td>${totalMonto}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className='card p-3 col-sm' key={key}>
                  <table className='table table-sm'>
                      <thead>
                        <tr>
                          <th>Código generación:</th>
                          <td>{el.mh.CodigoGeneracion}</td>
                        </tr>
                        <tr>
                          <th>Número de control:</th>
                          <td>{el.mh.NumeroControl}</td>
                        </tr>
                        <tr>
                          <th>Sello recibido:</th>
                          <td>{el.mh.SelloRecibido}</td>
                        </tr>
                        <tr>
                          <th>Fecha procesamiento:</th>
                          <td>{el.mh.FechaProcesamiento}</td>
                        </tr>
                      </thead>
                  </table>
                  {/* <p><b>Código generación:</b>  {el.mh.CodigoGeneracion}</p>
                  <p><b>Número de control: </b> {el.mh.NumeroControl}</p>
                  <p><b>Sello recibido:</b>  {el.mh.SelloRecibido}</p>
                  <p><b>Fecha procesamiento:</b>  {el.mh.FechaProcesamiento}</p>
                  <p><b>Fecha contabilizado :</b>  {f.toLocaleString()}</p> */}
                </div>
                <div className='col-sm-12 card pb-2 mt-1'>


                  <button className='brn btn-warning btn-sm btn-block' onClick={getPDF} data-fecha={el.fechageneracion} data-codigogeneracion={el.mh.CodigoGeneracion} data-numerocontrol={el.mh.NumeroControl} data-sellorecibido={el.mh.SelloRecibido} >DESCARGAR FACTURA</button>
                </div>

              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default DtesViewAlumnos
