const useErecursos = (content) => {

    let recurso = [];

    if (content.items) {
        content.items.map((elemento) => {

            let itemRecurso = {};
            itemRecurso.nombre = elemento.fields.nombre;
            itemRecurso.descripcion = elemento.fields.descripcin;
            itemRecurso.tipo = elemento.fields.tipo[0];
            itemRecurso.idLogo = elemento.fields.logo.sys.id;
            itemRecurso.idIMGVIDEO = elemento.fields.imagenvideo.sys.id;
            itemRecurso.accesoRemoto = elemento.fields.accesoRemoto;
            itemRecurso.prefijoid = elemento.fields.prefijoid;
            itemRecurso.guiasTematicas = elemento.fields.guasTemticas;

            recurso.push(itemRecurso);
            return null
        });
    }

    let assetRecurso = [];

    if (content.includes.Asset) {

        content.includes.Asset.map((x) => {
            let media = [];
            media.id = x.sys.id;
            media.url = x.fields.file.url;
            assetRecurso.push(media);
            return null;
        });

        recurso.map((item, index) => {

            assetRecurso.map((itemAsset) => {
                if (itemAsset.id === item.idIMGVIDEO) {
                    recurso[index].urlIMGVIDEO = itemAsset.url;
                }
                if (itemAsset.id === item.idLogo) {
                    recurso[index].urlLogo = itemAsset.url;
                }
                return null;
            })
            return null;
        })
    }

    return [recurso];

}

export default useErecursos;