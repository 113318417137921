import React from "react";

const ErecursosTematicas = ({ tematicas }) => {

    if (!tematicas) {
       tematicas = []; 
    }

    return (
            <>
                {
                    tematicas.length > 0 
                    ?
                    tematicas.map((elemt)=>{
                        return (
                            <span className="badge badge-secondary mr-1"> {elemt}</span>
                        )
                    })
                    :
                    null
                }  
            </>              
    )

}

export default ErecursosTematicas;